import React from 'react';
import { Switch, Route } from '../../utils/router'
import CustomerList from './CustomerList';
import CustomerSingle from './CustomerSingle';
import CustomerAdd from './CustomerAdd';
import CustomerEdit from './CustomerEdit';
import CustomerView from './CustomerView';

class Customer extends React.Component {
    render(){
        return (
            <Switch>
                <Route exact path='/customers/add' component={CustomerAdd} />
                <Route
                    path='/status/:publicId'
                    component={CustomerView}
                    // render={(props) => <CustomerView {...props} />}
                />
                <Route
                    path='/customers/edit/:customerPublicId'
                    component={CustomerEdit}
                    // render={(props) => <CustomerEdit {...props} />}
                />
                <Route
                    path='/customers/:customerPublicId'
                    component={CustomerSingle}
                    // render={(props) => <CustomerSingle {...props} />}
                />
                <Route
                    exact
                    path='/customers'
                    component={CustomerList}
                    // render={(props) => <CustomerList {...props} />}
                />
            </Switch>
        );
    }
}

export default Customer;
