import React from "react";
import {useTranslation} from "react-i18next";

const ProgressBar = (props) => {
    const stripsBought = props.bought;
    //const stripsRemaining = props.remaining;
    let customerCards = props.customerCards;
    /** removing expired and cards **/
    customerCards = customerCards.filter((card, index) => {
        return (card.is_expired === false);
    })

    customerCards = customerCards.filter((card, index) => {
        return (card.strips_remaining > 0);
    })
    
    const totalStrips = customerCards.reduce(function(prev, current){
        return prev+current.amount
    }, 0)

    const totalUsedStrips = customerCards.reduce(function(prev, current){
        return prev+current.strips_booked
    }, 0)

    const percentageUsed = (totalStrips > 0) ? Math.floor((totalUsedStrips/totalStrips)*100) : 0;
    // const percentageAvailable = stripsBought < 1 ? 0 : (100 / stripsBought) * stripsRemaining;
    // const percentageUsed = stripsBought < 1
    //     ? 0
    //     : (100 - percentageAvailable) > 100
    //         // little FIX: when percentage says 100% but there are strips left, make
    //         // it 99%
    //         ? stripsRemaining > 0 ? 99 : 100
    //         : Math.floor(100 - percentageAvailable);
    const { t } = useTranslation()
    let backgroundColor = 'green';

    switch (true) {
        case percentageUsed < 50:
            backgroundColor = 'green';
            break;
        case percentageUsed < 75:
            backgroundColor = '#FA29F9';
            break;
        case percentageUsed < 95:
            backgroundColor = '#ED0255';
            break;
        default:
            backgroundColor = 'red';
            break;
    }

    // the text to show in the progressbar
    let barText = '';
    if (percentageUsed > 50) {
        barText = percentageUsed + '% ' + t('used');
    }

    const progressStyle = {
        width: percentageUsed + "%",
        "backgroundColor": backgroundColor
    };


    return (
        <>
            <div className="progress w-100">
                <div className="progress-bar"
                     role="progressbar"
                     style={progressStyle}
                     aria-valuenow={percentageUsed}
                     aria-valuemin="0"
                     aria-valuemax={stripsBought}
                >
                    {barText}
                </div>
            </div>
        </>
    )
}

export default ProgressBar
