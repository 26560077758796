import React, {useEffect, useState} from 'react';
import {Button, Modal, Spinner} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {ICardType} from "../../interfaces/ICardType";
import persistentStore from "../../stores/persistentStore";

type CheckoutModalProps = {
    show: boolean,
    hide: ()=>void,
    loading: boolean,
    handleOrderSubmit: ({name,email}:{name:string,email:string})=>void,
    cardType: ICardType
}

const CheckoutModal = (props: CheckoutModalProps) => {
    const {t} = useTranslation()
    const {handleOrderSubmit, show, hide, loading, cardType} = props
    const [email, setEmail] = useState<string>(persistentStore.buyer.email)
    const [name, setName] = useState<string>(persistentStore.buyer.name)

    const handleSubmit = (event: any) => {
        event.preventDefault();
        handleOrderSubmit({name: name, email: email})
    }
    const displayprice = ((cardType.price_in_cents) /100)

    // store the buyer information so it can be used when the user hits the back-button
    // or when the user buys again next time
    useEffect(() => {
        persistentStore.setBuyer({name: name, email: email})
    }, [email, name])

    return (
        <Modal id='checkout-modal' show={show} onHide={() => hide()}>
            <form
                onSubmit={handleSubmit}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{cardType?.name} voor € {Number.isInteger(displayprice)?(cardType.price_in_cents / 100):(cardType.price_in_cents / 100).toFixed(2)}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className="form-group">
                        <label htmlFor="inputName">{t('Your name')}</label>
                        <input
                            type="text"
                            className="form-control"
                            id="inputName"
                            placeholder={t('John Doe')}
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            name="name"
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="inputDefaultEmail">{t('Your email address')}</label>
                        <input
                            type="text"
                            className="form-control"
                            id="inputDefaultEmail"
                            placeholder={t('johndoe@email.com')}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            name="defaultEmail"
                            required
                        />
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="link" className="text-danger" onClick={() => hide()}>
                        {t('Cancel')}
                    </Button>

                    <Button
                        type="submit"
                        size='lg'
                        variant="primary"
                        className="mr-1"
                        disabled={loading}
                    >
                        {loading &&
                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>}
                        {!loading && t('Buy')}
                    </Button>

                </Modal.Footer>
            </form>
        </Modal>
    )
}

export default CheckoutModal;
