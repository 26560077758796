import axios from 'axios'
import errorChecker from './errorchecker'
import persistentStore from "../stores/persistentStore";

const orderRequests = () => {

    const get = async (publicId: string) => {
        const path = `/orders/${publicId}`
        const config = persistentStore.authHeader

        return axios.get(persistentStore.apiUrl + path, config)
            .then(response => errorChecker(response))
            .then(response => {
                const data = response.data.data;
                if (!data.order) {
                    throw Error('The response did not contain the created order');
                }

                return data.order
            })
    }

    /**
     * Get orders of the current logged in user
     */
    const getFromUser = async () => {
        const path = `/orders/get-from-user`
        const config = persistentStore.authHeader

        return axios.get(persistentStore.apiUrl + path, config)
            .then(response => errorChecker(response))
            .then(response => {
                const data = response.data.data;
                if (!data.orders) {
                    throw Error('The response did not contain the created order');
                }

                return data.orders
            })
    }

    return {
        get,
        getFromUser,
    }
}

export const orders = orderRequests()
