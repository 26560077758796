import React, {useEffect, useState} from 'react';
import {Button, Modal, Spinner} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {ICardType} from "../../interfaces/ICardType";
import Col from "react-bootstrap/Col";

type AddCardTypeModelType = {
    shouldShow: boolean,
    hide: ()=>void,
    loading: boolean,
    submit: (event: any, card: ICardType)=>void
}

const AddCardTypeModal = (props: AddCardTypeModelType) => {
    const {t} = useTranslation()
    const [name, setName] = useState('')
    const [defaultQuantity, setDefaultQuantity] = useState<number>(10)
    const [typedPrice, setTypedPrice] = useState<string>('')
    const [priceInCents, setPriceInCents] = useState<number>(0)
    const [description, setDescription] = useState('')
    const [expires, setExpires] = useState('')
    const [hasCustomValidity, setHasCustomValidity] = useState<boolean>(false)
    const [durationQuantity, setDurationQuantity] = useState<number>(1)
    const [durationUnit, setDurationUnit] = useState<string>('years')

    const [cardType, setCardType] = useState<ICardType>({} as ICardType)

    const checkNumber = (event: any) => {
        if (event.name === 'defaultQuantity') {
            setDefaultQuantity((Number(event.value) ? event.value : ''))
        }
    }

    useEffect(() => {
        const updatedCard: ICardType = {
            name: name,
            default_quantity: defaultQuantity,
            price_in_cents: priceInCents,
            validity_time: expires,
            description: description,
            has_custom_validity: hasCustomValidity,
        }

        if(hasCustomValidity){
            updatedCard.validity_time = `${durationQuantity} ${durationUnit}`
        }

        setCardType(updatedCard)
    }, [name, defaultQuantity, priceInCents, description, expires, hasCustomValidity, durationQuantity, durationUnit])

    const changePrice = (value: string) => {
        // store whatever is typed
        setTypedPrice(value)

        // now, turn the madness that is typed into an understandable price

        // change comma to point
        const correctDecimals = value
            .replace(',', '.')
            .replace(' ', '')

        // filthy hack: add a 9 when there are two decimals. Otherwise: javascript will deduct 0.01 from it :-|
        const positionOfDecimal = correctDecimals.indexOf('.')
        const decimalAmount = correctDecimals.substr(positionOfDecimal).length
        const floatValue = decimalAmount <= 2
            ? parseFloat(correctDecimals)
            : parseFloat(correctDecimals+'9')
        const intValue = parseInt((floatValue * 100).toString(), 10)
        setPriceInCents(intValue)
    }

    const emptyForm = () => {
        setName('')
        setDefaultQuantity(10)
        setTypedPrice('')
        setDescription('')
        setPriceInCents(0)
    }

    const addCard = (event: any) => {
        props.submit(event, cardType)

        // reset form
        emptyForm()
    }

    const handleExpires = (event: any) => {
        setExpires(event.target.value)
        setHasCustomValidity((event.target.value === 'Other'))
    }

    return (
        <Modal show={props.shouldShow} onHide={props.hide}>
            <form onSubmit={addCard}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('Add a new card')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group">
                        <label htmlFor="inputName">{t('Name of this type of card')}</label>
                        <input
                            type="text"
                            className="form-control"
                            id="inputName"
                            placeholder='Bijv: 10-rittenkaart'
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            name="name"
                            required
                        />
                        <small>Dit is de naam die de klant bij de kaart ziet op de verkooppagina.</small>
                    </div>

                    <div className="form-group">
                        <label htmlFor="inputDefaultQuantity">{t('Default quantity')}</label>
                        <input
                            type="text"
                            className="form-control"
                            id="inputDefaultQuantity"
                            placeholder={'10'}
                            value={defaultQuantity}
                            onChange={(e) => checkNumber(e.target)}
                            name="defaultQuantity"
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="inputPriceInCents">{t('Price in cents (inc VAT)')}</label>
                        <input
                            type="text"
                            className="form-control"
                            id="inputPriceInCents"
                            placeholder={t('Price in cents (inc VAT)')}
                            // value={(priceInCents / 100).toStringLocale(store.locale)}
                            value={typedPrice}
                            onChange={(e) => changePrice(e.target.value)}
                            name="priceInCents"
                            required
                        />
                        <small>Op de factuur wordt de BTW bijgevoegd</small>
                    </div>

                    <div className="form-group">
                        <label htmlFor="inputDuedate">Geldigheidsduur: hoe lang zijn de strippen geldig?</label>
                        <select
                            id="inputDueDate"
                            className="form-control"
                            name="geldigheidsduur"
                            onChange={(event) => handleExpires(event)}
                        >
                            <option value="0">{t('unlimited')}</option>
                            <option value="3 weeks">3 {t('weeks')}</option>
                            <option value="12 weeks">12 {t('weeks')}</option>
                            <option value="1 months">1 {t('month')}</option>
                            <option value="3 months">3 {t('months')}</option>
                            <option value="6 months">6 {t('months')}</option>
                            <option value="1 years">1 {t('year')}</option>
                            <option value="Other">{t('other')}...</option>
                        </select>
                    </div>

                    {hasCustomValidity && (
                        <div className="form-row">
                            <Col sm={12}>
                                <label htmlFor="inputCustomDuration">{t('Custom duration')}</label>
                            </Col>
                            <Col>
                                <input
                                    type="number"
                                    min={1}
                                    step='any'
                                    className="form-control"
                                    id="inputCustomDuration"
                                    placeholder={t('Duration')}
                                    value={durationQuantity}
                                    onChange={(e) => setDurationQuantity(parseInt(e.target.value))}
                                    name="customDurtion"
                                    required
                                />
                            </Col>

                            <Col>
                                <select
                                    id="inputValidityType"
                                    className="form-control"
                                    name="geldigheidsduur"
                                    value={durationUnit}
                                    onChange={(event) => setDurationUnit(event.target.value)}
                                >
                                    <option value="days">{t('days')}</option>
                                    <option value="weeks">{t('weeks')}</option>
                                    <option value="months">{t('months')}</option>
                                    <option value="years">{t('year')}</option>
                                </select>
                            </Col>
                        </div>
                    )}

                    <div className="form-group">
                        <label htmlFor="inputDescription">{t('Description')}</label>
                        <textarea
                            className='form-control'
                            id="inputDescription"
                            name='description'
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                        <small>De beschrijving is te zien op de verkooppagina van de kaarten</small>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="link" id='candel-addcard' className="text-danger" onClick={props.hide}>
                        {t('Cancel')}
                    </Button>

                    <Button
                        type="submit"
                        size='lg'
                        id='submit-cardtype'
                        variant="primary"
                        className="mr-1"
                        disabled={props.loading}
                    >
                        {props.loading &&
                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>}
                        {!props.loading && t('Save')}
                    </Button>

                </Modal.Footer>
            </form>
        </Modal>
    )
}

export default AddCardTypeModal;
