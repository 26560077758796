import axios from 'axios'
import store from '../stores/store'
import errorChecker from './errorchecker'
import {history} from '../utils/router'
import {toast} from "react-toastify"
import qs from 'querystring'
import persistentStore from "../stores/persistentStore";
import {IUser} from "../interfaces/IUser";
import {IUserSettings} from "../interfaces/IUserSettings";
import {ISellerSettings} from "../interfaces/ISellerSettings";

const userRequests = () => {
    // register an event for this user
    const event = async (eventName: string) => {
        const path = `/users/event/${eventName}`

        return axios.post(persistentStore.apiUrl + path, [], persistentStore.authHeader)
            .then((response) => {
                return response.data
            })
    }

    const impersonate = async (idOfUserToBecome: number) => {
        const path = `/impersonate/${idOfUserToBecome}`

        return axios.post(persistentStore.apiUrl + path, [], persistentStore.authHeader)
            .then((response) => {
                const serverData = response.data

                if (response.data.success === true) {
                    // store the current information
                    persistentStore.impersonate(
                        serverData.data.user,
                        serverData.data.token,
                    )
                }

                return serverData
            })
    }

    const logout = () => {
        store.emptyStore()
        history.push('/login')
    }

    const login = async ({username, password, remember_me}: { username: string, password: string, remember_me: boolean }) => {
        const path = `/login`
        const data = {email: username, password: password, remember_me: remember_me}
        const header = {
            headers: {
                'Accept': 'application/json',
            }
        }

        return axios.post(persistentStore.apiUrl + path, qs.stringify(data), header)
            .then(response => errorChecker(response))
            .then(result => {
                if (result.status === 401) {
                    toast.error('Incorrect username or password')
                    return
                }

                const responseData = result.data.data
                const user = responseData.user
                const token = responseData.token

                persistentStore.setUser(user)
                persistentStore.setToken(token)
                store.setCardTypes(user.card_types)
                persistentStore.setPageLength(user.settings?.paginationLength || 15)

                return result.data
            })
    }

    const register = async (user: IUser) => {
        const path = `/register`
        return axios.post(persistentStore.apiUrl + path, user, persistentStore.authHeader)
            .then(response => errorChecker(response))
            .then(response => response.data)
    };

    const validatePasswordResetTokens = async (props: any) => {
        const path = `/validatePasswordResetTokens`
        return axios.post(persistentStore.apiUrl + path, props, persistentStore.authHeader)
            .then(response => response.data)
    };

    const requestResetPassword = async (props: any) => {
        const path = `/requestResetPassword`
        return axios.post(persistentStore.apiUrl + path, props, persistentStore.authHeader)
            .then(response => response.data)
            .catch(error => error)
    };

    const resetPassword = async (props: any) => {
        const path = `/resetpassword`
        const data = props

        return axios.post(persistentStore.apiUrl + path, data, persistentStore.authHeader)
            .then(response => response.data)
            .catch(error => error)
    };

    const changePassword = async (props: any) => {
        const path = `/changepassword`
        return axios.post(persistentStore.apiUrl + path, props, persistentStore.authHeader)
            .then(response => response.data)
            .catch(error => error)
    };

    const get = async () => {
        const path = `/users`

        return axios.get(persistentStore.apiUrl + path, persistentStore.authHeader)
            .then(response => errorChecker(response))
            .then(response => {
                return response.data.data.users
            })
    };

    const getSellerSettings = async (userId: number): Promise<ISellerSettings> => {
        const path = `/users/get-seller-settings/${userId}`

        return axios.get(persistentStore.apiUrl + path, persistentStore.authHeader)
            .then(response => errorChecker(response))
            .then(response => {
                const data = response.data.data;
                if (!data.settings) {
                    throw Error('The response did not contain settings');
                }

                const sellerSettings: ISellerSettings = data.settings

                return sellerSettings
            })
    };

    const refetch = async (userId: number): Promise<IUser> => {
        const path = `/users/refetch/${userId}`

        return axios.get(persistentStore.apiUrl + path, persistentStore.authHeader)
            .then(response => errorChecker(response))
            .then(response => response.data.data.user)
    };

    const getOne = async (userId: number): Promise<IUser> => {
        const path = `/users/${userId}`

        return axios.get(persistentStore.apiUrl + path, persistentStore.authHeader)
            .then(response => errorChecker(response))
            .then(response => response.data.data.user)
    };

    const del = async (userId: number) => {
        const path = `/users/${userId}`

        return axios.delete(persistentStore.apiUrl + path, persistentStore.authHeader)
            .then(response => errorChecker(response))
            .then(response => response.data)
    };

    const returnUserFromResponse = (response: any) => {
        const data = response.data
        persistentStore.setUser(response.data.data.user)
        return data
    }

    const update = async (user: IUser, userId: number) => {
        const path = `/users/edit/${userId}`
        const config = persistentStore.authHeader

        return axios.put(persistentStore.apiUrl + path, user, config)
            .then(response => errorChecker(response))
            .then(returnUserFromResponse)
    }

    const updateSettings = async (userSettings: IUserSettings, id: number) => {
        const path = `/users/updateSettings/${id}`
        const config = persistentStore.authHeader

        return axios.put(persistentStore.apiUrl + path, userSettings, config)
            .then(response => errorChecker(response))
            .then(returnUserFromResponse)
    }

    const updateLogo = async (formData: FormData) => {
        const path = `/users/uploadLogo`
        const config = persistentStore.authHeader

        return axios.post(persistentStore.apiUrl + path, formData, config)
            .then(response => errorChecker(response))
            .then(response => {
                if (response.data.success) {
                    persistentStore.setUser(response.data.data?.user)
                    return response.data
                }

                console.error(response)
                throw new Error('An error occurred while uploading the logo')
            })
    }

    const clearLogo = async () => {
        const path = `/users/clearLogo`
        const config = persistentStore.authHeader

        return axios.post(persistentStore.apiUrl + path, {}, config)
            .then(response => errorChecker(response))
            .then(response => {
                persistentStore.setUser(response.data.data?.user)
                return response.data
                // return data so the requesting party can check for 'success' as well
            })
    }

    const displayBuyButton = async (userId: number) => {
        const path = `/users/display-buy-button/${userId}`
        const config = persistentStore.authHeader

        return axios.get(persistentStore.apiUrl + path, config)
            .then(response => errorChecker(response))
            .then(response => {
                return response.data.data
            })
    }

    const hidesSalesPage = async (userId: number) => {
        const path = `/hides-salespage/${userId}`
        const config = persistentStore.authHeader

        return axios.get(persistentStore.apiUrl + path, config)
            .then(response => errorChecker(response))
            .then(response => {
                return response.data.data
            })
    }

    const getSlugOfLastBoughtCard = async (userId: number) => {
        const path = `/slug-of-last-bought-card/${userId}`
        const config = persistentStore.authHeader

        return axios.get(persistentStore.apiUrl + path, config)
            .then(response => errorChecker(response))
            .then(response => {
                return response.data.data
            })
    }

    const sellsCards = async (userId: number) => {
        const path = `/sells_cards/${userId}`
        const config = persistentStore.authHeader

        return axios.get(persistentStore.apiUrl + path, config)
            .then(response => errorChecker(response))
            .then(response => {
                return response.data.data
            })
    }

    const stripsOverTime = async () => {
        const path = `/users/stats`
        const config = persistentStore.authHeader

        return axios.get(persistentStore.apiUrl + path, config)
            .then(response => errorChecker(response))
            .then(response => {
                if (response.data.data.user_statistics) {
                    return response.data.data
                }
                console.error(response)
                throw new Error('An error occurred while fetching user statistics')
            })
    }

    const saveNewSaleSlug = async (saleSlug: string) => {
        const path = `/users/save-sale-slug`
        const config = persistentStore.authHeader
        const data = {
            sale_slug: saleSlug,
        }

        return axios.post(persistentStore.apiUrl + path, data, config)
            .then(response => errorChecker(response))
            .then(response => {
                if (response.data.data.sale_slug) {
                    return response.data.data.sale_slug
                }
                console.error(response)
                throw new Error('No sale_slug supplied in the response')
            })
    }

    const saveNewSlug = async (slug: string) => {
        const path = `/users/save-slug`
        const config = persistentStore.authHeader
        const data = {
            slug: slug,
        }

        return axios.post(persistentStore.apiUrl + path, data, config)
            .then(response => errorChecker(response))
            .then(response => {
                if (response.data.data.slug) {
                    return response.data.data.slug
                }
                console.error(response)
                throw new Error('No slug supplied in the response')
            })
    }

    const isSaleSlugUnique = async (saleSlug: string) => {
        const path = `/users/is-slug-unique/${saleSlug}`
        const config = persistentStore.authHeader

        return axios.get(persistentStore.apiUrl + path, config)
            .then(response => errorChecker(response))
            .then(response => {
                if (response.data.data.hasOwnProperty('unique')) {
                    return response.data.data.unique
                }

                console.error(response)
                throw Error('The response did not contain property "unique"');
            })
    }

    const isSlugUnique = async (slug: string) => {
        const path = `/users/is-slug-unique/${slug}`
        const config = persistentStore.authHeader

        return axios.get(persistentStore.apiUrl + path, config)
            .then(response => errorChecker(response))
            .then(response => {
                console.log('response.data.data:')
                console.log(response.data.data)
                if (response.data.data.hasOwnProperty('unique')) {
                    return response.data.data.unique
                }

                console.error(response)
                throw Error('The response did not contain property "unique"');
            })
    }

    const getTimeOrStripsFromCustomer = async (customerPublicId: string) => {
        const path = `/users/time-or-strips-from-customer/${customerPublicId}`
        const config = persistentStore.authHeader

        return axios.get(persistentStore.apiUrl + path, config)
            .then(response => errorChecker(response))
            .then(response => {
                if (response.data.data.useType) {
                    return response.data.data.useType
                }

                console.error(response)
                throw Error('The response did not contain the type the user uses for their cards');
            })
    }

    const updateUserInfo = async (userInfo: any, id: number) => {
        const path = `/users/update-user-info/${id}`
        const config = persistentStore.authHeader

        return axios.put(persistentStore.apiUrl + path, userInfo, config)
            .then(response => errorChecker(response))
            .then(response => {
                return response.data
            })
    }

    return {
        changePassword,
        clearLogo,
        del,
        event,
        get,
        getTimeOrStripsFromCustomer,
        getOne,
        getSellerSettings,
        getSlugOfLastBoughtCard,
        hidesSalesPage,
        impersonate,
        isSlugUnique,
        isSaleSlugUnique,
        login,
        logout,
        refetch,
        register,
        requestResetPassword,
        resetPassword,
        sellsCards,
        saveNewSlug,
        saveNewSaleSlug,
        displayBuyButton,
        stripsOverTime,
        update,
        updateLogo,
        updateSettings,
        validatePasswordResetTokens,
        updateUserInfo,
    }
}


export const users = userRequests()
