import axios from 'axios'
import errorChecker from './errorchecker'
import persistentStore from "../stores/persistentStore";

const eventRequests = () => {

    const get = async () => {
        const path = `/events`
        const config = persistentStore.authHeader

        return axios.get(persistentStore.apiUrl + path, config)
            .then(response => errorChecker(response))
            .then(response => {
                const data = response.data.data;
                if (!data.events || data.events.length < 1) {
                    throw Error('The response did not contain events');
                }

                return data.events
            })
    }

    const trialMails = async () => {
        const path = `/events/trial-mails`
        const config = persistentStore.authHeader

        return axios.get(persistentStore.apiUrl + path, config)
            .then(response => errorChecker(response))
            .then(response => {
                const data = response.data.data;
                if (!data.events || data.events.length < 1) {
                    throw Error('The response did not contain events');
                }

                return data.events
            })
    }

    const getByType = async (type: string) => {
        const path = `/events/get-by-type/${type}`
        const config = persistentStore.authHeader

        return axios.get(persistentStore.apiUrl + path, config)
            .then(response => errorChecker(response))
            .then(response => {
                const data = response.data.data;
                if (!data.events || data.events.length < 1) {
                    throw Error('The response did not contain events');
                }

                return data.events
            })
    }

    const getByUserId = async (user_id: number) => {
        const path = `/events/get-from-user/${user_id}`
        const config = persistentStore.authHeader

        return axios.get(persistentStore.apiUrl + path, config)
            .then(response => errorChecker(response))
            .then(response => {
                const data = response.data.data;
                if (!data.events || data.events.length < 1) {
                    throw Error('The response did not contain events');
                }

                return data
            })
    }

    return {
        get,
        getByType,
        getByUserId,
        trialMails,
    }
}

export const events = eventRequests()
