import React from "react"
import Section from "../../components/Section";
import {Container} from "react-bootstrap";
import SectionHeader from "../../components/SectionHeader";

const HiddenSalesPage = () => {
    const bg="white"
    const textColor="dark"
    const size="md"
    const bgImage=""
    const bgImageOpacity=1
    const title= 'Strippenkaart App'
    const subtitle= 'deze pagina is niet in gebruik'

    console.log('Note: The salespage is not in use')

    return (
        <Section
            bg={bg}
            textColor={textColor}
            size={size}
            bgImage={bgImage}
            bgImageOpacity={bgImageOpacity}
        >
            <Container>
                <SectionHeader
                    title={title}
                    subtitle={subtitle}
                    size={2}
                    spaced={true}
                    className="text-center"
                />

            </Container>
        </Section>
    )
}

export default HiddenSalesPage
