import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {observer} from 'mobx-react'
import {users} from '../../api/users'
import {useRouter} from '../../utils/router'
import {useLoading} from "../../utils/helpers";
import {useTranslation} from "react-i18next";
import Alert from "react-bootstrap/Alert";
import FormGroup from "react-bootstrap/FormGroup";
import Spinner from "react-bootstrap/Spinner";
import Header from "../../parts/Header";

const ResetPassword = (props) => {
    const [password, setPassword] = useState('')
    const [error, setError] = useState('')
    const [success, setSuccess] = useState('')
    const [loading, startLoading, stopLoading] = useLoading()
    const router = useRouter()
    const {t} = useTranslation()
    const {selector, validator} = router.query

    useEffect(() => {
        // validate the password reset values
        users.validatePasswordResetTokens({selector: selector, validator: validator})
            .then(response => {
                // console.log(response)
            })
            .catch(error => {
                setError(error.response.data.error.message)
            })
    }, [selector, validator])

    const handlePasswordChange = (event) => {
        event.preventDefault()
        startLoading()

        users.resetPassword({password: password, validator: validator, selector: selector})
            .then((response) => {
                if (response.success !== true) {
                    setError(response.error)
                    return
                }

                setSuccess(t('Password successfully changed. Go to the Login page and login using your new password'))
            })
            .finally(() => {
                stopLoading()
            })
    }

    return (
        <>
            <Header />
            <main role="main" className="col-md-9 ml-sm-auto col-lg-10 pt-3 px-4">
                <form className="form-signin col-md-5" onSubmit={handlePasswordChange}>

                    <h1 className="h3 mb-3 font-weight-normal">{t('Change your password')}</h1>

                    {error && <Alert variant='danger'>{error}</Alert>}
                    {success && <Alert variant='success'>{success}</Alert>}

                    <FormGroup>
                        <label htmlFor="inputPassword" className="sr-only">{t('Password')}</label>
                        <input type="password" id="inputPassword" className="form-control" placeholder={t("Password")}
                               name="password" value={password} onChange={e => setPassword(e.target.value)}
                               disabled={success || loading}
                               required
                        />
                    </FormGroup>

                    <button className="btn btn-primary btn-block" type="submit" disabled={success || loading}>
                        {loading && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
                        {! loading && t('Change password')}
                    </button>

                    <Link to="/login" className="btn btn-link">{t('Login')}</Link>
                </form>
            </main>
        </>
    )
}

export default observer(ResetPassword)

