import React from 'react';
import {Button, Modal} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import SKImage from '../assets/img/strippenkaart-logo.png'
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import './UpgradeTrialModal.scss'

const UpgradeTrialModal = (props: any) => {
    const {t} = useTranslation()

    return (
        <Modal show={props.show} onHide={props.hide} className='UpgradeTrialModal'>
            <Modal.Header closeButton>
                <Modal.Title>Leg actie-tarief vast</Modal.Title>
            </Modal.Header>

            <Modal.Body className='container'>
                <Row>
                    <Col sm={7}>
                        <p>Upgrade je abonnement en</p>
                        <ul className='perks'>
                            <li>
                                behoud huidig actie-tarief <strong>levenslang</strong>
                            </li>
                            <li>
                                blijf ongestoord doorwerken
                            </li>
                            <li>
                                behoud je gegevens
                            </li>
                            <li>
                                zorg dat je klanten zelf bij hun overzichten kunnen
                            </li>
                        </ul>
                    </Col>
                    <Col sm={4}>
                        <img width="170px" alt='logo strippenkaart' src={SKImage} />
                    </Col>
                </Row>
            </Modal.Body>

            <Modal.Footer>
                <Button
                    variant="link"
                    className="text-danger"
                    onClick={props.hide}
                >
                    {t('Cancel')}
                </Button>

                <Button
                    type="submit"
                    size='lg'
                    variant="primary"
                    className="mr-1"
                    onClick={() => {window.location.href = 'https://pauledenburg.plugandpay.nl/checkout/strippenkaart'}}
                >
                    {t('Verleng')}
                </Button>

            </Modal.Footer>
        </Modal>
    )
}

export default UpgradeTrialModal;
