import axios from 'axios'
import errorChecker from './errorchecker'
import persistentStore from "../stores/persistentStore";
import {ICard} from "../interfaces/ICard";

const cardRequests = () => {

  const get = async (cardId: number) => {
    const path = `/cards/${cardId}`

    return axios.get(persistentStore.apiUrl+path, persistentStore.authHeader)
      .then(response => errorChecker(response))
      .then(response => {
        if (!response.data.data.card) {
          console.log('No cards found in response');
          throw Error('The response did not contain cards');
        }

        return response.data.data.card
      })
  }

  const add = async (card: ICard) => {
    const path = `/cards`
    const data = card

    return axios.post(persistentStore.apiUrl+path, data, persistentStore.authHeader)
      .then(response => errorChecker(response))
      .then(response => {
        if (!response.data.data.card) {
          console.log('The response did not contain the updated card');
          throw Error('The response did not contain cards');
        }

        return response.data.data.card
      })
  }

  const edit = async (card: ICard) => {
    const path = `/cards/${card.id}`
    const data = card

    return axios.put(persistentStore.apiUrl+path, data, persistentStore.authHeader)
      .then(response => errorChecker(response))
      .then(response => {
        if(response.data.success === false){
          return response.data
        }
        else {
          if (!response.data.data.card) {
            console.log('The response did not contain the updated card');
            throw Error('The response did not contain cards');
          }

          return response.data
        }
      })
  }

  const del = async (cardId: number) => {
    const path   = `/cards/${cardId}`

    return axios.delete(persistentStore.apiUrl+path, persistentStore.authHeader)
      .then(response => errorChecker(response))
      .then(response => {
        const card = response.data.data.card

        if(! card){
          throw Error('The response did not contain the removed card');
        }

        return card
      })
  }

  return {
    get,
    add,
    del,
    edit
  }
}

export const cards = cardRequests()
