import React from 'react';
import Header from "./parts/Header";
import store from "./stores/store";
import Sidebar from "./parts/Sidebar";
import {Route, Switch, useLocation} from "./utils/router";
import PrivateRoute from "./utils/PrivateRoute";
import Dashboard from "./pages/Dashboard/DashboardPage";
import Customer from "./pages/Customers/Customer";
import ReportsPage from "./pages/Reports/ReportsPage";
import CardTypesPage from "./pages/Cards/CardTypesPage";
import UnitEdit from "./pages/Units/UnitEdit";
import IntegrationsPage from "./pages/Integrations/IntegrationsPage";
import Config from "./pages/Config/Config";
import Footer from "./parts/Footer";
import {getSlug} from "./utils/helpers";
import BottomNav from "./parts/BottomNav";
import ClassPage from "./pages/Class/ClassPage"
import UsersPage from "./pages/Users/UsersPage"
import UsersDetailsPage from "./pages/Users/UsersDetailsPage"
import UpgradeTrialModal from "./components/UpgradeTrialModal";
import {observer} from "mobx-react";
import persistentStore from "./stores/persistentStore";
import CustomerCardsPage from "./pages/Customers/CustomerCardsPage";
import RedirectMessage from "./components/RedirectMessage";
import Theme from './theme'
import tawkTo from "./utils/tawkTo";
import featureToggleStore from "./stores/featureToggleStore";
import UseTimeContext from "./contexts/UseTimeContext";
import './App.scss'

const App = () => {
    // get the URL-part after the domainname ( i call it 'slug')
    const slug = getSlug()
    const isCustomerView = slug.startsWith('/status/')
    const showSidebar = !isCustomerView
    const useTime = ['time', 'hours', 'uur'].includes(persistentStore.user?.settings.useType || 'strippen')

    const search = useLocation().search
    const from = (new URLSearchParams(search)).get('from')
    const redirectedFromHome: boolean = (from === 'strippenkaart.app')

    // add TawkTo on production
    if (featureToggleStore.chat) {
        const tawkToPropertyId = process.env.REACT_APP_TAWKTO_ID

        if (!tawkToPropertyId) {
            console.log('No Tawk_To property id defined. Have you set env var TAWKTO_ID?')
        }

        if (persistentStore.user && tawkToPropertyId) {
            tawkTo(tawkToPropertyId, persistentStore.user.email, persistentStore.user.name)
        }
    }

    return (
        <UseTimeContext.Provider value={useTime}>
            <Theme/>
            <Header/>
            <div className="d-flex flex-row mb-5 mb-md-0  main-content">
                {(persistentStore.loggedIn && showSidebar) && <Sidebar/>}
                <div className="main pl-3" style={{flex: 1, maxWidth: '100%'}} >
                    {redirectedFromHome && <RedirectMessage/>}
                    <Switch>
                        <PrivateRoute exact path='/' component={Dashboard}/>
                        <PrivateRoute exact path='/dashboard' component={Dashboard}/>
                        <PrivateRoute path='/customers' component={Customer}/>
                        <PrivateRoute path='/class' component={ClassPage}/>
                        <PrivateRoute path='/reports' component={ReportsPage}/>
                        <PrivateRoute path='/sales/cards' component={CardTypesPage}/>
                        <PrivateRoute path='/cards' component={CustomerCardsPage}/>
                        {/*<PrivateRoute path='/units' component={UnitsPage}/>*/}
                        <PrivateRoute path='/units/edit/:customerId/:unitid' component={UnitEdit}/>
                        <PrivateRoute path='/integrations' component={IntegrationsPage}/>
                        <PrivateRoute exact path='/config/:tab' component={Config}/>
                        <PrivateRoute path='/config' component={Config}/>
                        {persistentStore.isAdmin &&
                        <>
                            <PrivateRoute exact path='/users' component={UsersPage}/>
                            <PrivateRoute exact path='/users/:id' component={UsersDetailsPage}/>
                        </>
                        }
                        <Route component={() => <div>not found</div>}/>
                    </Switch>

                    {(persistentStore.user?.type === 'trial' && store.showUpgrade) && (
                        <UpgradeTrialModal
                            show={store.showUpgrade}
                            hide={() => store.setShowUpgrade(false)}
                        />
                    )}
                </div>
            </div>

            <Footer/>
            <BottomNav/>
        </UseTimeContext.Provider>
    )
}

export default observer(App);
