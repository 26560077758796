import React from "react";
import Button from "react-bootstrap/Button";
import {useTranslation} from "react-i18next";
import './ImpersonateHeader.scss'
import {useRouter} from "../utils/router";
import persistentStore from "../stores/persistentStore";

export const ImpersonateHeader = () => {
    const {t} = useTranslation()
    const router = useRouter()

    const stopImpersonating = () => {
        persistentStore.stopImpersonating()
        router.push('/users')
    }

    return (
        <Button
            variant='danger'
            className='mr-2'
            onClick={() => stopImpersonating()}
        >
            <i className='fa fa-user' />&nbsp;
            {t('Stop impersonating')}
        </Button>
    );
};
