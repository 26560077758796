import {action, decorate, observable} from 'mobx'

class UiStore {
    paginationLength: number = 15
    moneyOptions = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        style: 'currency',
        currency: 'EUR',
        currencyDisplay: 'symbol',
    }
    moneyNoDecimals = {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        style: 'currency',
        currency: 'EUR',
        currencyDisplay: 'symbol',
    }
    fixedNumberOptions = {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        style: 'decimal',
    }
    percentageOptions = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        style: 'percent',
    }

    setPaginationLength(newLength: number) {
        this.paginationLength = newLength
    }
}

decorate(UiStore, {
    paginationLength: observable,

    setPaginationLength: action,
})

const uiStore = new UiStore()
export default uiStore
