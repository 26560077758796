import React from 'react';
import Col from "react-bootstrap/cjs/Col";
import './BottomNav.scss'
import {BarChart, Users} from 'react-feather';
import {useTranslation} from "react-i18next";
import {Link} from "../utils/router";
import Row from "react-bootstrap/cjs/Row";
import persistentStore from "../stores/persistentStore";

const BottomNav = () => {
    const {t} = useTranslation()

    return (
        <nav className="BottomNav navbar fixed-bottom navbar-light d-block d-md-none">
            <Row>
                <Col className='d-flex justify-content-center text-center'>
                    <Link to='/dashboard'>
                        <BarChart /><br />
                        {t('Dashboard')}
                    </Link>
                </Col>

                {persistentStore.isAdmin && (
                    <Col className='d-flex justify-content-center text-center'>
                        <Link to='/users'>
                            <i className='fa fa-users' /><br />
                            {t('Users')}
                        </Link>
                    </Col>
                )}

                <Col className='d-flex justify-content-center text-center'>
                    <Link to='/customers'>
                        <Users /><br />
                        {t('Customers')}
                    </Link>
                </Col>
            </Row>
        </nav>
    )
}

export default BottomNav;
