import React from "react";
import {users as usersApi} from '../../api/users'
import {Row} from 'react-bootstrap'
import Spinner from 'react-feather/dist/icons/settings'
import UsersTable from "./UsersTable"
import {useTranslation} from "react-i18next";
import {useQuery} from "react-query";

const UsersPage = () => {
    const {t} = useTranslation()

    const users = useQuery('getUsers', usersApi.get)

    return (
        <div id='users-list'>
            <Row className='col-12'>
                <h2>{t('Users')}</h2>
            </Row>
            <Row className='col-12'>
                { users.isLoading && <Spinner className="spinner"/>}
            </Row>
            <Row>
                { users.isFetched && <UsersTable users={users}/> }
            </Row>
        </div>
    );
}

export default UsersPage;
