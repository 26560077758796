import React from 'react';
import {Button, Card} from "react-bootstrap";
import {useTranslation} from "react-i18next";

const SecurityTab = ({setShowDeleteModal}: {setShowDeleteModal: (show:boolean)=>void}) => {
    const {t} = useTranslation()

    return (
        <Card className='mt-4 mb-5'>
            <Card.Body>

                <div>
                    <Button
                        id='btn-delete-account'
                        variant="danger"
                        onClick={() => setShowDeleteModal(true)}
                    >
                        {t('Delete account')}
                    </Button>
                </div>

            </Card.Body>
        </Card>
    )
}

export default SecurityTab;
