import {Link} from 'react-router-dom'
import Spinner from 'react-feather/dist/icons/settings';
import React from 'react'
import {Row, Col, Card} from "react-bootstrap";

import {observer} from 'mobx-react'
import {Folder} from 'react-feather';

export const NumberOfCustomers = observer((props: any) => {
    const {stats, loading, title}: { stats: any, loading: boolean, title: string } = props

    return (
        <Card
            bg='light'
            text='dark'
            className="mb-2"
        >
            <Link to={`/customers`}>
                <Card.Body className="customers_text_center">
                    <Row>
                        <Col md={12}>
                            <Card.Title className="mb-1">{title}</Card.Title>
                        </Col>
                        <Col md={6} className="pr-0 m-auto">
                            <h1>
                                {loading && <Spinner className="spinner"/>}
                                {(!stats.isLoading && stats.data?.customerCount)}
                            </h1>
                        </Col>
                        <Col md={6} className='m-auto text-center'>
                            <Folder className="customer-d-icon"/>
                        </Col>
                    </Row>
                </Card.Body>
            </Link>
        </Card>
    )
})
