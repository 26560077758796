import React from "react";
import moment from 'moment';

import {Document, Page, View, Text, Image, StyleSheet} from '@react-pdf/renderer';
import {useTranslation} from "react-i18next";
import persistentStore from "../../stores/persistentStore";
import {IInvoice} from "../../interfaces/IInvoice";
import {IUser} from "../../interfaces/IUser";
import {IInvoiceItem} from "../../interfaces/IInvoiceItem";
import store from "../../stores/store";
import uiStore from "../../stores/uiStore";

const InvoicePDF = (props: any) => {
    const {t} = useTranslation()
    const invoice: IInvoice = props.invoice
    const subtotalInCents: number = invoice.invoice_items.reduce((carry: number, invoiceItem: IInvoiceItem) => {
        carry += invoiceItem.quantity * invoiceItem.price_in_cents
        return carry
    }, 0)
    const vatInCents: number = invoice.invoice_items.reduce((sum: number, invoiceItem: IInvoiceItem) => {
        sum += invoiceItem.vat_in_cents
        return sum
    }, 0)
    const orderTotal: number = invoice.total_price_in_cents

    const COL1_WIDTH = 60;
    const COLN_WIDTH = (100 - COL1_WIDTH) / 3;

    const styles = StyleSheet.create({
        page: {
            backgroundColor: '#fff',
            padding: 24
        },
        h1: {
            fontSize: 24,
            fontWeight: 500,
        },
        h5: {
            fontSize: 12,
            fontWeight: 500,
        },
        h6: {
            fontSize: 10,
            fontWeight: 500
        },
        paidText: {
            fontSize: 24,
            fontWeight: 500,
            paddingLeft: '135px',
            textTransform: "uppercase"
        },
        body1: {
            fontSize: 9,
            lineHeight: 1.5
        },
        body2: {
            fontSize: 8,
            lineHeight: 1.5
        },
        mb1: {
            marginBottom: 4
        },
        header: {
            flexDirection: 'row',
            justifyContent: 'space-between'
        },
        brand: {
            height: 24,
            width: 24
        },
        references: {
            marginTop: 32,
            flexDirection: 'row',
            justifyContent: 'space-between'
        },
        billing: {
            marginTop: 32
        },
        items: {
            marginTop: 32
        },
        notes: {
            marginTop: 32
        },
        table: {
            display: 'table',
            width: 'auto'
        },
        tableHeader: {},
        tableBody: {},
        tableRow: {
            flexDirection: 'row',
            borderBottomWidth: 1,
            borderColor: '#eeeeee',
            borderStyle: 'solid'
        },
        tableCell1: {
            padding: 6,
            width: `${COL1_WIDTH}%`
        },
        tableCellN: {
            padding: 6,
            width: `${COLN_WIDTH}%`
        },
        alignRight: {
            textAlign: 'right'
        }
    });

    const InvoiceLogo = (props: any) => {
        const {seller}: { seller: IUser } = props
        const InvoiceLogo = seller?.logo
            ? <Image src={`${persistentStore.apiUrl}/img/logo/${seller.logo}`} style={styles.brand}/>
            : <Text style={styles.h2}>{seller?.name}</Text>

        return (InvoiceLogo);
    }

    if (!invoice) {
        return null
    }

    return (
        <>
            <Document>
                <Page size="A4" style={styles.page}>
                    <View style={styles.header}>
                        <View>
                            <InvoiceLogo seller={invoice.seller}/>
                        </View>
                        <View>
                            <Text style={styles.paidText}>
                                {invoice.status}
                            </Text>
                            <Text style={styles.h5}>
                                {t('Invoice')} #
                                {invoice.public_id}
                            </Text>
                        </View>
                    </View>

                    <View style={[styles.references]}>
                        <View>
                            <Text style={[styles.h5, styles.mb1]}>
                                {t('Date of issue')}
                            </Text>
                            <Text style={styles.body1}>
                                {moment(invoice.created).format('DD-M-YYYY')}
                            </Text>
                        </View>
                        <View></View>
                    </View>

                    <View style={styles.billing}>
                        <Text style={styles.body1}>
                            {invoice.seller.name}
                        </Text>
                        <Text style={styles.body1}>
                            {invoice.user_company_name ? invoice.user_company_name : ''}
                        </Text>
                        <Text style={styles.body1}>
                            {invoice.user_company_address ? invoice.user_company_address : ''}
                            {invoice.user_company_zipcode ? ", "+invoice.user_company_zipcode : ''}
                            {invoice.user_company_city ? ", "+invoice.user_company_city : ''}
                        </Text>
                        <Text style={styles.body1}>{invoice.user_company_coc_number ? t('CoC') + ': ' + invoice.user_company_coc_number : ''}</Text>
                        <Text style={styles.body1}>{invoice.user_company_vat_number ? t('VAT') + ': ' + invoice.user_company_vat_number : ''}</Text>
                    </View>

                    <View style={styles.billing}>
                        <Text style={[styles.h5, styles.mb1]}>
                            {t('Billed to')}
                        </Text>
                        <Text style={styles.body1}>
                            {invoice.buyer.name}
                        </Text>
                        <Text style={styles.body1}>
                            {invoice.customer_company_name ? invoice.customer_company_name : ''}
                        </Text>
                        <Text style={styles.body1}>
                            {invoice.customer_company_address ? invoice.customer_company_address : ''}
                            {invoice.customer_company_zipcode ? ", "+invoice.customer_company_zipcode : ''}
                            {invoice.customer_company_city ? ", "+invoice.customer_company_city : ''}
                            {invoice.buyer.invoice_references}
                        </Text>
                        <Text style={styles.body1}>{invoice.customer_company_coc_number ? t('CoC') + ': ' + invoice.customer_company_coc_number : ''}</Text>
                        <Text style={styles.body1}>{invoice.customer_company_vat_number ? t('VAT') + ': ' + invoice.customer_company_coc_number : ''}</Text>
                    </View>

                    <View style={styles.items}>
                        <View style={styles.table}>
                            <View style={styles.tableHeader}>
                                <View style={styles.tableRow}>
                                    <View style={styles.tableCell1}>
                                        <Text style={styles.h6}>
                                            {t('Description')}
                                        </Text>
                                    </View>
                                    <View style={styles.tableCellN}>
                                        <Text style={styles.h6}>
                                            {t('Qty')}
                                        </Text>
                                    </View>
                                    <View style={styles.tableCellN}>
                                        <Text style={styles.h6}>
                                            {t('Price')}
                                        </Text>
                                    </View>
                                    <View style={styles.tableCellN}>
                                        <Text style={[styles.h6, styles.alignRight]}>
                                            {t('Subtotal')}
                                        </Text>
                                    </View>
                                </View>
                            </View>

                            <View style={styles.tableBody}>
                                {invoice.invoice_items.map((invoiceItem: IInvoiceItem, index: number) => (
                                    <View style={styles.tableRow} key={index}>
                                        <View style={styles.tableCell1}>
                                            <Text style={styles.body2}>
                                                {invoiceItem.description}
                                            </Text>
                                        </View>
                                        <View style={styles.tableCellN}>
                                            <Text style={styles.body2}>
                                                {invoiceItem.quantity}
                                            </Text>
                                        </View>
                                        <View style={styles.tableCellN}>
                                            <Text style={styles.body2}>
                                                {((invoiceItem.price_in_cents) / 100).toLocaleString(store.locale, uiStore.moneyOptions)}
                                            </Text>
                                        </View>
                                        <View style={styles.tableCellN}>
                                            <Text style={[styles.body2, styles.alignRight]}>
                                                {((invoiceItem.quantity * invoiceItem.price_in_cents) / 100).toLocaleString(store.locale, uiStore.moneyOptions)}
                                            </Text>
                                        </View>
                                    </View>
                                ))}

                                <View style={styles.tableRow}>
                                    <View style={styles.tableCell1}/>
                                    <View style={styles.tableCellN}/>
                                </View>

                                <View style={styles.tableRow}>
                                    <View style={styles.tableCell1}/>
                                    <View style={styles.tableCellN}/>
                                    <View style={styles.tableCellN}>
                                        <Text style={styles.body2}>
                                            {t('Subtotal')}
                                        </Text>
                                    </View>
                                    <View style={styles.tableCellN}>
                                        <Text style={[styles.body2, styles.alignRight]}>
                                            {(subtotalInCents / 100).toLocaleString(store.locale, uiStore.moneyOptions)}
                                        </Text>
                                    </View>
                                </View>
                                <View style={styles.tableRow}>
                                    <View style={styles.tableCell1}/>
                                    <View style={styles.tableCellN}/>
                                    <View style={styles.tableCellN}>
                                        <Text style={styles.body2}>
                                            {t('VAT')}
                                        </Text>
                                    </View>
                                    <View style={styles.tableCellN}>
                                        <Text style={[styles.body2, styles.alignRight]}>
                                            {(vatInCents / 100).toLocaleString(store.locale, uiStore.moneyOptions)}
                                        </Text>
                                    </View>
                                </View>
                                <View style={styles.tableRow}>
                                    <View style={styles.tableCell1}/>
                                    <View style={styles.tableCellN}/>
                                    <View style={styles.tableCellN}>
                                        <Text style={styles.body2}>
                                            {t('Total')}
                                        </Text>
                                    </View>
                                    <View style={styles.tableCellN}>
                                        <Text style={[styles.body2, styles.alignRight]}>
                                            {(orderTotal / 100).toLocaleString(store.locale, uiStore.moneyOptions)}
                                        </Text>
                                    </View>
                                </View>
                            </View>
                        </View>
                    </View>
                </Page>
            </Document>
        </>
    );
}

export default InvoicePDF;
