import Spinner from 'react-feather/dist/icons/settings';
import React, {useEffect, useState} from 'react'
import {Card} from "react-bootstrap";
import {Bar} from 'react-chartjs-2';
import 'chartjs-plugin-annotation';
import {IChartDataset} from "../../interfaces/IChartDataset";
import {IChartData} from "../../interfaces/IChartData";
import {useQuery} from "react-query";
import {stats} from "../../api/stats";

type Props = {
    title: string,
    time: "monthly" | "daily"
}

export const GraphPageviewTargets = (props: Props) => {
    const {title, time} = props
    // const [time, setTime] = useState<'daily' | 'monthly'>('daily')
    const [labels, setLabels] = useState<string[]>([])
    const [data, setData] = useState<number[]>([])
    const pageviewQuery = useQuery('pageviewStats' , () => stats.getPageviewStats())
    const monthTarget = 540
    const dayTarget = 18

    useEffect(() => {
        if(! pageviewQuery.data){
            return
        }

        const pageviews = time === 'daily' ? pageviewQuery.data.daily : pageviewQuery.data.monthly
        const pageviewLabels = Object.keys(pageviews)
        const pageviewValues: number[] = Object.values(pageviews)

        setLabels(pageviewLabels)
        setData(pageviewValues)

    }, [pageviewQuery.data, time])


    const colors = {
        pink: 'rgba(255,204,255,1)',
        pinkOpacity: 'rgba(255,204,255,0.4)',
        blue: 'rgba(102,153,255,1)',
        blueOpacity: 'rgba(102,153,255,0.4)',
        green: 'rgba(102,204,153,1)',
        greenOpacity: 'rgba(102,204,153,0.4)',
    }

    const defaultChartDataSet: IChartDataset = {
        label: 'change me',
        backgroundColor: 'rgba(0,123,255,0.4)',
        borderCapStyle: 'butt',
        borderColor: 'rgba(0,123,255,1)',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        data: [],
        fill: true,
        lineTension: 0.1,
        pointBackgroundColor: '#fff',
        pointBorderColor: 'rgba(0,123,255,1)',
        pointBorderWidth: 1,
        pointHitRadius: 10,
        pointHoverBackgroundColor: 'rgba(0,123,255,1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointHoverRadius: 5,
        pointRadius: 1,
    };

    const pageviewChartDataSet: IChartDataset = Object.assign({}, defaultChartDataSet, {
        label: 'pageviews',
        backgroundColor: colors.pinkOpacity,
        borderColor: colors.pink,
        // data: Object.values(selectedStats?.total_users || []),
        data: data
    })

    const chartData: IChartData = {
        // should be: dec, jan, feb, ...
        labels: labels,
        datasets: [pageviewChartDataSet]
    }

    const options = {
        legend: {
            display: true
        },
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true
                }
            }]
        },
        annotation: {
            annotations: [{
                type: 'line',
                mode: 'horizontal',
                scaleID: 'y-axis-0',
                value: time === 'daily' ? dayTarget : monthTarget,
                borderDash: [2, 2],
                borderColor: 'tomato',
                borderWidth: 1,
                label: {
                    backgroundColor: 'red',
                    content: 'pageview target',
                    cornerRadius: 4,
                    enabled: true,
                    fontColor: '#fff',
                    fontFamily: 'sans-serif',
                    fontSize: 10,
                    fontStyle: 'bold',
                    position: 'end',
                    textAlign: 'center',
                    xPadding: 4,
                    yPadding: 4,
                }
            }],
            drawTime: "afterDraw" // (default)
        }
    }

    return (
        <Card>
            <Card.Header as="h5" style={{display: 'flex'}}>
                <div>
                    {title}
                </div>
            </Card.Header>
            <Card.Body>
                <Bar data={chartData} options={options}/>
                {pageviewQuery.isLoading && <h1 className="text-center"><Spinner className="spinner"/></h1>}
            </Card.Body>
        </Card>
    )
}
