import React from "react";
import {Button} from 'react-bootstrap'
import DataTable from "react-data-table-component";
import {useTranslation} from "react-i18next";
import {useRouter} from "../../utils/router";
import store from "../../stores/store";
import {IUser} from "../../interfaces/IUser";
import moment from "moment";
import FilterComponent from "../../components/FilterComponent";
import {users as usersApi} from '../../api/users';
import persistentStore from "../../stores/persistentStore";

const UsersTable = (props: any) => {
    const [filterText, setFilterText] = React.useState('');
    const {t} = useTranslation()
    const router = useRouter()
    const users = props.users

    const impersonate = (user: IUser) => {
        if (user.id === undefined) {
            console.error('No id known for user. Cannot impersonate')
            return
        }

        usersApi.impersonate(user.id)
            .then((result: any) => {
                router.push('/')
            })
    }

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setFilterText('');
            }
        };

        return <FilterComponent
            onFilter={(e: any) => setFilterText(e.target.value)}
            onClear={handleClear}
            filterText={filterText}
        />;
    }, [filterText]);

    const filteredUsers = (users.data || [])
        .filter((user: IUser) => {
            if (filterText === '') {
                return true
            }
            const data = JSON.stringify(user).toLowerCase()
            const query = filterText.toLowerCase()

            return data && data.includes(query)
        })

    const columns = [
        {
            name: t('Name'),
            selector: 'name',
            sortable: true,
        },
        {
            name: t('Type'),
            selector: 'type',
            sortable: true,
            cell: (row: IUser) => row.type
        },
        {
            name: t('Email'),
            selector: 'email',
            sortable: true,
        },
        {
            name: t('Customers'),
            sortable: true,
            selector: 'customer_count',
        },
        {
            name: t('Requests'),
            sortable: true,
            selector: 'request_count',
        },
        {
            name: t('Last time'),
            selector: 'last_seen',
            sortable: true,
            cell: (user: IUser) => {
                return user.last_seen
                    ? `${moment(user.last_seen).fromNow()}`
                    : ''
            },
        },
        {
            name: t('Last seen'),
            selector: 'last_seen',
            sortable: true,
            cell: (user: IUser) => {
                return user.last_seen
                    ? moment(user.last_seen).format('YYYY-MM-DD HH:mm')
                    : ''
            },
        },
        {
            name: t('Action'),
            cell: (row: any) => (
                <Button variant="secondary" onClick={() => impersonate(row)} className='d-flex flex-row'>
                    <i className='fa fa-user mt-1'/>
                    <span className='d-none d-lg-block'>
                        &nbsp; {t('Impersonate')}
                    </span>
                </Button>
            )
        }
    ]

    const onRowClicked = (user: IUser) => {
        store.setUserView(user)
        router.push(`/users/${user.id}`)
    }

    return (
        <DataTable
            className='CustomerListView customer-list-view'
            columns={columns}
            data={filteredUsers}
            pagination
            paginationPerPage={persistentStore.pageLength}
            paginationRowsPerPageOptions={persistentStore.pageLengthOptions}
            onChangeRowsPerPage={(rowCount) => persistentStore.setPageLength(rowCount)}
            noHeader={true}
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            onRowClicked={(event) => onRowClicked(event)}
            defaultSortField='last_seen'
            defaultSortAsc={false}
        />
    )
}

export default UsersTable
