import React, {useCallback, useEffect, useState} from 'react'
import {observer} from 'mobx-react'
import {units} from '../../api/units'
import {toast} from 'react-toastify'
import './UnitAdder.scss'
import Spinner from "react-bootstrap/Spinner";
import {Alert, Col, Row} from 'react-bootstrap'
import {useTranslation} from "react-i18next";
import store from "../../stores/store";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from "react-bootstrap/Button";
import {stripnameTimePlural, stripnameTimeSingular} from "../../utils/stripname";
import {ICustomer} from "../../interfaces/ICustomer";
import persistentStore from "../../stores/persistentStore";
import {QueryResult} from "react-query";
import {ICard} from "../../interfaces/ICard";
import RecurrentModal from "../../components/RecurrentModal";
import {INewRecurrence} from "../../interfaces/IRecurrence";
import {INewUnit} from "../../interfaces/IUnit";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSync} from "@fortawesome/free-solid-svg-icons/faSync";
import featureToggleStore from "../../stores/featureToggleStore";

type UnitAdderProps = {
    invalidateCache: () => void,
    className: string,
    customerQuery?: QueryResult<ICustomer>
}

const UnitAdder = (props: UnitAdderProps) => {
    const [loading, setLoading] = useState(false)
    const [amount, setAmount] = useState<any>(1)
    const [notifyByEmail, setNotifyByEmail] = useState(persistentStore.user?.settings.unitAdder.mailByDefault || false)
    const [description, setDescription] = useState('')
    const [creationDate, setCreationDate] = useState<any>(new Date())
    const [cardToUse, setCardToUse] = useState<number>()
    const [availableCards, setAvailableCards] = useState<ICard[]>()
    const [error, setError] = useState<string>('')
    const [customer, setCustomer] = useState<ICustomer>()
    const {t} = useTranslation()
    const {invalidateCache, customerQuery} = props
    const [showRecurrentModal, setShowRecurrentModal] = useState(false)
    const useTime = ['time', 'hours', 'uur'].includes(persistentStore.user?.settings.useType || 'strippen')
    const emptyRecurrence: INewRecurrence = {
        repeat_every: 1,
        repeating_timeframe: 'week',
        repeating_days: 0,
        ends_after_occurrences: 0,
        ends_on_date: '',
        start_date: undefined,
        active: true
    }
    const [recurrence, setRecurrence] = useState(emptyRecurrence)
    const [hasRecurrence, setHasRecurrence] = useState(false)

    useEffect(() => {
        if (useTime) {
            setAmount('01:00')
        }
    }, [useTime])

    const recurrenceIsEmpty = useCallback(
        (recurrenceToCheck: INewRecurrence): boolean => {
            return recurrencesAreTheSame(emptyRecurrence, recurrenceToCheck)
        },
        [emptyRecurrence]
    )

    // check if recurrences has been undone
    useEffect(() => {
            const hasRecurrenceSet = ! recurrenceIsEmpty(recurrence)

            setHasRecurrence(hasRecurrenceSet)
    }, [recurrence, emptyRecurrence, recurrenceIsEmpty])

    useEffect(() => {
        if (customer?.cards && customer.cards.length > 0) {
            const cardsWithStripsAvailable = customer.cards.filter((card: ICard) => card.strips_remaining > 0)
            setAvailableCards(cardsWithStripsAvailable)
            setCardToUse(cardsWithStripsAvailable[0].id)
        }

    }, [customer])

    // if we received customer via props, use that
    // otherwise, fetch the customer ourselves
    useEffect(() => {
        if (customerQuery?.data) {
            setCustomer(customerQuery.data)
        }
    }, [customerQuery])

    useEffect(() => {
        // uncheck the mail checkbox when this customer has no mailaddress
        if (!customer?.email) {
            setNotifyByEmail(false)
            return
        }

        if (persistentStore.user) {
            setNotifyByEmail(persistentStore.user.settings.unitAdder.mailByDefault)
        }
    }, [customer])

    useEffect(() => {
        if (notifyByEmail) {
            const customerHasEmailAddress = (customer && customer.email)
            if (!customerHasEmailAddress) {
                setNotifyByEmail(false)
            }
        }
    }, [notifyByEmail, customer])

    const recurrencesAreTheSame = (obj1: INewRecurrence, obj2: INewRecurrence) => {
        const sameLength = (Object.keys(obj1).length === Object.keys(obj2).length)
        const sameKeyValues = Object
            .keys(obj1)
            // @ts-ignore
            .every(key => obj2.hasOwnProperty(key) && obj1[key] === obj2[key] )

        return sameLength && sameKeyValues
    }

    const handleNotifyByEmailClicked = (event: any) => {
        const checked: boolean = event.target.checked

        if (checked && customer && !customer.email) {
            toast.error(t('Customer has no emailaddress set. Please provide one if you want to notify the customer by email'))
        }

        setNotifyByEmail(checked)
    }

    const isValidAmount = (amountToValidate: number) => {

        // must be numeric
        if (isNaN(amountToValidate)) {
            return false
        }

        return amountToValidate >= 1
    }

    /**
     * Empty the error message and make sure the error-div is not hidden
     * anymore
     */
    const resetError = () => {
        setError('')
    }

    // The function which is called when submitting a work-entry ( aka adding
    // a unit to a card) when the form is submitted
    const addUnit = (event: any) => {
        event.preventDefault()
        store.triggerEvent('unit_added')

        resetError()

        if (persistentStore.user?.settings.useType !== 'hours') {
            // only do something when a valid value has been provided
            if (!isValidAmount(amount)) {
                setError('Geef 1 of meer strippen op.')
                return
            }
        }

        if (customerQuery?.data === undefined) {
            setError(t('Customer is not defined, cannot add a unit'))
            return
        }

        const unit: INewUnit = {
            amount: amount,
            description: description,
            customer_public_id: `${customer?.public_id}`,
            notify_by_email: notifyByEmail,
            card_to_use: cardToUse,
            creation_date: creationDate,
            // recurrence: recurrence
        }

        // add the recurrence if one was provided
        if(hasRecurrence && ! recurrenceIsEmpty(recurrence)){
            unit.recurrence = recurrence
            unit.recurrence.start_date = creationDate
        }

        sendUnitToAPi(unit)
    }

    const sendUnitToAPi = (unit: INewUnit) => {
        setLoading(true)

        units.add(unit)
            .then((response) => {
                setLoading(false)

                // show different message when a recurrence was added
                if(response.units.length > 0 && response.units[0].recurrence_id > 0){
                    toast.success('Herhaalde afboeking toegevoegd')
                }
                else {
                    if (persistentStore.user?.settings.useType === 'hours') {
                        toast.success('tijd afgeboekt')
                    } else {
                        const name = unit.amount === 1 ? stripnameTimeSingular() : stripnameTimePlural()
                        toast.success(name + ' afgeboekt')
                    }
                }

                // reset the form
                resetForm()

                // update the customer in the store with information from the server
                invalidateCache()
            })
            .catch((error) => {
                setLoading(false)
                if (!error.response) {
                    console.error(error)
                    return
                }
                if (error.response.data.errors) {
                    toast.error(error.response.data.errors.detail)
                }
            })
    }

    const resetForm = () => {
        setDescription('')
        setAmount(useTime ? '01:00' : 1)
        setRecurrence(emptyRecurrence)
    }

    return (
        <>
            <Row id="UnitAdder" className={`UnitAdder block-row unit-adder ml-0 col-sm-12`}>
                <Col sm={12} className="info-block">
                    <form>
                        <div className="form-row">
                            <Col md={3} className="form-group">
                                {useTime ? (
                                    <>
                                        <label htmlFor="amountUnits" className="text-left">
                                            Tijd
                                        </label>
                                        <input type="time"
                                               className="form-control"
                                               onChange={(e) => {
                                                   setAmount(e.target.value)
                                               }}
                                               value={amount}
                                               disabled={loading || customerQuery?.isLoading}
                                        />
                                    </>

                                ) : (
                                    <>
                                        <label htmlFor="amountUnits"
                                               className="text-left">Aantal {useTime ? 'strippen' : stripnameTimePlural()}</label>
                                        <select
                                            className="form-control col-10"
                                            id="amountUnits"
                                            onChange={(e) => {
                                                setAmount(parseInt(e.target.value, 10))
                                            }}
                                            value={amount}
                                            disabled={loading || customerQuery?.isLoading}
                                        >
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                        </select>
                                    </>
                                )}
                            </Col>

                            <Col md={9} className="form-group">
                                <label htmlFor="inlineFormInputName2">{t('Description')}</label>
                                <input type="textarea"
                                       className="form-control"
                                       id="inlineFormInputName2"
                                       onChange={(e) => setDescription(e.target.value)}
                                       placeholder="Bijv: boekhouding bijgewerkt"
                                       value={description}
                                       disabled={loading || customerQuery?.isLoading}
                                />
                            </Col>
                        </div>

                        <div className="form-row">
                            <Col md={12} className="form-group">
                                <label htmlFor="inlineFormCreationDate">{hasRecurrence ? t('Start date') : t('Creation date')}</label>
                                <DatePicker
                                    selected={creationDate}
                                    onChange={date => setCreationDate(date)}
                                    dateFormat="dd-MM-yyyy, H:mm"
                                    className="form-control"
                                    showTimeSelect
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    placeholderText="Creation date"
                                    locale="nl"
                                />
                            </Col>

                            {(availableCards?.length || 0) > 1 && (
                                <Col md={12} className='mb-4'>
                                    <label
                                        htmlFor="availableCard"
                                        className="text-left"
                                    >
                                        {t('Card to book off of')}
                                    </label>
                                    <select
                                        className="form-control col-10"
                                        id="availableCards"
                                        onChange={(e) => {
                                            setCardToUse(parseInt(e.target.value, 10))
                                        }}
                                        value={cardToUse || customer?.cards[0].id}
                                    >
                                        {availableCards?.map((card: ICard, index: number) => (
                                            <option value={card.id} key={index}>
                                                {
                                                    (card.description === undefined || card.description === '')
                                                        ? 'Kaart zonder beschrijving'
                                                        : card.description
                                                }
                                            </option>
                                        ))}
                                    </select>
                                </Col>
                            )}

                            <Col md={6} className="form-group">
                                <Button
                                    id='decrease-strips'
                                    type="submit"
                                    className="btn btn-primary"
                                    onClick={addUnit}
                                    disabled={loading || customerQuery?.isLoading}
                                >
                                    {(loading || customerQuery?.isLoading) &&
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>}
                                    {(!loading && !customerQuery?.isLoading) && `${stripnameTimePlural() === 'uur' ? 'tijd' : stripnameTimePlural()}  afboeken`}
                                </Button>

                                {featureToggleStore.recurrence && (
                                    <button
                                        id='add-recurring'
                                        className={`btn  ml-2 ${hasRecurrence ? 'btn-outline-success' : 'btn-outline-secondary'}`}
                                        onClick={() => setShowRecurrentModal(true)}
                                        disabled={showRecurrentModal}
                                    >
                                        <FontAwesomeIcon icon={faSync} className="material-icons mr-1"/>
                                        {hasRecurrence && (
                                            t('Recurring')
                                        )}
                                        {! hasRecurrence && (
                                            t('Not recurring')
                                        )}
                                    </button>
                                )}
                            </Col>

                            <Col md={6} className="form-group">
                                <label className="checkbox-inline"
                                       style={(customer && !customer?.email) ? {textDecoration: 'line-through'} : {}}
                                >
                                    <input
                                        type="checkbox"
                                        id="notify_by_email"
                                        checked={notifyByEmail}
                                        onChange={handleNotifyByEmailClicked}
                                        className='mr-1'
                                        readOnly={!customer?.email || customerQuery?.isLoading}
                                    />
                                    {t('Notify customer by email')}
                                </label>
                            </Col>
                        </div>
                    </form>

                    {error && <Alert variant='danger'>{error}</Alert>}
                </Col>
            </Row>

            {showRecurrentModal && (
                <RecurrentModal
                    show={showRecurrentModal}
                    hide={() => setShowRecurrentModal(false)}
                    onChange={setRecurrence}
                    recurrence={recurrence}
                />
            )}
        </>
    )
}

export default observer(UnitAdder)
