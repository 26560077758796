import {moment} from "./helpers";
import persistentStore from "../stores/persistentStore";

const getDaysUntilExpiration = (expirationDate) => {
    // add 1 day to make the dueDate inclusive (e.g. 'tot en met')
    const dueDate = moment(expirationDate).add(1, 'days');
    const now     = moment();
    let diffDays = dueDate.diff(now, 'days');
    const diffHours = dueDate.diff(now, 'hours')%24;

    // if there are some hours left, count them as a full day
    if(diffHours > 0){
        diffDays += 1;
    }

    return diffDays;
};

const stripSingularOrPlural = (amount=0) => {
    return amount === 1 ? persistentStore.user?.settings?.stripName.singular : persistentStore.user?.settings?.stripName.plural;
};

const stripTimeSingularOrPlural = (amount=0) => {
    if(persistentStore.user?.settings.useType === 'strips'){
        return amount === 1 ? 'time' : 'times';
    }else{
        return amount === 1 ? persistentStore.user?.settings?.stripName.singular : persistentStore.user?.settings?.stripName.plural;
    }
   
};

export {getDaysUntilExpiration, stripSingularOrPlural, stripTimeSingularOrPlural};
