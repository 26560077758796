import React, {useEffect, useState} from "react";
import {Row, Col, Jumbotron, Button, Spinner} from 'react-bootstrap'
import {Mail, CheckCircle, CheckSquare, Eye} from 'react-feather';
import moment from "moment";
import {useTranslation} from "react-i18next";
import OrderTable from "./OrderTable";
import EventsTable from "./EventsTable";
import {users as usersApi} from '../../api/users';
import {useRouter} from "../../utils/router";
import {useQuery} from "react-query";
import SubscriptionTable from "./SubscriptionTable";
import {toast} from "react-toastify";

const UsersDetailsPage = () => {
    const {t} = useTranslation()
    const router = useRouter()
    const [loading, setLoading] = useState(false)

    const [entrepreneurType, setEntrePreneurType] = useState('')
    const [ageCategory, setAgeCategory] = useState('')
    const [sex, setSex] = useState('')

    const user = useQuery('getUser', () => usersApi.getOne(router.query.id))
    const settings = user.data?.settings


    useEffect(() => {
        if (user.data) {
            setEntrePreneurType(user.data.entrepreneur_type || '')
            setAgeCategory(user.data.age_category || '')
            setSex(user.data.sex || '')
        }
    }, [user.data])

    useEffect(() => {
        if (!user.data) {
            return
        }

        setEntrePreneurType(user.data.entrepreneur_type)
        setSex(user.data.sex)
        setAgeCategory(user.data.age_category)
    }, [user.data])

    const getPreviewText = () => {
        const emailTemplate: string = settings?.emailTemplate || ''
        return {__html: emailTemplate}
    }

    const getPreviewCardText = () => {
        const emailCardTemplate: string = settings?.emailCardTemplate || ''
        return {__html: emailCardTemplate}
    }

    const impersonate = () => {
        if (user.data?.id === undefined) {
            console.error('No id known for user. Cannot impersonate')
            return
        }

        usersApi.impersonate(user.data?.id)
            .then(() => {
                router.push('/')
            })
    }

    const handleSubmitUserInfo = (e: any) => {
        e.preventDefault();
        setLoading(true)
        usersApi
            .updateUserInfo({
                entrepreneur_type: entrepreneurType,
                age_category: ageCategory,
                sex: sex
            }, router.query.id)
            .then((response: any) => {
                setLoading(false)
                if (response.success === true) {
                    toast.success(t('User info updated'))
                    return
                }
            })
            .catch((error: any) => {
                console.log(`error caught when updating user info: `, error)
            })
    };

    const mailByDefault = settings?.unitAdder?.mailByDefault

    return (
        <div id='users-list'>
            <Row className='col-12'>
                <h2>
                    {user.data?.name}
                    <Button variant="secondary" className='ml-1' onClick={() => impersonate()}>
                        {t('Impersonate')}
                    </Button>
                </h2>
            </Row>
            <Row className='col-12'>
                <Col md={12} className='p-0'>
                    <Jumbotron>
                        <Row>
                            <Col md={4}>
                                <h5 title="Email">
                                    <Mail className='mr-2'/>
                                    {user.data?.email}
                                </h5>
                            </Col>

                            <Col md={4}>
                                <h5 title="Customer type" className="text-capitalize">
                                    <CheckCircle className='mr-2'/>
                                    {user.data?.type}
                                    {user.data?.type === 'trial' && (
                                        <div>
                                            {moment().diff(user.data?.created, 'days')}&nbsp;{t('days')}
                                        </div>
                                    )}
                                </h5>
                            </Col>

                            <Col md={4}>
                                <h5 title="Last seen">
                                    <Eye className='mr-2'/>
                                    {moment(user.data?.last_seen || user.data?.created).fromNow(true)}
                                    &nbsp;{t('ago')} ({moment(user.data?.last_seen || user.data?.created).format('DD-MM-YYYY')})
                                </h5>
                            </Col>
                        </Row>
                    </Jumbotron>
                </Col>
            </Row>

            <Row className='col-12'>
                <Col md={12} className='p-0'><h3>{t('Settings')}</h3></Col>
                <Col md={6} className='p-0 pr-3'>
                    <Jumbotron>
                        <h5>{t('Email Template')}</h5>
                        <p>{t('Send email to customer when strips are debited')}</p>
                        <div className="mt-3" dangerouslySetInnerHTML={getPreviewText()}/>
                    </Jumbotron>
                </Col>
                <Col md={6} className='p-0'>
                    <Jumbotron>
                        <h4>{t('User info')}</h4>
                        <hr/>
                        <form onSubmit={handleSubmitUserInfo}>
                            <div className="form-group">
                                <label htmlFor="sex">{t('Sex')}</label>
                                <select
                                    className="form-control"
                                    id="sex"
                                    value={sex}
                                    onChange={(e) => setSex(e.target.value)}
                                >
                                    <option >{t('Select option')}</option>
                                    <option value='male'>{t('male')}</option>
                                    <option value='female'>{t('female')}</option>
                                    <option value='other'>{t('other')}</option>
                                </select>
                            </div>

                            <div className="form-group">
                                <label htmlFor="age-group">{t('Age group')}</label>
                                <select
                                    className="form-control"
                                    id="age-group"
                                    value={ageCategory}
                                    onChange={(e) => setAgeCategory(e.target.value)}
                                >
                                    <option>{t('Select option')}</option>
                                    <option value='20-29'>20 - 29</option>
                                    <option value='30-39'>30 - 39</option>
                                    <option value='40-49'>40 - 49</option>
                                    <option value='50-59'>50 - 59</option>
                                    <option value='60-69'>60 - 69</option>
                                </select>
                            </div>

                            <div className="form-group">
                                <label htmlFor="branch">{t('Branche of work')}</label>
                                <select
                                    className="form-control"
                                    id="branch"
                                    value={entrepreneurType}
                                    onChange={(e) => setEntrePreneurType(e.target.value)}
                                >
                                    <option>{t('Select option')}</option>
                                    <option value='knowledge worker'>{t('knowledge worker')}</option>
                                    <option value='practicioner'>{t('practicioner')}</option>
                                    <option value='trainer - coach'>{t('trainer / coach')}</option>
                                    <option value='other'>{t('other')}</option>
                                </select>
                            </div>
                            <Button
                                type="submit"
                                size='lg'
                                id='btn-customer-save'
                                variant="primary"
                                disabled={loading}
                            >
                                {loading &&
                                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>}
                                {!loading && t('Save')}
                            </Button>
                        </form>
                    </Jumbotron>
                </Col>
            </Row>

            <Row className='col-12'>
                <Col md={6} className='p-0 pr-3'>
                    <Jumbotron>
                        <h5>Email Template</h5>
                        <p>Send email to customer when strips are low</p>
                        <h5>Send mail when this many strips are left : {settings?.emailCardLeft}</h5>
                        <div className="mt-3" dangerouslySetInnerHTML={getPreviewCardText()}/>
                    </Jumbotron>
                </Col>
                <Col md={6} className='p-0'>
                    <Jumbotron>
                        <hr/>
                        <h5>Strippen, credits or something else?</h5>
                        <p className="text-capitalize">
                            <CheckSquare className='mr-2'/>
                            {settings?.stripName.plural}
                        </p>
                        <hr/>
                        <h5>Time or Strips</h5>
                        <p className="text-capitalize"><CheckSquare className='mr-2'/> {settings?.useType}</p>
                        <hr/>
                        <h5>Mail By Default</h5>
                        <p><CheckSquare className='mr-2'/> {mailByDefault ? 'True' : 'False'}</p>
                        <hr/>
                    </Jumbotron>
                </Col>
            </Row>
            <Row className='col-12'>
                <Col md={6} className='p-0 pr-3'>
                    <h3>{t('Events')}</h3>
                    <EventsTable user={user} events={user.data?.events || []}/>
                </Col>
                <Col md={6} className='p-0 pr-3'>
                    <h3>{t('Orders')}</h3>
                    <OrderTable orders={user.data?.orders || []}/>
                </Col>
            </Row>
            <Row className='col-12'>
                <Col md={6} className='p-0 pr-3'>
                    <h3>{t('Subscriptions')}</h3>
                    <SubscriptionTable user={user}/>
                </Col>
            </Row>
        </div>
    );
}

export default UsersDetailsPage;
