import React, {useState} from 'react';
import DataTable from "react-data-table-component";
import {useTranslation} from "react-i18next";
import FilterComponent from "../../components/FilterComponent";
import moment from "moment/moment";
import {ISubscription} from "../../interfaces/ISubscription";
import SubscriptionEditModal from "./SubscriptionEditModal";
import persistentStore from "../../stores/persistentStore";
import {toast} from 'react-toastify';
import {subscriptions as subscriptionsApi} from '../../api/subscriptions';
import './SubscriptionTable.scss'
import {Edit2} from "react-feather";

const SubscriptionTable = (props: any) => {
    const {t} = useTranslation()
    const [filterText, setFilterText] = React.useState<string>('');
    const {user} = props
    const [showModal, setShowModal] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [editedSubscription, setEditedSubscription] = useState<ISubscription>({} as ISubscription)

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setFilterText('');
            }
        };

        return <FilterComponent
            onFilter={(e: any) => setFilterText(e.target.value)}
            onClear={handleClear}
            filterText={filterText}
        />;
    }, [filterText]);

    const columns = [
        {
            name: t('Status'),
            selector: 'status',
            sortable: true,
        },
        {
            name: t('Type'),
            selector: 'type',
            sortable: true,
        },
        {
            name: t('Price'),
            selector: 'paid_amount_in_cents',
            sortable: true,
            cell: (subscription: ISubscription) => parseFloat((subscription.paid_amount_in_cents / 100).toString()),
        },
        {
            name: t('Access until'),
            selector: 'grants_access_until',
            sortable: true,
            cell: (subscription: ISubscription) => moment(subscription.grants_access_until).format('YYYY-MM-DD'),
        },
        // {
        //     name: t('Invoice'),
        //     sortable: false,
        //     cell: (subscription: ISubscription) => <a href={`/invoice/${subscription.invoice.public_id}`}>{t('invoice')}</a>
        // },
        {
            name: t('Created'),
            selector: 'created',
            sortable: true,
            cell: (subscription: ISubscription) => moment(subscription.created).format('YYYY-MM-DD'),
        },
        {
            name: t('Action'),
            selector: 'action',
            sortable: true,
            ignoreRowClick: true,
            cell: (subscription: ISubscription) => (
                <Edit2
                    onClick={() => handleEditSubscription(subscription)}
                    className='edit-icon'
                />
            ),
        }
    ]

    const handleEditSubscription = (subscription: ISubscription) => {
        setEditedSubscription(subscription)
        setShowModal(true)
    }

    const handleUpdateSubscription = (subscription: ISubscription) => {
        setLoading(true)
        subscriptionsApi
            .update(subscription)
            .then((response: any) => {
                setLoading(false)

                if (response.success === true) {
                    toast.success(t('subscription info updated'))
                    setShowModal(false)
                }
            })
            .catch((error: any) => {
                console.log(`error caught when updating subscription: `, error)
            })
    }

    const filteredSubscriptions = (user.data?.subscriptions || []).filter((subscription: ISubscription) => {
        if (filterText === '') {
            return true
        }
        const data = JSON.stringify(subscription).toLowerCase()
        const query = filterText.toLowerCase()

        return data && data.includes(query)
    });

    return (
        <>
            <DataTable
                className='OrderListView order-list-view'
                columns={columns}
                data={filteredSubscriptions}
                pagination
                paginationPerPage={persistentStore.pageLength}
                paginationRowsPerPageOptions={persistentStore.pageLengthOptions}
                onChangeRowsPerPage={(rowCount) => persistentStore.setPageLength(rowCount)}
                noHeader={true}
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                defaultSortField='created'
                defaultSortAsc={false}
            />

            <SubscriptionEditModal
                shouldShow={showModal}
                hide={() => setShowModal(false)}
                updateSubscription={handleUpdateSubscription}
                loading={loading}
                subscription={editedSubscription}
            />
        </>
    )
}

export default SubscriptionTable;
