import {Link} from 'react-router-dom'
import React from 'react'
import {Card} from "react-bootstrap";
import PlusIcon from 'react-feather/dist/icons/plus-circle'
import {useRouter} from "../../utils/router";
import {useTranslation} from 'react-i18next';
import './NewCustomerCard.scss'

export const NewCustomerCard = () => {
    const {t} = useTranslation()
    const router = useRouter()

    return (
        <Card
            className='CustomerCard mb-2 flex-grow-1 flex-lg-grow-0 mb-4 add-customer'
            onClick={() => router.push('/customers/add')}
        >
            <div className='card-body' id='new-customer-link'>
                <Link to="/customers/add" className='d-flex flex-row'>
                    <PlusIcon className="feather-50"/>
                    <p className='ml-3'>{t('Add new customer')}</p>
                </Link>
            </div>
        </Card>
    )
}
