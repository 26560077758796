import React from "react";
import UnitLine from "../Units/UnitLine";
import {ICard} from "../../interfaces/ICard";
import {IUnit} from "../../interfaces/IUnit";
import {ICustomer} from "../../interfaces/ICustomer";

type CardBodyProps = {
    card: ICard,
    customer?:ICustomer
}

const CardBody = (props: CardBodyProps) => {
    const units = props.card.units;

    if (! units || units.length === 0) {
        //remove empty unit line from card
        return <></>;
    }

    const orderedUnits = units.sort((a: IUnit, b: IUnit) => {
        if(! a.created || ! b.created){
            return -1
        }

        return b.created?.localeCompare(a.created|| '')
    })

    // turn all work-done into 'unit lines'; a line containing info of
    // the work that is done and the amount of units that is booked off
    // of the strippenkaart

    return (
        <div className="card-body" style={{padding: 0}}>
            {
                orderedUnits.map((unit, index) => (
                    <UnitLine
                        key={index}
                        description={unit.description}
                        unitId={unit.id}
                        unit={unit}
                        customer={props.customer}
                    />
                ))
            }
        </div>
    );
};

export default CardBody;

