import React, {useContext, useState} from 'react';
import CardList from "./CardList";
import UnitAdder from "../Units/UnitAdder";
import CardTable from "./CardTable";
import {observer} from 'mobx-react'
import {Button, Col, Row} from 'react-bootstrap'
import {useTranslation} from "react-i18next";
import {useRouter} from "../../utils/router";
import store from "../../stores/store";
import {toast} from "react-toastify";
import {cards} from "../../api/cards";
import SweetAlert from "react-bootstrap-sweetalert/dist";
import './CustomerSingle.scss'
import {useQuery, useQueryCache} from "react-query";
import {customers as customersApi} from '../../api/customers'
import CustomerStats from "./CustomerStats";
import {stripnamePlural} from "../../utils/stripname";
import CustomerMails from "./CustomerMails"
import CardAdd from "../Cards/CardAdd";
import {ICard} from "../../interfaces/ICard";
import CustomerHeader from "./CustomerHeader";
import CustomerAlerts from "./CustomerAlerts";
import UseTimeContext from "../../contexts/UseTimeContext";
import Recurrences from "../../components/Recurrences";
import CustomerNotes from "./CustomerNotes";
import MetaInfo from './MetaInfo';

const CustomerSingle = () => {
    const [showCardAdd, setShowCardAdd] = useState(false)
    const [showConfirm, setShowConfirm] = useState(false)

    const useTime: boolean = useContext(UseTimeContext)
    const {t} = useTranslation()
    const router = useRouter()
    const queryCache = useQueryCache()
    const customerPublicId: string = router.query.customerPublicId
    const customerQuery = useQuery(
        ['getCustomer', customerPublicId],
        () => customersApi.getByPublicId(customerPublicId)
    )

    const toggleShowAddCard = () => {
        // only toggle when this component is still mounted
        setShowCardAdd(!showCardAdd)
    };

    const removeCard = (card: ICard) => {
        cards.del(card.id)
            .then(() => queryCache.invalidateQueries('getCustomer'))
    };

    const deleteCustomer = (customerId: string) => {
        store.triggerEvent('customer_deleted')
        customersApi.del(customerId)
            .then((response) => {
                if (response.success) {
                    toast.success(t('Customer deleted'))
                    queryCache.invalidateQueries('getCustomers')

                    router.push('/customers')
                }
            })
    }

    if (customerQuery.isLoading) {
        return <div>{t('Loading')}</div>
    }

    return (
        <>
            <CustomerHeader
                customerQuery={customerQuery}
                setShowConfirm={setShowConfirm}
            />

            <CustomerStats customer={customerQuery}/>

            <CustomerAlerts customerQuery={customerQuery}/>

            <Col sm={12} className="CustomerSingle">
                <Row>
                    <Col sm={12} lg={12}>
                        <Button className="mt-2" variant={'link'} id='show-addcard' onClick={toggleShowAddCard}>
                            <div>
                                {showCardAdd ? "- " : "+ "}
                                {useTime ? 'Strippen' : stripnamePlural()}{t('card')} toevoegen
                            </div>
                        </Button>
                    </Col>
                </Row>

                <Row>
                    <Col sm={12} lg={7}>
                        <Col md={12} className="p-0">
                            {showCardAdd && (
                                <CardAdd
                                    toggleaddcard={toggleShowAddCard}
                                    customer={customerQuery.data}
                                    invalidateCache={() => {
                                        queryCache.invalidateQueries('getCustomer')
                                    }}
                                />
                            )}
                        </Col>
                        <Col md={12} className="p-0">
                            {(customerQuery.data?.strips_remaining > 0) && (
                                <UnitAdder
                                    customerQuery={customerQuery}
                                    className='col-sm-12 col-lg-10 col-xl-7'
                                    invalidateCache={() => {
                                        queryCache.invalidateQueries('getCustomer')
                                        queryCache.invalidateQueries(['mails', customerPublicId])
                                        queryCache.invalidateQueries(['getRecurrences', customerPublicId])
                                    }}
                                />
                            )}
                        </Col>
                        <div className="col-md-12 p-0">
                            <Recurrences
                                customerPublicId={customerPublicId}
                                className=''
                            />
                        </div>
                    </Col>

                    <Col sm={12} lg={5} className={'ml-auto'}>
                        <CustomerNotes 
                            notes={customerQuery.data?.notes || ''}
                            customer={customerQuery.data}
                        />
                        
                        <MetaInfo
                            customer={customerQuery.data}
                        />
                    </Col>
                </Row>

                <hr/>

                <CardList
                    cards={customerQuery.data?.cards || []}
                    title={t("Strippenkaarten")}
                    removeCard={removeCard}
                    loading={customerQuery.isLoading}
                    customer={customerQuery.data}
                />

                <CardTable customer={customerQuery.data}/>

                <CustomerMails
                    customer={customerQuery.data}
                    title={t('Emails sent to customer')}
                />
            </Col>

            {showConfirm && (
                <SweetAlert
                    warning
                    showCancel
                    allowEscape={true}
                    closeOnClickOutside={true}
                    confirmBtnText="Ja, verwijder de klant"
                    confirmBtnBsStyle="danger"
                    title="Weet je het zeker?"
                    onConfirm={() => deleteCustomer(customerQuery.data?.public_id)}
                    onCancel={() => setShowConfirm(false)}
                    focusCancelBtn
                >
                    Je kunt het verwijderen van de klant niet ongedaan maken!
                </SweetAlert>
            )}
        </>
    )
}

export default observer(CustomerSingle)
