import {action, decorate, observable} from 'mobx'
import autoStore from './autoStore'

class FeatureToggleStore {
    themeColors: boolean = process.env.NODE_ENV === 'development'
    chat: boolean = process.env.NODE_ENV === 'production'
    recurrence: boolean = process.env.NODE_ENV === 'development'

    setRecurrence(newValue: boolean){
        this.recurrence = newValue
    }

    /**
     * Change the value via an endpoint: /feature/:featureName/:featureValue (the featureValue can be either 0 or 1)
     * @param featureName
     * @param featureValue
     */
    set(featureName: string, featureValue: boolean){
        // only set it when the property already exists
        if(! this.hasOwnProperty(featureName)){
            console.error(`Trying to set non-existing property ${featureName}`)
            return false
        }

        switch(featureName){
            case 'themeColors':
                this.themeColors = featureValue
                break;
            case 'chat':
                this.chat = featureValue
                break;
            case 'recurrence':
                this.recurrence = featureValue
                break;
        }

        console.log(`This feature is successfully updated: ${featureName}`)
        return true;
    }

    constructor() {
        // store changes to the state in localStorage
        autoStore(this, 'featureToggleStore')
    }
}

decorate(FeatureToggleStore, {
    setRecurrence: action,

    themeColors: observable,
    chat: observable,
    recurrence: observable,
})

const featureToggleStore = new FeatureToggleStore()
export default featureToggleStore
