import axios from 'axios'
import errorChecker from './errorchecker'
import persistentStore from "../stores/persistentStore";
import {ICompany} from "../interfaces/ICompany";

const companyRequests = () => {

    const addCompany = async (company: ICompany) => {
        const path = `/companies/add/`
        const data = company
        const config = persistentStore.authHeader

        return axios.post(persistentStore.apiUrl + path, data, config)
            .then(
                (response) => {
                    if (response.request.status !== 400) {
                        return response
                    }

                    return JSON.parse(response.request.response)
                })
            .then(response => errorChecker(response)).then(response => {
                const data = response.data

                if (!data.data.company) {
                    console.log('The response did not contain the created company')
                    return response.data
                }
                return data.data
            })
    }

    const addCustomerCompanyToInvoice = async (company: ICompany, invoicePublicId: string) => {
        const path = `/companies/add-customer-company-to-invoice/${invoicePublicId}`
        const data = company
        const config = persistentStore.authHeader

        return axios.post(persistentStore.apiUrl + path, data, config)
            .then(
                (response) => {
                    if (response.request.status !== 400) {
                        return response
                    }

                    return JSON.parse(response.request.response)
                })
            .then(response => errorChecker(response)).then(response => {
                const data = response.data

                if (!data.data.company) {
                    console.log('The response did not contain the created company')
                    return response.data
                }
                return data.data
            })
    }

    const updateCompany = async (company: ICompany) => {
        const path = `/companies/${company.id}`
        const config = persistentStore.authHeader

        return axios.put(persistentStore.apiUrl + path, company, config)
            .then(response => errorChecker(response))
            .then(response => response.data)
    }

    const updateCustomerCompanyOnInvoice = async (company: ICompany, invoicePublicId: string) => {
        const path = `/companies/update-customer-company-on-invoice/${company.id}/${invoicePublicId}`
        const config = persistentStore.authHeader

        return axios.put(persistentStore.apiUrl + path, company, config)
            .then(response => errorChecker(response))
            .then(response => response.data)
    }

    const get = async (companyId: number) => {
        const path = `/companies/view/${companyId}`
        const config = persistentStore.authHeader

        return axios.get(persistentStore.apiUrl + path, config)
            .then(
                response => errorChecker(response)).then(response => {
                return response.data
            })
    }

    return {
        addCompany,
        addCustomerCompanyToInvoice,
        updateCompany,
        updateCustomerCompanyOnInvoice,
        get
    }
}

export const companies = companyRequests()
