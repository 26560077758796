import React, {useRef} from 'react';
import {Card} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {ICustomer} from '../../interfaces/ICustomer';
import {customers} from '../../api/customers';
import {toast} from 'react-toastify';
import {useQueryCache} from 'react-query';
import * as Sentry from '@sentry/browser';
import ContentEditable from "react-contenteditable";
import './CustomerNotes.scss'

type CustomerNotesProps = {
    notes: string,
    customer: ICustomer
}

const CustomerNotes = (props: CustomerNotesProps) => {
    const {customer, notes} = props
    const {t} = useTranslation()
    const queryCache = useQueryCache();
    const notesHtml = notes.split('\n').map((item: string, key: number) => {
        return item
    }).join('</p><p>')

    const updatedNotes = useRef(notesHtml)

    const handleUpdateNotes = (event: any) => {
        updatedNotes.current = event.target.value
    }

    const saveNotes = (event: any) => {
        const notes: string = updatedNotes.current
            .replaceAll('</p><p>', '\n')
            .replaceAll('<p>', '')
            .replaceAll('</p>', '')

        const updatedCustomer: ICustomer = Object.assign(
            {},
            customer,
            {notes: notes}
        )

        customers
            .update(updatedCustomer)
            .then(response => {
                if (response.success === true) {
                    toast.success(t('Notes updated'));
                    queryCache.invalidateQueries('getCustomer');
                    return;
                }

                const errors = validationErrors(response.data.errors);

                Object.entries(errors as { [key: string]: string })
                    .forEach(([field, message]) => {
                        switch (field) {
                            case 'notes':
                                toast.error(message);
                                break;
                            default:
                                console.log(`Error for unknown field: ${field}`);
                        }
                    });
            })
            .catch((error) => {
                console.log(`error caught when updating customer: `, error);
                Sentry.captureException(error);
            })
    }

    /**
     * Returns an array with the field as key and the message as value
     * @param errors
     */
    const validationErrors = (errors: any) => {
        const validationErrorsObject: { [key: string]: string } = {}

        for (const [field, errorObject] of Object.entries(errors)) {
            // const message = Object.entries(errorObject as [string, string])
            //     .map( ([fieldName, message]: [string, string]) => message);
            // validationErrors[field] = message[0];
            console.log(errorObject)
            validationErrorsObject[field] = 'test'
        }

        return validationErrorsObject;
    };

    return (
        <Card className={'mt-4 mr-2 customer-notes'}>
            <Card.Header style={{backgroundColor: "white"}}>
                <Card.Title className="mb-0">{t('Private Notes')}</Card.Title>
                <small>
                    {t('These are only visible to you, the customer won\'t see them')}.<br/>
                    {t('Click the note below to edit the text')}.
                </small>
            </Card.Header>
            <Card.Body>
                <ContentEditable
                    className='notes'
                    html={updatedNotes.current}
                    disabled={false}
                    onChange={(e: any) => handleUpdateNotes(e)}
                    onBlur={(e: any) => saveNotes(e)}
                />
            </Card.Body>
        </Card>
    )
};

export default CustomerNotes;
