import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {trials} from "../../api/trials";
import {useRouter} from "../../utils/router";
import persistentStore from "../../stores/persistentStore";
import {browserName, browserVersion, deviceType, getUA, osName, osVersion} from 'react-device-detect'
import {info as infoApi} from "../../api/info";
import {useQuery} from "react-query";

const TrialPage = () => {
    const {t} = useTranslation()
    const router = useRouter()
    const infoQuery = useQuery('info', infoApi.ip)

    useEffect(() => {
        // TODO filter out webcrawlers, spiders, Google, etc.

        // remove all local cache and stuff
        persistentStore.emptyStore()

        if (infoQuery.data) {
            const browserInfo: BrowserInfo = {
                user_agent: getUA,
                browser: browserName,
                browser_version: browserVersion,
                os: osName,
                os_version: osVersion,
                // @ts-ignore
                timezone: infoQuery.data.time_zone.name,
                // @ts-ignore
                local_time: infoQuery.data.time_zone.current_time,
                device: deviceType,
                // @ts-ignore
                ip: infoQuery.data.ip,
                // @ts-ignore
                threat: infoQuery.data.threat,
                // @ts-ignore
                city: infoQuery.data.city,
                // @ts-ignore
                country: infoQuery.data.country_name,
            }

            trials.createTrialAccount(browserInfo)
                .then(() => {
                    window.location.href = window.location.origin + '/customers'
                })
        }
    }, [infoQuery.data, router])

    return (
        <div>
            {t('Please wait for your account to be created')}
        </div>
    );
}

export default TrialPage;

