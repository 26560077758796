import axios from "axios";
import errorChecker from "./errorchecker";
import persistentStore from "../stores/persistentStore";


const statisticRequests = () => {
    const getUserStats = async () => {
        const path  = `/stats/users`;

        return axios
            .get(persistentStore.apiUrl+path, persistentStore.authHeader)
            .then(response => errorChecker(response))
            .then(response => {
                return response.data.data
            })
        ;
    }

    const getMonthlyStats = async () => {
        const year = new Date().getFullYear();
        const path  = `/stats/monthly/${year}`;

        return axios
            .get(persistentStore.apiUrl+path, persistentStore.authHeader)
            .then(response => errorChecker(response))
            .then(response => {
                return response.data.data
            })
            ;
    }

    const getCustomerStats = async () => {
        const path  = `/stats/customers`;

        return axios
            .get(persistentStore.apiUrl+path, persistentStore.authHeader)
            .then(response => errorChecker(response))
            .then(response => {
                return response.data.data
            })
            ;
    }

    const getSystemStats = async () => {
        const path  = `/stats/system`;

        return axios
            .get(persistentStore.apiUrl+path, persistentStore.authHeader)
            .then(response => errorChecker(response))
            .then(response => {
                return response.data.data.system_statistics
            })
            ;
    }

    const getTrialStats = async () => {
        const path  = `/stats/new-trials`;

        return axios
            .get(persistentStore.apiUrl+path, persistentStore.authHeader)
            .then(response => errorChecker(response))
            .then(response => {
                return response.data.data.trial_stats
            })
            ;
    }

    const revenue = async () => {
        const path  = `/stats/revenue`;

        return axios
            .get(persistentStore.apiUrl+path, persistentStore.authHeader)
            .then(response => errorChecker(response))
            .then(response => {
                // contains keys: costs, revenue, result
                return response.data.data
            })
            ;
    }

    const getSalesStats = async () => {
        const path  = `/stats/sales`;

        return axios
            .get(persistentStore.apiUrl+path, persistentStore.authHeader)
            .then(response => errorChecker(response))
            .then(response => {
                return response.data.data.sales_stats
            })
            ;
    }

    const getPageviewStats = async () => {
        const path  = `/stats/pageviews`;

        return axios
            .get(persistentStore.apiUrl+path, persistentStore.authHeader)
            .then(response => errorChecker(response))
            .then(response => {
                return response.data.data.pageview_stats
            })
        ;
    }

    return {
        getUserStats,
        getCustomerStats,
        getMonthlyStats,
        getPageviewStats,
        getTrialStats,
        getSalesStats,
        revenue,
        getSystemStats,
    }
}

export const stats = statisticRequests()
