import React from 'react';
import DataTable from "react-data-table-component";
import Spinner from 'react-feather/dist/icons/settings';
import {Col} from "react-bootstrap";
import persistentStore from "../../stores/persistentStore";
import FilterComponent from "../../components/FilterComponent";
import {IUser} from "../../interfaces/IUser";
import store from "../../stores/store";
import {useRouter} from "../../utils/router";
import moment from "moment";

const TrialUserTable = (props: any) => {
    const {loading }:{loading: boolean} = props
    const [filterText, setFilterText] = React.useState<string>('');
    const router = useRouter()

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                // setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return <FilterComponent onFilter={(e: any) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
    }, [filterText]);

    const filteredUsers: IUser[] = props.userStats.data?.trialUsers.filter((trialUser: IUser) => {
        if(filterText === ''){
            return true
        }
        const data = JSON.stringify(trialUser).toLowerCase()
        const query = filterText.toLowerCase()

        return data && data.includes(query)
    });

    const columns = [
        {
            name: 'Name',
            selector: 'name',
            sortable: true,
        },
        {
            name: 'Email',
            selector: 'email',
            sortable: true,
            grow: 2
        },
        {
            name: 'Days left',
            selector: 'days_left',
            sortable: true,
            right: true,
            maxWidth: '120px'
        },
        {
            name: '# customers',
            selector: 'customers_count',
            sortable: true,
            right: true,
            maxWidth: '120px'
        },
        {
            name: 'Requests',
            sortable: true,
            right: true,
            selector: 'requests_this_month',
        },
        {
            name: 'Last Seen',
            selector: 'last_seen',
            sortable: true,
            cell: (user: IUser) => {
                return user.last_seen
                    ? `${moment(user.last_seen).fromNow()}`
                    : ''
            },
        },
    ]

    if(loading){
        return (
            <Col md={12}>
                <Spinner className="spinner"/>
            </Col>
        )
    }

    const onRowClicked = (user: IUser) => {
        store.setUserView(user)
        router.push(`/users/${user.id}`)
    }

    return (
        <DataTable
            columns={columns}
            data={filteredUsers}
            defaultSortField='days_left'
            defaultSortAsc={true}
            pagination={(filteredUsers||[]).length > 15}
            paginationPerPage={persistentStore.pageLength}
            paginationRowsPerPageOptions={persistentStore.pageLengthOptions}
            onChangeRowsPerPage={(rowCount) => persistentStore.setPageLength(rowCount)}
            noHeader
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            persistTableHead
            progressPending={loading}
            highlightOnHover={true}
            pointerOnHover={true}
            onRowClicked={(event) => onRowClicked(event)}
        />
    );
}

export default TrialUserTable;
