import React from "react";
import {Col, Card, Button} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {Check as CheckIcon} from "react-feather";
import {ICardType} from "../../interfaces/ICardType";
import {stripnamePlural} from "../../utils/stripname";
import store from "../../stores/store";
import uiStore from "../../stores/uiStore";
import {observer} from "mobx-react";
import {ISellerSettings} from "../../interfaces/ISellerSettings";

type CardListProps = {
    placeOrder: (cardType: ICardType)=>void,
    card: ICardType,
    settings: ISellerSettings
}

function CardList(props: CardListProps) {
    const {t} = useTranslation()
    const {card, settings, placeOrder} = props

    const getDescriptionText = () => {
        return {__html: card.description.replace(/\n/g, '<br />')}
    }

    const showIncVat: boolean = settings.showIncVat

    const vatPercentage: number = parseFloat(String(settings.vatPercentage))
    const price: number = showIncVat
    ? card.price_in_cents
    : (card.price_in_cents*100) / ((1+vatPercentage)*100)

    const displayPrice = (price/100)


    return (
        <Col
            md={12}
            lg={4}
            className="py-3 d-flex align-items-stretch"
        >
            <Card className="w-100 cardtype">
                <Card.Body className="d-flex flex-column p-4">
                    <h5 className="mb-4">{card.name}</h5>
                    <h1 className="font-weight-bold mb-3 border-bottom">
                        {Number.isInteger(displayPrice) ? (price/100).toLocaleString(store.locale, uiStore.moneyNoDecimals) : (price/100).toLocaleString(store.locale, uiStore.moneyOptions)}
                        {/* ((price/100)).toLocaleString(store.locale, uiStore.moneyOptions) */}
                        &nbsp;
                        <small style={{fontSize: '0.8rem'}}>
                            {
                                vatPercentage > 0
                                    ? showIncVat
                                        ? t('inc. VAT')
                                        : t('ex. VAT')
                                    : t('no VAT needed')
                            }
                        </small>
                    </h1>

                    <ul className="list-unstyled">
                        {card.default_quantity && (
                            <li className="py-1">
                                <CheckIcon className='text-success mr-3'/> {card.default_quantity} {stripnamePlural()}
                            </li>
                        )}

                        {card.description && (
                            <li>
                                <Card.Text className="mb-4" dangerouslySetInnerHTML={getDescriptionText()}/>
                            </li>
                        )}
                    </ul>

                    <Button
                        variant="primary"
                        size="lg"
                        block={true}
                        className="mt-auto border-top"
                        onClick={() => placeOrder(card)}
                    >
                        {t('Choose')}
                    </Button>
                </Card.Body>
            </Card>
        </Col>
    );
}

export default observer(CardList);
