import axios from 'axios'
import errorChecker from './errorchecker'
import persistentStore from "../stores/persistentStore";

const paymentRequests = () => {

    const get = async (publicId) => {
        const path = `/payments/${publicId}`
        const config = persistentStore.authHeader

        return axios.get(persistentStore.apiUrl + path, config)
            .then(response => errorChecker(response))
            .then(response => {
                const data = response.data

                if(data.success){
                    // return data so the requesting party can check for 'success' as well
                    return data
                }

                console.error(response)
                throw new Error('Could not get payment')
            })
    }

    const place = async (payment) => {
        const path = `/buy`
        const config = persistentStore.authHeader

        return axios.post(persistentStore.apiUrl + path, payment, config)
            .then(response => errorChecker(response))
            .then(response => {
                const data = response.data

                if(data.success){
                    // return data so the requesting party can check for 'success' as well
                    return data
                }
                return data
                // console.error(response)
                // throw new Error('Could not place payment')
            })
    }

    return {
        place,
        get,
    }
}

export const payments = paymentRequests()
