import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {getSlug} from '../utils/helpers';
import {
    BarChart,
    Sliders,
    Users,
    BarChart2,
    HelpCircle,
    RotateCw,
    UserPlus,
    MessageSquare,
    Archive
} from 'react-feather';
import {observer} from 'mobx-react'
import store from '../stores/store'
import {useTranslation} from "react-i18next";
import Button from "react-bootstrap/cjs/Button";
import persistentStore from "../stores/persistentStore";
import './Sidebar.scss'
import MakerLink from "../components/MakerLink";
import featureToggleStore from "../stores/featureToggleStore";

const Sidebar = ({className}: { className?: string }) => {
    const [menu, setMenu] = useState<JSX.Element[]>([])
    const {t} = useTranslation()

    const populateMenu = (reset: boolean = false) => {

        let valuesToRemove: string[] = [];

        if (persistentStore.isNotAdmin) {
            valuesToRemove = ['users'];
        }

        const newMenu: JSX.Element[] = menuItems
            .filter(item => !valuesToRemove.includes(item.id))
            .map(menuItem => {
                let menuClassName = 'nav-link';
                if (isActive(menuItem.href) && ! reset) {
                    menuClassName += ' active';
                }
                return (
                    <li className="nav-item" key={menuItem.name} onClick={(e) => populateMenu()}>
                        <Link
                            to={menuItem.href}
                            className={menuClassName}
                        >
                            {icons[menuItem.icon]}
                            {menuItem.name}
                        </Link>
                    </li>
                );
            });

        setMenu(newMenu)
    }

    useEffect(() => {
        populateMenu()
        // eslint-disable-next-line
    }, [])

    const icons: { [key: string]: JSX.Element } = {
        customers: <Users className="feather feather-users"/>,
        integrations: <BarChart2 className="feather feather-bar-chart-2"/>,
        reports: <BarChart2 className="feather"/>,
        dashboard: <BarChart className="feather"/>,
        configuration: <Sliders className="feather"/>,
        upgradetopro: <RotateCw className="feather"/>,
        class: <UserPlus className="feather"/>
    };

    const menuItems = [
        {id: 'dashboard', name: 'Dashboard', href: '/', icon: 'dashboard', enabled: true},
        {id: 'customers', name: t('Customers'), href: '/customers', icon: 'customers', enabled: true},
        {id: 'users', name: t('Users'), href: '/users', icon: 'customers', enabled: true},
        // {name: 'Rapporten', href: '/reports', icon: 'reports', enabled: false},
        {id: 'class', name: t('Class'), href: '/class', icon: 'class', enabled: false},
        {id: 'configuration', name: t('Configuration'), href: '/config', icon: 'configuration', enabled: false},
    ];

    const isActive = (urlToCheck: string) => {
        // get the part after the domainname (including leading slash)
        const slug = getSlug();

        // when we want to check whether we're on the homepage, then the WHOLE
        // slug must be exactly like slug (and not starting with, otherwise you
        // wil always get true
        if (urlToCheck === '/' && slug !== '/') {
            return false;
        }

        // note: returns always true when we're on root (/)
        const relevantPartOfSlug = slug.substr(0, urlToCheck.length);
        return relevantPartOfSlug === urlToCheck;
    }

    // don't show the sidebar to non-loggedin users
    if (!persistentStore.loggedIn) {
        return <nav className="col-md-2 d-none d-md-block bg-light sidebar"/>
    }

    const startChat = () => {
        console.log('firing up the chat engine')
        // @ts-ignore
        if (window.Tawk_API) {
            // @ts-ignore
            window.Tawk_API.popup()
        }
    }

    return (
        <nav className={`d-none d-md-block bg-light sidebar ${className || ''}`}>
            <div className="sidebar-sticky">
                <ul className="nav flex-column">
                    {menu}
                </ul>

                <hr/>

                <ul className="nav flex-column">
                    <li className="nav-item">
                        <Link
                            to='/sales/cards'
                            className={`nav-link ${isActive('/sales/cards') ? 'active' : ''}`}
                            onClick={(e) => populateMenu(true)}
                        >
                            <Archive className="feather feather-archive"/>
                            {t('Cards')}
                        </Link>
                    </li>
                </ul>

                <hr/>

                {persistentStore.user?.type === 'trial' && (
                    <Button
                        size='sm'
                        variant='outline-primary'
                        className='ml-2 right'
                        onClick={() => store.setShowUpgrade(true)}
                        id='upgrade-trial'
                    >
                        {t('Upgrade to Pro')}
                    </Button>
                )}

                <ul className="nav flex-column">
                    <li>
                        <a
                            href='https://docs.strippenkaart.app'
                            className='nav-link'
                            target='_blank'
                            rel="noopener noreferrer"
                            id='help-link'
                        >
                            <HelpCircle className="feather feather-users"/>
                            Help
                        </a>
                    </li>
                    {featureToggleStore.chat && (
                        <li>
                            <Link
                                to={'#'}
                                className='nav-link'
                                id='help-link'
                                onClick={startChat}
                            >
                                <MessageSquare className="feather"/>
                                Chat
                            </Link>
                        </li>
                    )}
                </ul>
            </div>
            <MakerLink/>
        </nav>
    );
}

export default observer(Sidebar)
