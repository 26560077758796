import React from "react";
import Section from "../../components/Section";
import Container from "react-bootstrap/Container";
import SectionHeader from "../../components/SectionHeader";
import Pricing from "../Users/Pricing";
import {useTranslation} from "react-i18next";

function PricingSection(props) {
    const {t} = useTranslation()

    return (
        <Section
            bg={props.bg}
            textColor={props.textColor}
            size={props.size}
            bgImage={props.bgImage}
            bgImageOpacity={props.bgImageOpacity}
        >
            <Container>
                <SectionHeader
                    title={props.title}
                    subtitle={props.subtitle}
                    size={2}
                    spaced={true}
                    className="text-center"
                />
                <Pricing
                    buttonText={t('Choose')}
                    items={[
                        {
                            id: "monthly",
                            name: t('Monthly'),
                            price: "12,95",
                            period: "month",
                            perks: [
                                t("Unlimited customers"),
                                t("Unlimited cards"),
                            ],
                        },
                        // {
                        //     id: "free",
                        //     name: t('🚨 Temporary'),
                        //     price: "0,00",
                        //     period: "month",
                        //     perks: [
                        //         t("🚨 6 months free"),
                        //         t("🚨 Option available until payment works"),
                        //         t("Unlimited customers"),
                        //         t("Unlimited cards"),
                        //     ],
                        // },
                        {
                            id: "yearly",
                            name: t('Yearly'),
                            price: "129,95",
                            period: "year",
                            perks: [
                                t("Unlimited customers"),
                                t("Unlimited cards"),
                                t("3 months for free!"),
                                t("Let customers buy their cards online (coming soon)"),
                            ],
                        },
                    ]}
                />
            </Container>
        </Section>
    );
}

export default PricingSection;
