import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import {observer} from 'mobx-react'
import {useLoading} from "../../utils/helpers";
import {useTranslation} from "react-i18next";
import {users} from "../../api/users";
import Alert from "react-bootstrap/Alert";
import FormGroup from "react-bootstrap/FormGroup";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Header from "../../parts/Header";

const RequestPasswordReset = () => {
    const [email, setEmail] = useState('')
    const [error, setError] = useState<string|undefined>('')
    const [success, setSuccess] = useState('')
    const [loading, startLoading, stopLoading] = useLoading()
    const {t} = useTranslation()

    const handleError = (response: any) => {
        if(response.data.message){
            console.error(response.data.message)
        }

        if (response.status === 404) {
            setError(t('There is no account with this email address'))
            return
        }

        if(response.status >= 500){
            console.error(response.data)
            setError(t('Er is een fout opgetreden'))
            return
        }
        setSuccess(t('I received your request. An email has been sent with instructions on how to change your password'))
    }

    const handlePasswordChange = (event: any) => {
        event.preventDefault()
        startLoading()
        setError(undefined)

        users.requestResetPassword({email: email})
            .then((response) => {
                if (response.success !== true) {
                    return handleError(response.response)
                }

                setSuccess(t('Success! I have sent you an email with a password reset link'))
            })
            .finally(() => {
                stopLoading()
            })
    }

    return (
        <>
            <Header />
            <main role="main" className="offset-2 col-md-8 pt-3 px-4">
                <form className='form-signin col-md-8' onSubmit={handlePasswordChange}>

                    <h1 className="h3 mb-3 font-weight-normal">{t('Request a password reset')}</h1>

                    {error && <Alert variant='danger'>{error}</Alert>}
                    {success && <Alert variant={"success"}>{success}</Alert>}

                    <FormGroup>
                        <label htmlFor="inputEmail" className="sr-only">{t('Your email address')}</label>
                        <input type="email" id="inputEmail" className="form-control"
                               placeholder={t('Your email address')}
                               name="email" value={email} onChange={e => setEmail(e.target.value)} disabled={loading || success.length>0}
                               required
                        />
                    </FormGroup>

                    <Button variant='primary' className="btn-block" type="submit" disabled={loading|| success.length>0}>
                        {loading && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
                        {! loading && t('Request reset')}
                    </Button>

                    <div className='mt-3'>
                        {t('Remembered your password again?')}
                        <Link to="/login" className="btn btn-link">{t('Login')}</Link>
                    </div>
                </form>
            </main>
        </>
    )
}

export default observer(RequestPasswordReset)

