import React, {useContext} from 'react'
import SingleStrippenkaart from './SingleStrippenkaart'
import {observer} from 'mobx-react'
import './CardList.scss'
import {Alert} from "react-bootstrap";
import {ICard} from "../../interfaces/ICard";
import {ICustomer} from "../../interfaces/ICustomer";
import UseTimeContext from "../../contexts/UseTimeContext";

type CardListProps = {
    cards: ICard[],
    loading: boolean,
    error?: string,
    className?: string,
    title: string,
    removeCard?: (card: ICard)=>void,
    customer?:ICustomer
}

const CardList = (props: CardListProps) => {
    const useTime = useContext(UseTimeContext)

    const orderByTimeLeft = (cardA: ICard, cardB: ICard) => {
        return (cardA.time_remaining > cardB.time_remaining)
            ? -1
            : ((cardB.time_remaining > cardA.time_remaining)
                    ? 1
                    : 0
            )
    }

    const orderByStripsLeft = (cardA: ICard, cardB: ICard) => {
        return (cardA.strips_remaining > cardB.strips_remaining)
            ? -1
            : ((cardB.strips_remaining > cardA.strips_remaining)
                    ? 1
                    : 0
            )
    }

    const orderByPurchaseDate = (cardA: ICard, cardB: ICard) => {
        return (cardA.created > cardB.created)
            ? -1
            : ((cardB.created > cardA.created)
                ? 1
                : 0
            )
    }

    /**
     * The strippenkaarten need to be ordered this way:
     * 1. cards which are active (no duedate or in future) and have strips available: ordered by duedate is soon (needs to be used first)
     * 2. cards which are active and have no duedate (strippen are valid indefinately)
     * 3. cards which are not active or have no strips left
     */
    const orderListOfCardsOnDueDate = (cards: ICard[]) => {
        const sortMethod = useTime ? orderByTimeLeft : orderByStripsLeft

        const activeCardsWithStripsLeft = cards
            .filter((card) => {
                const hasTimeOrStrips: boolean = ! card.is_full
                return hasTimeOrStrips && (card.duedate === null || ! card.is_expired)
            })
            .sort(sortMethod)

        const cardsExpiredOrFull = cards
            .filter((card) => {
                return ! activeCardsWithStripsLeft.includes(card)
            })
            .sort(orderByPurchaseDate)

        return activeCardsWithStripsLeft.concat(cardsExpiredOrFull)
    }

    const orderedCards = orderListOfCardsOnDueDate(props.cards)
    return (
        <div className='CardList'>
            {props.loading && <Alert variant='info'>Gegevens aan het laden....</Alert>}
            {props.error && <Alert variant='danger'>Error! {props.error}</Alert>}

            {orderedCards.map((card: ICard, index: number) => (
                <div
                    className={`card strippenkaart ${card.is_full ? 'collapsed' : 'expanded'} ${props.className ? props.className : ''}`}
                    key={index}
                >
                    <a href={`#card-card.id`} className="strippenkaart-anchor">{card.id}</a>
                    <SingleStrippenkaart card={card} customer={props.customer} />
                </div>
            ))}
        </div>
    )
}

export default observer(CardList)
