import Spinner from 'react-feather/dist/icons/settings';
import React, {useEffect, useState} from 'react'
import {Card} from "react-bootstrap";
import {Line} from 'react-chartjs-2';
import {IUserStatistic} from "../../interfaces/IUserStatistics";
import {IChartDataset} from "../../interfaces/IChartDataset";
import {IChartData} from "../../interfaces/IChartData";
import {TTimespan} from "../../interfaces/TTimespan";

type CustomerStripsOverTimeProps = {
    stats: IUserStatistic[],
    unit_stats: {[key: string]: {booked?: number, added?: number}}[],
    loading: boolean,
    timespan: TTimespan,
    title: string
}

export const CustomerStripsOverTime = (props: CustomerStripsOverTimeProps) => {
    const {unit_stats, loading, timespan, title} = props
    const [labels, setLabels] = useState<string[]>([])
    const [stripsAdded, setStripsAdded] = useState<number[]>([])
    const [stripsBooked2, setStripsBooked] = useState<number[]>([])
    const [stripsTotal, setStripsTotal] = useState<number[]>([])

    const colors = {
        pink: 'rgba(255,204,255,1)',
        pinkOpacity: 'rgba(255,204,255,0.4)',
        blue: 'rgba(102,153,255,1)',
        blueOpacity: 'rgba(102,153,255,0.4)',
        green: 'rgba(102,204,153,1)',
        greenOpacity: 'rgba(102,204,153,0.4)',
    }

    useEffect(() => {
        getStatsByTimespan(unit_stats, timespan)
    }, [timespan, unit_stats])

    /**
     * split out the data into 3 arrays:
     * - labels
     * - strips_added
     * - strips_booked
     *
     * The length of the arrays should all be the same
     * @param unit_stats
     * @param timespan
     */
    const getStatsByTimespan = (unit_stats: {[index: string]: {added?: number, booked?: number}}[], timespan: TTimespan): void => {
        if(! unit_stats){
            // return {strips_added: {}, strips_booked: {}, strips_available: {}}
            return
        }

        const labels = Object.keys(unit_stats)
        const unitsAddedAndBooked = Object.values(unit_stats)

        const unitsAdded = unitsAddedAndBooked.map((value: {added?: number, booked?: number}) => {
            return value.added ? value.added : 0;
        }, [])
        const unitsBooked = unitsAddedAndBooked.map((value: {added?: number, booked?: number}) => {
            return value.booked ? value.booked : 0;
        }, [])

        const unitsTotal = labels.reduce((carry: number[], label: string, index: number) => {
            const indexBefore: number = (index-1) < 0 ? 0 : index-1
            const valueBefore: number = index === 0 ? 0 : carry[indexBefore]
            const unitBooked = (unitsBooked[index] || 0)
            const unitAdded = (unitsAdded[index] || 0)

            carry[index] = valueBefore + unitAdded - unitBooked
            return carry
        }, [])

        setStripsAdded(unitsAdded)
        setStripsBooked(unitsBooked)
        setStripsTotal(unitsTotal)
        setLabels(labels)
    }

    const defaultChartDataSet: IChartDataset = {
        label: 'change me',
        backgroundColor: 'rgba(0,123,255,0.4)',
        borderCapStyle: 'butt',
        borderColor: 'rgba(0,123,255,1)',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        data: [],
        fill: true,
        lineTension: 0.1,
        pointBackgroundColor: '#fff',
        pointBorderColor: 'rgba(0,123,255,1)',
        pointBorderWidth: 1,
        pointHitRadius: 10,
        pointHoverBackgroundColor: 'rgba(0,123,255,1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointHoverRadius: 5,
        pointRadius: 1,
    };

    const stripsAvailable: IChartDataset = Object.assign({}, defaultChartDataSet, {
        label: 'beschikbaar',
        backgroundColor: colors.pinkOpacity,
        borderColor: colors.pink,
        // data: Object.values(selectedStats?.strips_available || {}),
        data: stripsTotal
    })

    const stripsBooked: IChartDataset = Object.assign({}, defaultChartDataSet, {
        label: 'afgeboekt',
        backgroundColor: colors.blueOpacity,
        borderColor: colors.blue,
        // data: Object.values(selectedStats?.strips_booked || {}),
        data: stripsBooked2
    })

    const stripsBought: IChartDataset = Object.assign({}, defaultChartDataSet, {
        label: 'verkocht',
        backgroundColor: colors.greenOpacity,
        borderColor: colors.green,
        // data: Object.values(selectedStats?.strips_added || {}),
        data: stripsAdded
    })

    const chartData: IChartData = {
        // should be: dec, jan, feb, ...
        labels: labels,
        datasets: [stripsAvailable, stripsBooked, stripsBought]
    }

    const options = {
        legend: {
            display: true
        },
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true
                }
            }]
        }
    }

    return (
        <Card>
            <Card.Header as="h5">{title}</Card.Header>
            <Card.Body>
                <Line data={chartData} options={options}/>
                {loading && <h1 className="text-center"><Spinner className="spinner"/></h1>}
            </Card.Body>
        </Card>
    )
}
