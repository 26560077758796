import React, {useEffect, useState} from 'react';
import {clearCacheAsync} from "../../utils/clearCache";
import {useRouter} from "../../utils/router";

const ClearCache = () => {
    const [status, setStatus] = useState('loading')
    const router = useRouter()

    useEffect(() => {
        setStatus('cache legen')

        // clear the cache
        clearCacheAsync()
            .then(() => setStatus('cache geleegd'))

    }, [])

    useEffect(() => {
        console.log(status)

        if(status === 'cache geleegd'){
            clearCacheAsync()
                .then(() => setStatus('localStorage geleegd'))
                .then(() => router.push('/login'))
        }
        // eslint-disable-next-line
    }, [status])

    return (
        <div>
            <div>Status: {status}</div>
        </div>
    )
}

export default ClearCache;
