import React, {useEffect, useState} from 'react';
import OrderSection from "./OrderSection";
import {observer} from "mobx-react";
import {cardtypes} from "../../api/cardtypes";
import {useRouter} from "../../utils/router";
import {useLoading} from "../../utils/helpers";
import {useQuery} from "react-query";
import {IUser} from "../../interfaces/IUser";
import {users} from "../../api/users";
import {ISellerSettings} from "../../interfaces/ISellerSettings";
import PageLoader from "../../components/PageLoader";
import persistentStore from "../../stores/persistentStore";
import HiddenSalesPage from "./HiddenSalesPage";

function OrderPage() {
    const [loading, startLoading, stopLoading] = useLoading()
    const [availableCardTypes, setAvailableCardTypes] = useState([])
    const [settings, setSettings] = useState<ISellerSettings | undefined>()
    const [salespageTitle, setSalespageTitle] = useState<string>()
    const [salespageDescription, setSalespageDescription] = useState<string>()
    const [seller, setSeller] = useState<IUser | undefined>()
    const router = useRouter()
    const saleSlug: string = router.query.saleSlug
    const cardtypeSlug: string = router.query.cardtypeSlug
    const cardtypesQuery = useQuery(
        'cardtypes',
        () => {
            return cardtypeSlug
                ? cardtypes.getFromSlug(cardtypeSlug)
                : cardtypes.getFromSaleSlug(saleSlug)
        },
    )

    const sellerSettingsQuery = useQuery(
        'seller',
        () => {
            if (seller !== undefined) {
                return users.getSellerSettings(seller.id)
            }
        },
        {enabled: seller !== undefined}
    )

    useEffect(() => {
        if (cardtypesQuery.data) {
            setAvailableCardTypes(cardtypesQuery.data.card_types)
            setSeller(cardtypesQuery.data.seller)
        }
    }, [cardtypesQuery.data])

    useEffect(() => {
        if (sellerSettingsQuery.data) {
            setSalespageDescription(sellerSettingsQuery.data.salespageDescription)
            setSalespageTitle(sellerSettingsQuery.data.salespageTitle)
            setSettings(sellerSettingsQuery.data)
        }
    }, [sellerSettingsQuery.data])

    if (settings === undefined) {
        return <PageLoader/>
    }

    // hide the salespage when the global salespage is requested and the user disabled that
    if (persistentStore.user?.settings.hideSalespage && cardtypeSlug === undefined) {
        return <HiddenSalesPage />
    }

    return (
        <OrderSection
            bg="white"
            textColor="dark"
            size="md"
            bgImage=""
            seller={seller}
            loading={cardtypesQuery.isLoading || loading}
            startLoading={startLoading}
            stopLoading={stopLoading}
            cards={availableCardTypes}
            bgImageOpacity={1}
            title={salespageTitle || ''}
            subtitle={salespageDescription || ''}
            settings={settings}
        />
    );
}

export default observer(OrderPage)
