import Spinner from 'react-feather/dist/icons/settings';
import React, {useEffect, useState} from 'react'
import {Card} from "react-bootstrap";
import {Bar} from 'react-chartjs-2';
import {IChartDataset} from "../../interfaces/IChartDataset";
import {IChartData} from "../../interfaces/IChartData";
import {QueryResult, useQuery} from "react-query";
import {stats} from "../../api/stats";
import {ISalesStats} from "../../interfaces/ISalesStats";
import {IPlanCollection} from "../../interfaces/IPlanCollection";

type Props = {
    title: string,
    time: "monthly" | "daily"
    monthTarget: number,
    dayTarget: number
}

export const GraphSales = (props: Props) => {
    const {title, monthTarget, dayTarget, time} = props
    const [labels, setLabels] = useState<string[]>([])
    const [monthPlanData, setMonthPlanData] = useState<number[]>([])
    const [yearPlanData, setYearPlanData] = useState<number[]>([])
    const salesQuery: QueryResult<ISalesStats> = useQuery('salesStats' , () => stats.getSalesStats())

    const colors = {
        pink: 'rgba(255,204,255,1)',
        pinkOpacity: 'rgba(255,204,255,0.4)',
        blue: 'rgba(102,153,255,1)',
        blueOpacity: 'rgba(102,153,255,0.4)',
        green: 'rgba(102,204,153,1)',
        greenOpacity: 'rgba(102,204,153,0.4)',
    }

    useEffect(() => {
        if(! salesQuery.data || salesQuery.data.year_plans.monthly.length < 1 || salesQuery.data.month_plans.daily.length < 1 ){
            return
        }

        const months: IPlanCollection = salesQuery.data.month_plans
        const years : IPlanCollection = salesQuery.data.year_plans

        const timeframedMonths: {[key: string]: number} = time === 'daily' ? months.daily : months.monthly
        const timeframedYears : {[key: string]: number} = time === 'daily' ? years.daily : years.monthly

        setLabels(Object.keys(timeframedMonths))
        setMonthPlanData(Object.values(timeframedMonths))
        setYearPlanData(Object.values(timeframedYears))
    }, [salesQuery.data, time])

    const defaultChartDataSet: IChartDataset = {
        label: 'change me',
        backgroundColor: 'rgba(0,123,255,0.4)',
        borderCapStyle: 'butt',
        borderColor: 'rgba(0,123,255,1)',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        data: [],
        fill: true,
        lineTension: 0.1,
        pointBackgroundColor: '#fff',
        pointBorderColor: 'rgba(0,123,255,1)',
        pointBorderWidth: 1,
        pointHitRadius: 10,
        pointHoverBackgroundColor: 'rgba(0,123,255,1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointHoverRadius: 5,
        pointRadius: 1,
    };

    const monthPlans: IChartDataset = Object.assign({}, defaultChartDataSet, {
        label: 'month plans',
        backgroundColor: colors.blueOpacity,
        borderColor: colors.blue,
        data: monthPlanData
    })

    const yearPlans: IChartDataset = Object.assign({}, defaultChartDataSet, {
        label: 'year plans',
        backgroundColor: colors.greenOpacity,
        borderColor: colors.green,
        data: yearPlanData
    })

    const chartData: IChartData = {
        labels: labels,
        datasets: [monthPlans, yearPlans]
    }

    const options = {
        legend: {
            display: true
        },
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true
                }
            }]
        },
        annotation: {
            annotations: [{
                type: 'line',
                mode: 'horizontal',
                scaleID: 'y-axis-0',
                value: time === 'daily' ? dayTarget : monthTarget,
                borderDash: [2, 2],
                borderColor: 'tomato',
                borderWidth: 1,
                label: {
                    backgroundColor: 'red',
                    content: 'paying user targets',
                    cornerRadius: 4,
                    enabled: true,
                    fontColor: '#fff',
                    fontFamily: 'sans-serif',
                    fontSize: 10,
                    fontStyle: 'bold',
                    position: 'end',
                    textAlign: 'center',
                    xPadding: 4,
                    yPadding: 4,
                }
            }],
            drawTime: "afterDraw" // (default)
        }
    }

    return (
        <Card>
            <Card.Header as="h5" style={{display: 'flex'}}>
                <div>
                    {title}
                </div>
            </Card.Header>
            <Card.Body>
                <Bar data={chartData} options={options}/>
                {salesQuery.isLoading && <h1 className="text-center"><Spinner className="spinner"/></h1>}
            </Card.Body>
        </Card>
    )
}
