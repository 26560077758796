import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import persistentStore from "../stores/persistentStore";

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    persistentStore.loggedIn
      ? <Component {...props} />
      : <Redirect to={{ pathname: '/login', state: { from: props.location } }}/>
  )}/>
)

export default PrivateRoute
