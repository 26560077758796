import React, {useContext} from 'react';
import {observer} from "mobx-react";
import {secondsToTime} from "../../utils/helpers";
import {useTranslation} from "react-i18next";
import {ICustomer} from "../../interfaces/ICustomer";
import {QueryResult} from "react-query";
import UseTimeContext from "../../contexts/UseTimeContext";

const CustomerStats = (props: any) => {
    const customer: QueryResult<ICustomer> = props.customer
    const useTime: boolean = useContext(UseTimeContext)

    const {t} = useTranslation()

    const bought: string = useTime
        ? secondsToTime(customer.data?.time_bought || 0)
        : String(customer.data?.strips_bought)

    const remaining: string = useTime
        ? secondsToTime(customer.data?.time_remaining || 0)
        : String(customer.data?.strips_remaining)

    const booked: string = useTime
        ? secondsToTime(customer.data?.time_booked || 0)
        : String(customer.data?.strips_booked)

    return (
        <div className="bg-white border-bottom mb-2">
            <div className="content content-boxed pb-2">
                <div className="row items-push text-center">
                    <div className="col-6 col-md-3 offset-md-2 d-none d-md-block">
                        <div className="font-size-sm font-w600 text-muted text-uppercase">{t('bought')}</div>
                        <div className="link-fx font-size-h3" id='bought'>
                            {bought}
                        </div>
                    </div>
                    <div className="col-6 col-md-3">
                        <div className="font-size-sm font-w600 text-muted text-uppercase">{t('remaining')}</div>
                        <div className="link-fx font-size-h3" id='remaining'>
                            {remaining}
                        </div>
                    </div>
                    <div className="col-6 col-md-3">
                        <div className="font-size-sm font-w600 text-muted text-uppercase">{t('booked')}</div>
                        <div className="link-fx font-size-h3" id='booked'>
                            {booked}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default observer(CustomerStats)
