import uiStore from "../stores/uiStore";
import DataTable from "react-data-table-component";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react";
import {ICustomerGroup} from "../interfaces/ICustomerGroup";
import ModalAddGroup from "./ModalAddGroup";
import Button from "react-bootstrap/Button";
import '../pages/Class/CustomerGroupsDataTable.scss'
import persistentStore from "../stores/persistentStore";
import {Alert, Card} from "react-bootstrap";
import {toast} from "react-toastify";
import {useMutation, useQueryCache} from "react-query";
import {customers as customersApi} from "../api/customers";
import {ChevronRight, ChevronDown, Delete as DeleteIcon, Users as UsersIcon} from "react-feather";

type CustomerGroupsProps = {
    addUsersInGroupToSelection: (group: ICustomerGroup) => void,
    removeUsersInGroupFromSelection: (group: ICustomerGroup) => void,
    customerGroups: ICustomerGroup[],
    groupError: string
    handleSubmitGroup: (event: any) => void
    loading: boolean,
    selectedCustomers: string[],
    setGroupName: (changedGroupName: string) => void,
    setShowModal: (changedShowModal: boolean) => void
    show: boolean,
    onGroupClicked?: (isClicked: boolean) => void
}

const CustomerGroups = (props: CustomerGroupsProps) => {
    const {
        addUsersInGroupToSelection, removeUsersInGroupFromSelection, customerGroups,
        groupError, handleSubmitGroup, loading, selectedCustomers, setGroupName,
        setShowModal, show, onGroupClicked
    } = props
    const {t} = useTranslation()
    const [selectedGroups, setSelectedGroups] = useState<number[]>([])
    const [error, setError] = useState('')
    const queryCache = useQueryCache()
    const [isExpanded, setIsExpanded] = useState(false)

    const [deleteGroupMutation, deleteInfo] = useMutation(
        (group: ICustomerGroup) => customersApi.deleteCustomerGroup(group.id),
        {
            onSettled: () => {
                queryCache.invalidateQueries('getCustomerGroups')
            }
        }
    )

    const toggleCard = () => {
        const toggledState = !isExpanded
        setIsExpanded(toggledState)
    }

    const deleteGroup = (group: ICustomerGroup) => {
        try {
            deleteGroupMutation(group)
            toast.success(t('Group deleted'))
        } catch {
            console.log(deleteInfo.error)
            console.log(deleteInfo.status)
            console.log(deleteInfo.data)
        }
    }

    // when the selected customers change, the groups might not be selected anymore
    // example: group 'all' is selected, but the user deselects one 1 customer. Then the 'all' group should not be
    //          selected anymore
    useEffect(() => {
        const groupsReallySelected: ICustomerGroup[] = (customerGroups || []).filter((group: ICustomerGroup) => {
            // a group is selected when all the customers in that group are selected
            const selectedCustomersInGroup = group.customers.filter((groupCustomerId: string) => {
                return selectedCustomers.includes(groupCustomerId)
            })

            // now the number of selected customers must equal the number of customers in the group
            return selectedCustomersInGroup.length === group.customers.length
        })

        // now set the selected groups
        const updatedSelectedGroups = groupsReallySelected.map((group: ICustomerGroup) => group.id)
        setSelectedGroups(updatedSelectedGroups)

    }, [selectedCustomers, customerGroups])

    const addGroupToSelection = (group: ICustomerGroup) => {
        const updatedSelectedGroups = selectedGroups.concat(group.id)
        setSelectedGroups(updatedSelectedGroups)
    }

    const removeGroupFromSelection = (group: ICustomerGroup) => {
        const updatedSelectedGroups = selectedGroups.filter((selectedGroupId: number) => selectedGroupId !== group.id)
        setSelectedGroups(updatedSelectedGroups)
    }

    const clickOnGroupRow = (group: ICustomerGroup) => {
        const groupIsSelected = selectedGroups.includes(group.id)

        // is selected? Then deselect
        if (groupIsSelected) {
            removeGroupFromSelection(group)
            removeUsersInGroupFromSelection(group)
            if (onGroupClicked) {
                onGroupClicked(false)
            }
            return
        }

        // not selected? Then select
        addGroupToSelection(group)
        if (onGroupClicked) {
            onGroupClicked(true)
        }
        addUsersInGroupToSelection(group)
    }

    const columns = [
        {
            name: t('Group Name'),
            sortable: false,
            grow: 3,
            cell: (customerGroup: ICustomerGroup) => customerGroup.class_name
        },
        {
            name: t('Customers'),
            sortable: true,
            right: true,
            maxWidth: '30px',
            cell: (customerGroup: ICustomerGroup) => {
                return <div><UsersIcon/> {`(${customerGroup.customers.length})`}</div>
            }
        },
        {
            name: t('Actions'),
            sortable: false,
            maxWidth: '30px',
            cell: (customerGroup: ICustomerGroup) => (
                <Button
                    variant='outline-secondary'
                    onClick={() => deleteGroup(customerGroup)}
                    className='delete-group'
                >
                    <DeleteIcon/>
                </Button>
            )
        }
    ]

    const resetError = () => {
        setError('')

        // ensure the errorPanel is visible
        jQuery('div.errorPanel > div').slideDown()
    }

    const conditionalRowStyles = [
        {
            when: (group: ICustomerGroup) => selectedGroups.includes(group.id),
            style: {
                backgroundColor: "#007bff1c",
            }
        }
    ]

    const addClassGroup = (event: any) => {
        event.preventDefault();

        if (selectedCustomers.length === 0) {
            setError('Selecteer minstens 1 klant uit de lijst hieronder')
            return
        }
        resetError()
        setShowModal(true);
    }

    return (
        <Card style={{width: '100%'}} id='groups'>
            {error && (
                <div className='errorPanel'>
                    <Alert variant='danger'>{error}</Alert>
                </div>
            )}

            <Card.Header onClick={toggleCard} className='group-header'>
                {isExpanded && <ChevronDown/>}
                {!isExpanded && <ChevronRight/>}
                {t('Groups')}
            </Card.Header>

            <Card.Body style={{display: isExpanded ? 'block' : 'none'}}>
                <p>{t('Select customers by clicking on them')}</p>
                <p>{t('Click on a group name to select all customers in that group')}</p>
                <div className='customer-groups-datatable d-flex'>
                    <DataTable
                        noHeader={true}
                        noTableHead={true}
                        className='customerGroups-data-table'
                        columns={columns}
                        pagination={(customerGroups || []).length > 15}
                        paginationPerPage={uiStore.paginationLength}
                        paginationRowsPerPageOptions={persistentStore.pageLengthOptions}
                        onRowClicked={(group) => clickOnGroupRow(group)}
                        data={customerGroups}
                        highlightOnHover={true}
                        pointerOnHover={true}
                        onChangeRowsPerPage={(rowCount) => uiStore.setPaginationLength(rowCount)}
                        noDataComponent={<div>{t('No groups')}</div>}
                        progressPending={loading}
                        progressComponent={<div><strong>...{t('Loading customer groups')}</strong></div>}
                        conditionalRowStyles={conditionalRowStyles}
                    />

                    {show && selectedCustomers.length > 0 && (
                        <ModalAddGroup
                            show={show}
                            hide={() => setShowModal(false)}
                            handleSubmitGroup={handleSubmitGroup}
                            setgroupName={setGroupName}
                            loading={loading}
                            groupError={groupError}
                        />
                    )}
                </div>

                <Button
                    id='create-group'
                    type="button"
                    className="btn btn-primary mt-3"
                    style={{width: '100%'}}
                    onClick={addClassGroup}
                    disabled={loading || selectedCustomers.length === 0}
                >
                    {selectedCustomers.length === 0 && t('No customers selected')}
                    {selectedCustomers.length > 0 && t('Create group from selected customers')}
                </Button>

            </Card.Body>
        </Card>
    )
}

export default observer(CustomerGroups)
