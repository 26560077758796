import React from 'react';
import {Switch, Route} from 'react-router-dom';
import CardEdit from './CardEdit';

const CustomerCardsPage = () => {

        return (
            <Switch>
                {/*<Route*/}
                {/*    path='/cards/add'*/}
                {/*    render={(props) => <CardAdd {...props} />}*/}
                {/*/>*/}
                <Route
                    path='/cards/edit/:id'
                    component={CardEdit}
                />
            </Switch>
        );
    }

export default CustomerCardsPage;
