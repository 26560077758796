import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {users} from "../api/users";

const RedirectMessage = () => {
    const [hide, setHide] = useState(false)
    const {t} = useTranslation()

    useEffect(() => {
        users.event('REDIRECTED_FROM_LP')
    }, [])

    if(hide){
        return <React.Fragment></React.Fragment>
    }

    return (
        <div
            className="alert alert-warning"
            role="alert"
            style={{textAlign: 'center', cursor: 'pointer'}}
            onClick={() => setHide(true)}
        >
            {t('We automatically redirected you from the homepage because you\'re logged in')}.
            <a href="https://strippenkaart.app/?from=members"> {t('Click here')} </a>&nbsp;
            {t('if you want to see the homepage')}
        </div>
    )
};

export default RedirectMessage;
