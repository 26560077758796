import React, {useState} from 'react';
import './RecurrentModal.scss'
import {Button, Modal} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {INewRecurrence, IRecurrence} from "../interfaces/IRecurrence";
import {IRecurringDay} from "../interfaces/IRecurringDay";

type RecurrentModalProps = {
    show: boolean,
    hide: () => void
    onChange: (recurrence: IRecurrence|INewRecurrence) => void
    recurrence: IRecurrence|INewRecurrence,
}

const RecurrentModal = (props: RecurrentModalProps) => {
    const {show, hide, onChange, recurrence} = props
    const {t} = useTranslation()

    const days: IRecurringDay[] = [
        {
            abbreviation: t('abbrevMonday'),
            name: t('monday'),
            value: 1
        },
        {
            abbreviation: t('abbrevTuesday'),
            name: t('tuesday'),
            value: 2
        },
        {
            abbreviation: t('abbrevWednesday'),
            name: t('wednesday'),
            value: 4
        },
        {
            abbreviation: t('abbrevThursday'),
            name: t('thursday'),
            value: 8
        },
        {
            abbreviation: t('abbrevFriday'),
            name: t('friday'),
            value: 16
        },
        {
            abbreviation: t('abbrevSaturday'),
            name: t('saturday'),
            value: 32
        },
        {
            abbreviation: t('abbrevSunday'),
            name: t('sunday'),
            value: 64
        },
    ]

    const [selectedDays, setSelectedDays] = useState<number>(recurrence.repeating_days)
    const toggleDay = (dayValue: number) => {
        let newValue: number = 0
        const isSelected = (dayValue & selectedDays) >= dayValue

        if (isSelected) {
            // deselect
            newValue = selectedDays - dayValue
            setSelectedDays(newValue)
        } else {
            // select
            newValue = selectedDays + dayValue
            setSelectedDays(newValue)
        }

        const updated = Object.assign({}, recurrence, {repeating_days: newValue})
        onChange(updated)
    }

    // const [updatedRecurrence, setUpdatedRecurrence] = useState(recurrence)
    // const [endsOnDate, setEndsOnDate] = useState<Date>(moment().toDate())

    const setTimeframe = (value: string) => {
        const updated = Object.assign({}, recurrence)

        if (["day", "week", "month", "year"].includes(value)) {
            // @ts-ignore
            updated.repeating_timeframe = value
            onChange(updated)
        }
    }

    // const changeEndsOnDate = (date: any) => {
    //     // if(date === null) { return }
    //     // if(Array.isArray(date)) { return }
    //     //
    //     if (typeof date.getMonth === 'function') {
    //         setEndsOnDate(date)
    //         const updated = updatedRecurrent
    //         updated.ends_on_date = moment(date).toISOString()
    //         setUpdatedRecurrent(updated)
    //     }
    // }

    // const changeRepeatEvery = (value: string) => {
    //     const updated = Object.assign({}, recurrence)
    //     updated.repeat_every = parseInt(value, 10)
    //     onChange(updated)
    // }

    return (
        <Modal show={show} onHide={hide} onClose={hide} className='recurrent-modal'>
            <Modal.Header>
                {t('Recurrences')}
            </Modal.Header>

            <Modal.Body>
                <div className="d-flex flex-row">
                    <label htmlFor="repeatEvery" className="mr-1">{t('Repeat every')}</label>
                    {/*<div className="">*/}
                    {/*    <input*/}
                    {/*        type="number"*/}
                    {/*        value={recurrence.repeat_every}*/}
                    {/*        onChange={(event) => changeRepeatEvery(event.target.value)}*/}
                    {/*        className="form-control-plaintext" id="repeatEvery"*/}
                    {/*    />*/}
                    {/*</div>*/}
                    <div className="">
                        <select
                            className="form-control"
                            defaultValue={recurrence.repeating_timeframe}
                            onChange={(element) => setTimeframe(element.target.value)}
                        >
                            <option value='day'>{t('day')}</option>
                            <option value='week'>{t('week')}</option>
                            {/*<option value='month'>{t('month')}</option>*/}
                            {/*<option value='year'>{t('year')}</option>*/}
                        </select>
                    </div>
                </div>

                {recurrence.repeating_timeframe === 'week' && (
                    <>
                        <label htmlFor="exampleFormControlInput1" className=''>
                            {t('Repeat on')}
                        </label>
                        <div className="d-flex flex-row">
                            <div className="" role="group" aria-label="First group">
                                {days.map((day: IRecurringDay, index: number) => (
                                    <button
                                        key={index}
                                        type="button"
                                        className={`btn mr-1 ${selectedDays & day.value ? 'btn-primary' : 'btn-outline-secondary'}`}
                                        onClick={() => toggleDay(day.value)}
                                    >
                                        {day.abbreviation}
                                    </button>
                                ))}
                            </div>
                        </div>
                    </>
                )}

                {/*<label htmlFor="staticEmail" className="">*/}
                {/*    {t('Ends')}*/}
                {/*</label>*/}
                {/*<div className="d-flex flex-column">*/}
                {/*    <div className="d-flex flex-row">*/}
                {/*        <input*/}
                {/*            className=""*/}
                {/*            type="radio"*/}
                {/*            name="exampleRadios"*/}
                {/*            id="exampleRadios1"*/}
                {/*            value="option1"*/}
                {/*            checked*/}
                {/*            onChange={(event) => changeEnding('never')}*/}
                {/*        />*/}
                {/*        <label className="" htmlFor="exampleRadios1">*/}
                {/*            {t('never')}*/}
                {/*        </label>*/}
                {/*    </div>*/}

                {/*    <div className="d-flex flex-row">*/}
                {/*        <input*/}
                {/*            className=""*/}
                {/*            type="radio"*/}
                {/*            name="exampleRadios"*/}
                {/*            id="exampleRadios1"*/}
                {/*            value="option1"*/}
                {/*            checked*/}
                {/*            onChange={(event) => changeEnding('ends_on_date')}*/}
                {/*        />*/}
                {/*        <label className="" htmlFor="exampleRadios1">*/}
                {/*            {t('on')}*/}
                {/*            <DatePicker*/}
                {/*                selected={endsOnDate}*/}
                {/*                onChange={date => changeEndsOnDate(date)}*/}
                {/*                dateFormat="dd-MM-yyyy"*/}
                {/*                className=""*/}
                {/*                showTimeSelect*/}
                {/*                showMonthDropdown*/}
                {/*                showYearDropdown*/}
                {/*                dropdownMode="select"*/}
                {/*                placeholderText={t("Creation date")}*/}
                {/*                locale="nl"*/}
                {/*            />*/}
                {/*        </label>*/}
                {/*    </div>*/}

                {/*    <div className="d-flex flex-row">*/}
                {/*        <input*/}
                {/*            className=""*/}
                {/*            type="radio"*/}
                {/*            name="exampleRadios"*/}
                {/*            id="exampleRadios1"*/}
                {/*            value="option1"*/}
                {/*            checked*/}
                {/*            onChange={(event) => changeEnding('after')}*/}
                {/*        />*/}
                {/*        <label className="" htmlFor="exampleRadios1">*/}
                {/*            {t('after')}*/}
                {/*        </label>*/}
                {/*        <div className="">*/}
                {/*            <input*/}
                {/*                type="number"*/}
                {/*                value={updatedRecurrent.repeat_every}*/}
                {/*                onChange={(event) => changeRepeatEvery(event.target.value)}*/}
                {/*                className=""*/}
                {/*                id="repeatEvery"*/}
                {/*            />*/}
                {/*        </div>*/}
                {/*        <div>*/}
                {/*            {t('occurrences')}*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </Modal.Body>

            <Modal.Footer>
                <Button variant="link" onClick={hide}>
                    {t('Cancel')}
                </Button>
                <Button
                    type="submit"
                    size='lg'
                    id='settings-update'
                    className="btn btn-primary"
                    onClick={hide}
                    // onClick={(event) => handleSubmit(event)}
                >
                    {t('Done')}
                </Button>
            </Modal.Footer>
        </Modal>
    )
};

export default RecurrentModal;
