import React from 'react';
import ReactGA from 'react-ga';

class ReportsPage extends React.Component
{
    componentDidMount = () => {
        // insert code for production
        if(process.env.NODE_ENV === 'production') {
            ReactGA.pageview("/reports");
        }
    };

    render()
    {

        return (
            <React.Fragment>
                <h1>Rapportages</h1>
                <div>
                    In de toekomst kun je hier rapportages verwachten.
                </div>
            </React.Fragment>
        )
    }
}

export default ReportsPage