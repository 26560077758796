import axios from "axios";
import errorChecker from "./errorchecker";
import persistentStore from "../stores/persistentStore";

const infoRequests = () => {

    const ip = async () => {
        const apiKey = persistentStore.IPDToken
        const url = `https://api.ipdata.co?api-key=${apiKey}`

        if(! apiKey){
            console.error('No IPD api token available');
            return
        }

        return axios.get(url)
            .then(response => errorChecker(response))
            .then(response => {
                if (!response.data) {
                    console.error('No info received for validation');
                }

                return response.data
            })
    };

    return {
        ip,
    }
}

export const info = infoRequests()
