import React from "react";

const CardFooter = (props) => {

    return (
        <div className="card-footer text-muted">
            <div className="row">
                <div className="total-label col-9">Totaal verbruikt</div>
                <div className="total-value col-3 text-right">{props.card.totalUnitsUsed}</div>
            </div>
            <div className="row">
                <div className="text-center col-12 card-description">{props.card.description}</div>
            </div>
        </div>
    );
};

export default CardFooter;
