import React, {ChangeEvent} from 'react';
import i18next from "i18next";
import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import persistentStore from "../stores/persistentStore";

type LanguageSwitcherProps = {
    className?: string,
}

const LanguageSwitcher = (props: LanguageSwitcherProps) => {
    const {t} = useTranslation()

    const changeLanguage = (language: string) => {
        persistentStore.setUserLanguage(language)
        i18next.changeLanguage(language)
    }

    return (
        <select
            name="changeLanguage"
            className={`${props.className}`}
            id="changeLangauge"
            onChange={(e:ChangeEvent<HTMLSelectElement>) => changeLanguage(e.target.value)}
            value={persistentStore.languageSelected}
        >
            <option value="nl">{t('Dutch')}</option>
            <option value="en">{t('English')}</option>
        </select>
    )
};

export default observer(LanguageSwitcher)
