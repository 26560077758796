import React, {useState} from 'react';
import {Button, Card, Spinner, ButtonGroup} from "react-bootstrap";
import {users} from "../../../api/users";
import {useLoading} from "../../../utils/helpers";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import persistentStore from "../../../stores/persistentStore";
import {IUserSettings} from "../../../interfaces/IUserSettings";
import LanguageSwitcher from "../../../components/LanguageSwitcher";

const SettingsTab = () => {
    const [loading, startLoading, stopLoading] = useLoading()
    const [checkBoxToggle, setCheckBoxToggle] = useState(persistentStore.user?.settings.unitAdder?.mailByDefault)
    const [chosenName, setChosenName] = useState(persistentStore.user?.settings?.stripName)
    const [useType, SetUseType] = useState(persistentStore.user?.settings?.useType)
    const [pageLength, setPageLength] = useState<number>(persistentStore.pageLength)
    const {t} = useTranslation()

    const setStripName = (chosenName: string) => {
        if (chosenName === 'strippen') {
            return setChosenName({singular: 'strip', plural: 'strippen'})
        }

        if (chosenName === 'credits') {
            return setChosenName({singular: 'credit', plural: 'credits'})
        }

        if (chosenName === 'ritten') {
            return setChosenName({singular: 'rit', plural: 'ritten'})
        }

        if (chosenName === 'uur' || chosenName === 'hours') {
            setChosenName({singular: 'uur', plural: 'uur'})
            return SetUseType('hours')
        }

        if (chosenName === 'strips') {
            return SetUseType(chosenName)
        }
    }

    // when the form is submitted
    const handleSubmitSettings = (event: any) => {
        event.preventDefault()

        if (!persistentStore.user) {
            return
        }

        startLoading()

        const copyOfSettings = JSON.parse(JSON.stringify(persistentStore.user.settings))
        const updatedSettings: IUserSettings = Object.assign(
            copyOfSettings,
            {unitAdder: {mailByDefault: checkBoxToggle}, stripName: chosenName, useType: useType, paginationLength: pageLength}
        )

        users.updateSettings(updatedSettings, persistentStore.user.id)
            .then(response => {
                if (response.success) {
                    toast.success(t('Settings updated'))
                    persistentStore.setUser(response.data.user)
                    persistentStore.setPageLength(pageLength)
                } else {
                    toast.error(t('Settings could not be updated'))
                }
            })
            .finally(() => {
                stopLoading()
            })
    }

    return (
        <form onSubmit={handleSubmitSettings}>
            <Card className="mt-4 mb-5 settings-page">
                <Card.Body>
                    <p className="mt-4"><b>Strippenkaart</b></p>
                    <p className="text-muted">{t('These changes reflect on working with the cards')}</p>
                    <label className="checkbox-inline">
                        <input
                            type="checkbox"
                            id='mailByDefault'
                            checked={checkBoxToggle}
                            onChange={(e) => setCheckBoxToggle(e.target.checked)}
                            className='mr-1'
                        />
                        {t('Sent e-mail update to customer by default')}
                    </label>

                    <p className="mt-4"><b>{t('Strippen, credits or something else?')}</b></p>
                    <p className="text-muted">{t('Do you use a name other than "strippen"? Choose your option here')}</p>

                    <ButtonGroup className="custom-btn-group">
                        <Button
                            variant={`${chosenName?.plural === 'strippen' ? 'success' : 'outline-secondary'}`}
                            onClick={() => setStripName('strippen')}
                            title={t('Strippen')}
                            className={`${chosenName?.plural === 'strippen' && 'active'}`}
                            id='name-strips'
                        >
                            {t('Strippen')}
                        </Button>
                        <Button
                            variant={`${chosenName?.plural === 'ritten' ? 'success' : 'outline-secondary'}`}
                            onClick={() => setStripName('ritten')}
                            title={t('Ritten')}
                            className={`${chosenName?.plural === 'ritten' && 'active'}`}
                            id='name-ritten'
                        >
                            {t('Ritten')}
                        </Button>
                        <Button
                            variant={`${chosenName?.plural === 'credits' ? 'success' : 'outline-secondary'}`}
                            onClick={() => setStripName('credits')}
                            title={t('Credits')}
                            className={`${chosenName?.plural === 'credits' && 'active'}`}
                            id='name-credits'
                        >
                            {t('Credits')}
                        </Button>
                        <Button
                            variant={`${chosenName?.plural === 'uur' ? 'success' : 'outline-secondary'}`}
                            onClick={() => setStripName('uur')}
                            title={t('Uur')}
                            className={`${chosenName?.plural === 'uur' && 'active'}`}
                            id='name-uur'
                        >
                            {t('Uur')}
                        </Button>
                    </ButtonGroup>

                    <p className="mt-4"><b>{t('Time or Strips')}</b></p>
                    <p className="text-muted">{t('Choose whether you want to book off time or credits off your cards')}</p>
                    <ButtonGroup className="custom-btn-group">
                        <Button
                            variant={`${useType === 'hours' ? 'success' : 'outline-secondary'}`}
                            onClick={() => setStripName('hours')}
                            title={t('Time')}
                            className={`${useType === 'hours' && 'active'}`}
                            id='book-time'
                        >
                            {t('Time')}
                        </Button>
                        <Button
                            variant={`${useType === 'strips' ? 'success' : 'outline-secondary'}`}
                            onClick={() => setStripName('strips')}
                            title={t('Strips')}
                            className={`${useType === 'strips' && 'active'}`}
                            id='book-strips'
                        >
                            {t('Strips')}
                        </Button>
                    </ButtonGroup>

                    <p className="mt-4"><b>{t('Pagination Length')}</b></p>
                    <p className="text-muted">{t('Set or change your pagination length')}.</p>
                    <select
                        className="form-control col-3"
                        id="paginationLength"
                        onChange={(e) => {
                            setPageLength(parseInt(e.target.value, 10))
                        }}
                        value={pageLength}
                        disabled={loading}
                    >
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="150">150</option>
                    </select>

                    <p className="mt-4"><b>{t('Language')}</b></p>
                    <p className="text-muted">{t('Change the language of the application')}.</p>
                    <LanguageSwitcher className="form-control left col-3" />

                </Card.Body>

                <Card.Footer>
                    <Button
                        type="submit"
                        size='lg'
                        id='settings-update'
                        className="btn btn-primary"
                        disabled={loading}
                    >
                        {loading &&
                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>}
                        {!loading && t('Submit')}
                    </Button>
                </Card.Footer>
            </Card>
        </form>
    )
}

export default SettingsTab;
