import React, {useEffect, useState} from 'react';
import DataTable from "react-data-table-component";
import persistentStore from "../../stores/persistentStore";
import FilterComponent from "../../components/FilterComponent";
import {IUser} from "../../interfaces/IUser";
import {IEvent} from "../../interfaces/IEvent";
import {QueryResult, useQuery} from "react-query";
import {users as usersApi} from "../../api/users";
import {observer} from "mobx-react";
import moment from "moment";

type TrialMailsTableProps = {
    eventsQuery: QueryResult<IEvent[]>
}

const TrialMailsTable = (props: TrialMailsTableProps) => {
    const {eventsQuery} = props
    const [filterText, setFilterText] = React.useState('');
    const userQuery = useQuery('getUsers', usersApi.get)
    const [users, setUsers] = useState<IUser[]>([] as IUser[])
    const [events, setEvents] = useState<IEvent[]>([])

    useEffect(() => {
        if( eventsQuery.data){
            setEvents(eventsQuery.data)
        }

    }, [eventsQuery.data])

    useEffect(() => {
        if(! userQuery.data){
            return
        }

        setUsers(userQuery.data)
    }, [userQuery.data])

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setFilterText('');
            }
        };

        return <FilterComponent
            onFilter={(e: any) => setFilterText(e.target.value)}
            onClear={handleClear}
            filterText={filterText}
        />;
    }, [filterText]);

    const filteredEvents = events.filter((event: IEvent) => {
        if (filterText === '') {
            return true
        }

        const data = JSON.stringify(event.data).toLowerCase()
        const query = filterText.toLowerCase()

        return (data && data.includes(query)) ||
            (event.type && event.type.toLowerCase().includes(query)) ||
            (event.email && event.email.toLowerCase().includes(query))
    });

    const columns = [
        {
            name: 'Sent',
            selector: 'created',
            sortable: true,
            width: '200px',
            cell: (event: IEvent) => moment(event.created).fromNow()
        },
        {
            name: 'Type',
            sortable: false,
            width: '100px',
            cell: (event: IEvent) => {
                const type = event.type

                if(type === 'TRIALMAIL_LOG'){
                    return 'log'
                }

                if(type === 'TRIALMAIL_TEST'){
                    return 'test'
                }

                if(type === 'TRIALMAIL_TESTRUN'){
                    return 'test'
                }

                if(type === 'TRIALMAIL_SENT'){
                    return 'verzonden'
                }

                return type
            },
        },
        {
            name: 'To email',
            sortable: false,
            width: '400px',
            cell: (event: IEvent) => {
                const data = event.data
                const name = event.name
                const email = data.recipient ? data.recipient : ''

                if(event.type === 'TRIALMAIL_TESTRUN'){
                    return data.testrun.join('\n')
                }

                if(event.type === 'TRIALMAIL_LOG'){
                    if(data.error){
                        return data.error.join('\n\r')
                    }

                    if(data.output){
                        return data.output.join('\n\r')
                    }

                    console.log(data.error.join('\n\r'))
                    return data.error.join('\n\r')
                }

                if(users.length > 0){
                    const user: IUser|undefined = users.find((user: IUser) => {
                        return user.id === parseInt(event.user_id, 10)
                    })

                    if(user === undefined){
                        // console.log(`Could not find user with id ${event.user_id}`)
                        // console.log(`${name} (${email})`)
                        return `${name} (${email})`
                    }
                    const userLink: string = `/users/${user.id}`

                    return <div>
                        <a href={`${userLink}`}>
                            {user.name}
                        </a>
                        {` (${email})`}
                    </div>
                }

                return `${name} (${email})`
            },
        }
    ]

    return (
        <DataTable
            columns={columns}
            data={filteredEvents}
            pagination={(filteredEvents||[]).length > 15}
            paginationPerPage={persistentStore.pageLength}
            paginationRowsPerPageOptions={persistentStore.pageLengthOptions}
            onChangeRowsPerPage={(rowCount) => persistentStore.setPageLength(rowCount)}
            noHeader
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            persistTableHead
            defaultSortField='created'
            defaultSortAsc={false}
            progressPending={eventsQuery.isLoading}
        />
    );
}

export default observer(TrialMailsTable);
