import React from 'react';
import {useTranslation} from "react-i18next";

const MollieIntegration = (props: any) => {
    const {t} = useTranslation()
    return (
        <>
            <label htmlFor="basic-url">Mollie</label>
            <p className="text-muted">
                {t('Have your customers buy your cards online and pay with iDeal with this Mollie integration')}
            </p>
            <div className="input-group mb-3">
                <div className="input-group-prepend">
                    <span className="input-group-text">{t('test key')}</span>
                </div>
                <input type="text" className="form-control" 
                    id="mollie-key-test"
                    name="mollie-key-test"
                    aria-describedby="mollie-key-test"
                    value={props.testKey}
                    onChange={(event) => props.setInputValue(event.target)}
                />
            </div>

            <div className="input-group mb-3">
                <div className="input-group-prepend">
                    <span className="input-group-text">{t('live key')}</span>
                </div>
                <input type="text" className="form-control"
                    id="mollie-key-live"
                    name="mollie-key-live"
                    aria-describedby="mollie-key-live"
                    value={props.liveKey}
                    onChange={(event) => props.setInputValue(event.target)}
                />
            </div>

        </>
    )
}

export default MollieIntegration;
