import React, {useEffect, useState} from 'react';
import './Loader.scss'
import {users} from "../api/users";

const Loader = () => {
    const [totalSeconds, setTotalSeconds] = useState(0)
    const clearCacheMessage = 'De cache wordt geleegd want het laden duurt te lang'
    let mounted = true

    setInterval(setTime, 1000);

    function setTime() {
        if(mounted){
            const newTime = totalSeconds + 1
            setTotalSeconds(newTime);
        }
    }

    useEffect(() => {

        if(totalSeconds > 7){
            console.error('Page is loading for more than 7 seconds. Clearing the cache now....')

            const loaderElement =  document.getElementById("loader");
            if(loaderElement && mounted){
                loaderElement.innerHTML = clearCacheMessage
                users.event('FORCE_CLEAR_CACHE')
                window.location.href = window.location.origin + "/clear-cache";
            }
        }

        return () => {
            // eslint-disable-next-line
            mounted = false
        }
    }, [totalSeconds, clearCacheMessage, mounted])

    return (
        <div className='loading' id='loader'>
            Loading&#8230;
        </div>
    )
};

export default Loader;
