import React from 'react';
import { createGlobalStyle } from 'styled-components';
import persistentStore from "./stores/persistentStore";

const Theme = () => {

const GlobalStyles = createGlobalStyle`
    .sidebar-sticky {
        background: ${persistentStore.user?.settings?.primaryColor};
    }
    .sidebar .nav-link{
        color: ${persistentStore.user?.settings?.secondryColor};
    }
    .sidebar .nav-link .feather{
        color: ${persistentStore.user?.settings?.secondryColor};
    }
    `
    return (
        <>
            <GlobalStyles />
        </>
        )
}   

export default Theme;