import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {observer} from 'mobx-react'
import store from '../stores/store'
import {users} from '../api/users'
import './Header.scss'
import {Button, Image} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {User as UserIcon} from "react-feather";
import {ImpersonateHeader} from "./ImpersonateHeader";
import persistentStore from "../stores/persistentStore";
import jQuery from 'jquery';
import {toast} from "react-toastify";
import {useRouter} from "../utils/router";
import HeaderMobileMenu from "./HeaderMobileMenu";
import version from '../metadata.json'

// Detects if device is in standalone mode
// const isStandaloneMode = (): boolean => {
//     // @ts-ignore
//     return !!(("standalone" in window.navigator) && window.navigator.standalone);
// }

// const isMobileDevice = (): boolean => {
//     // var userAgent = navigator.userAgent || navigator.vendor || window.opera;
//     const userAgent = navigator.userAgent || navigator.vendor
//
//     // Windows Phone must come first because its UA also contains "Android"
//     if (/windows phone/i.test(userAgent)) {
//         return true
//     }
//
//     if (/android/i.test(userAgent)) {
//         return true
//     }
//
//     // iOS detection from: http://stackoverflow.com/a/9039885/177710
//     if (isIOS()) {
//         return true
//     }
//
//     return false
// }

// const isIOS = (): boolean => {
//     return [
//             'iPad Simulator',
//             'iPhone Simulator',
//             'iPod Simulator',
//             'iPad',
//             'iPhone',
//             'iPod'
//         ].includes(navigator.platform)
//         // iPad on iOS 13 detection
//         || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
// }

const Header = () => {
    const [userLink, setUserLink] = useState<JSX.Element>()
    const {t} = useTranslation()
    // const [isMobile, setIsMobile] = useState(false)
    // const [isInStandalone, setIsInStandalone] = useState<boolean>(false)
    // const [installApp, setInstallApp] = useState(false)
    const versionString = process.env.NODE_ENV === 'development'
            ? `${version.buildMajor}.${version.buildMinor}.DEV`
            : `${version.buildMajor}.${version.buildMinor}.${version.buildRevision}`
    const router = useRouter()

    // useEffect(() => {
    //     const isStandAlone: boolean = isStandaloneMode()
    //     setIsInStandalone(isStandAlone)
    //
    //     // console.log(`standalone: ${isStandAlone}`)
    //
    //     const mobile = isMobileDevice()
    //     setIsMobile(mobile)
    //     // console.log(`mobile: ${mobile}`)
    // }, [])

    const logoutAndRemove = () => {
        users.event('REMOVE_THIS_TRIAL_ACCOUNT')
        users.logout()
    }

    const logoutUser = () => {
        // show a message when it's a trial user without an email
        const emptyMail = (persistentStore.user?.email === null || persistentStore.user?.email === '')
        const isTrialUser = persistentStore.user?.type === 'trial'

        if (isTrialUser && emptyMail) {
            const message = (
                <>

                <div>
                    <strong>Oeps, je account wordt verwijderd</strong>
                    <p className='mt-2'>
                        Je hebt nog geen emailadres.<br/><br/>
                        Wanneer je nu uitlogt kun je niet meer inloggen. Je klanten, strippenkaarten en gegevens worden verwijderd.
                    </p>
                    <p>
                        Geef daarom je emailadres op om later terug te kunnen komen
                    </p>
                    <Button onClick={() => router.push('/config/general?setLoginInfo')}>E-mail opgeven</Button>

                    <Button className='mt-2' onClick={logoutAndRemove}>Uitloggen en account verwijderen</Button>
                </div>
                </>
            )

            toast.dark(message, {autoClose: false})
            return
        }

        users.logout()
    }

    useEffect(() => {
        if (persistentStore.user) {
            // set default
            let gravatarImage = <UserIcon size={30} color={'white'}/>

            // override there is a  logo
            if (persistentStore.user.logo) {
                gravatarImage = <Image
                    src={`${persistentStore.apiUrl}/img/logo/${persistentStore.user.logo}`}
                    alt='logo'
                    className='avatar'
                />
            }

            const userName = persistentStore.user.name
                ? persistentStore.user.name
                : persistentStore.user.email

            setUserLink(<>

                <Button variant='link' className="btn-logout" id='btn-logout' onClick={logoutUser}>
                    {gravatarImage} {userName} &nbsp; Log uit
                </Button>
            </>)
        } else {
            setUserLink(<Link to="/login" className="nav-link" id='login-logout'>Log in</Link>)
        }

        jQuery('html').on('click', function () {
            jQuery('#mainMenu').removeClass('show')
        });

        // eslint-disable-next-line
    }, [persistentStore.user])

    // if (isMobile && isInStandalone) {
    //     // let deferredPrompt;
    //
    //     setInstallApp(true)
    // }

    return (
        <>
            <nav className="Header navbar navbar-expand-md navbar-dark sticky-top bg-dark ">
                <Link to="/" id="app-name" className="navbar-brand">
                    <div className='app-name'>{store.company}</div>
                    <div className='version'>{t('version')}: {versionString}</div>
                </Link>

                {persistentStore.isTrialUser && (
                    <Button
                        size='sm'
                        className='d-block d-md-none'
                        variant='outline-primary'
                        onClick={() => store.setShowUpgrade(true)}
                        id='upgrade-trial'
                    >
                        {t('Upgrade')}
                    </Button>
                )}

                <button
                    className="navbar-toggler"
                    type="button" data-toggle="collapse"
                    data-target="#mainMenu" aria-controls="navbarSupportedContent"
                    aria-expanded="false" aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"/>
                </button>

                <HeaderMobileMenu userLink={userLink}/>

                {persistentStore.isImpersonating && <ImpersonateHeader/>}

                <ul className="navbar-nav px-3 float-right loginblock d-none d-sm-block">
                    <li className="nav-item text-nowrap">
                        {userLink}
                    </li>
                </ul>
            </nav>

            {/*{installApp && (*/}
            {/*    <Modal size="sm" show={installApp} className="text-center">*/}
            {/*        <Modal.Header>*/}
            {/*            <Modal.Title>Installeer de Strippenkaart App</Modal.Title>*/}
            {/*        </Modal.Header>*/}
            {/*        <Modal.Body>*/}
            {/*            <p>Installeer de Strippenkaart App op je thuis-scherm voor de beste ervaring.</p>*/}
            {/*            <p className="mt-4">*/}
            {/*                Klik <a href="#home" onClick={e => e.preventDefault()}><ShareIcon/></a> en dan*/}
            {/*                "Toevoegen aan thuis-scherm"*/}
            {/*            </p>*/}
            {/*        </Modal.Body>*/}
            {/*        <Modal.Footer style={{justifyContent: 'center'}}>*/}
            {/*            <Button onClick={(e) => setInstallApp(false)} size="sm" variant="secondary">Close</Button>*/}
            {/*        </Modal.Footer>*/}
            {/*    </Modal>*/}
            {/*)}*/}
        </>
    );
}

export default observer(Header)
