import React from 'react';
import Spinner from 'react-feather/dist/icons/settings';
import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";

const DashboardStats = (props) => {
    const { userStats, customerStats } = props
    const {t} = useTranslation()

    return (
        <>
            <div className="info-block col-3">
                <div id="stats-usertotal" className="number">
                    {userStats.isLoading  && <Spinner className="spinner"/>}
                    {!userStats.isLoading  && userStats.data?.loggedInUserCount}
                </div>
                <div className="label">{t('Logged in users')}</div>
            </div>

            <div className="info-block col-3">
                <div id="stats-activeusertotal" className="number">
                    {userStats.isLoading && <Spinner className="spinner"/>}
                    {! userStats.isLoading && userStats.data?.activeUserCount}
                </div>
                <div className="label">{t('Paying Users')}</div>
            </div>

            <div className="info-block col-3">
                <div id="stats-trialusertotal" className="number">
                    {userStats.isLoading && <Spinner className="spinner"/>}
                    {! userStats.isLoading && userStats.data?.trialUserCount}
                </div>
                <div className="label">{t('Trial Users')}</div>
            </div>

            <div className="info-block col-3">
                <div id="stats-customertotal" className="number">
                    {customerStats.isLoading && <Spinner className="spinner"/>}
                    {! customerStats.isLoading && customerStats.data?.customerCount}
                </div>
                <div className="label">{t('Customers')}</div>
            </div>
        </>
    );
}

export default observer(DashboardStats)
