import React, {useEffect, useState} from 'react';
import DataTable from "react-data-table-component";
import FilterComponent from "../../components/FilterComponent";
import persistentStore from "../../stores/persistentStore";
import {QueryResult} from "react-query";
import {IEvent} from "../../interfaces/IEvent";
import {useTranslation} from "react-i18next";
import moment from "moment";

type EventTableProps = {
    eventQuery: QueryResult<IEvent[]>
}

const EventTable = (props: EventTableProps) => {
    const [filterText, setFilterText] = React.useState('');
    const [events, setEvents ] = useState<IEvent[]>([])
    const {eventQuery} = props
    const {t} = useTranslation()

    // cast data to a string
    useEffect(() => {
        if(eventQuery.data){
            setEvents(eventQuery.data)
        }
    }, [eventQuery.data])

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                // setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return <FilterComponent
            placeholder={''}
            id={'search-events'}
            onFilter={(e: any) => setFilterText(e.target.value)}
            onClear={handleClear} filterText={filterText}
        />;
    }, [filterText]);

    const filteredEvents = events.filter((event: IEvent) => {
        if(filterText === ''){
            return true
        }
        const data = JSON.stringify(event.data).toLowerCase()
        const query = filterText.toLowerCase()

        return (data && data.includes(query)) ||
            (event.type && event.type.toLowerCase().includes(query)) ||
            (event.email && event.email.toLowerCase().includes(query))
    });

    const datafieldsToShowPerType: {[key: string]: string[]} = {
        'LOGGED_IN_SUCCESS': ['name', 'email'],
        'LOGGED_IN_FAILED': ['name', 'email'],
        'MAIL_SENT_SUCCESS': ['email', 'subject', 'substitutions'],
        'UNIT_ADD_SUCCESS': ['amount', 'description', 'time_in_seconds'],
    }

    const columns = [
        {
            name: 'Type',
            selector: 'type',
            sortable: true,
            width: '250px'
        },
        {
            name: 'Name',
            selector: 'name',
            sortable: true,
            width: '200px'
        },
        {
            name: 'Email',
            selector: 'email',
            sortable: true,
            width: '200px'
        },
        {
            name: 'Data',
            selector: 'data',
            sortable: false,
            cell: (event: IEvent) => {
                if(datafieldsToShowPerType.hasOwnProperty(event.type)){
                    const fields: string[] = datafieldsToShowPerType[event.type].map((fieldName: string) => event.data[fieldName])
                    return fields.join(' - ')
                }

                return JSON.stringify(event.data)
            }
        },
        {
            name: 'Created',
            selector: 'created',
            sortable: true,
            width: '200px',
            cell: (event: IEvent) => moment(event.created).format('Y-DD-MM H:m')
        },
    ]

    return (
        <DataTable
            columns={columns}
            data={filteredEvents}
            pagination
            paginationPerPage={persistentStore.pageLength}
            paginationRowsPerPageOptions={persistentStore.pageLengthOptions}
            onChangeRowsPerPage={(rowCount) => persistentStore.setPageLength}
            noHeader
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            persistTableHead
            progressPending={eventQuery.isLoading}
            noDataComponent={<div>{t('No events')}</div>}
        />
    );
}

export default EventTable;
