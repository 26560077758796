import React, {useContext} from 'react';
import CardHeader from './CardHeader'
import CardBody from './CardBody'
import CardFooter from './CardFooter'
import {secondsToTime} from "../../utils/helpers";
import {IUnit} from "../../interfaces/IUnit";
import {ICard} from "../../interfaces/ICard";
import UseTimeContext from "../../contexts/UseTimeContext";
import {ICustomer} from "../../interfaces/ICustomer";

type SingleStrippenkaartProps = {
    card: ICard,
    customer?:ICustomer
}

const SingleStrippenkaart = (props: SingleStrippenkaartProps) => {
    const {card, customer} = props
    const units = card.units
    const useTime: boolean = useContext(UseTimeContext)

    const countAmount = (units: IUnit[]) => {
        const totalUnitsUsed = units.reduce((carry, unit) => {
            return unit.amount + carry
        }, 0)

        return totalUnitsUsed
    }

    const totalTimeInSecondsUsed = (units: IUnit[]) => {
        const totalTimeUsedInSeconds = units.reduce((carry, unit) => {
            return unit.time_in_seconds + carry
        }, 0)

        return totalTimeUsedInSeconds
    }

    const countTime = (units: IUnit[]) => {
        return secondsToTime(totalTimeInSecondsUsed(units))
    }

    // sum up all units
    card.totalUnitsUsed = useTime
        ? countTime(units || [])
        : String(countAmount(units || []))

    card.totalUnitsLeft = useTime
        ? String(card.time_in_seconds - totalTimeInSecondsUsed(units || []))
        : String(card.amount - countAmount(units || []))

    return (
        <>
            <CardHeader card={card} />
            <CardBody card={card} customer={customer}/>
            <CardFooter card={card}/>
        </>
    )
}
export default SingleStrippenkaart
