import {QueryResult, useQuery} from "react-query";
import {users as usersApi} from "../../api/users";
import {events as eventsApi} from "../../api/events";
import {IUserStats} from "../../interfaces/IUserStats";
import {stats} from "../../api/stats";
import {ICustomerStats} from "../../interfaces/ICustomerStats";
import React, {useState} from "react";
import {Col, Row} from "react-bootstrap";
import DashboardStats from "./DashboardStats";
import TrialMailsTable from "./TrialMailsTable";
import EventTable from "./EventTable";
import UserTable from "./UserTable";
import TrialUserTable from "./TrialUserTable";
import {useTranslation} from "react-i18next";
import {GraphTrials} from "./GraphTrials";
import {GraphRevenueOverTime} from "./GraphRevenueOverTime";
import {GraphPageviewTargets} from "./GraphPageviewTargets";
import {GraphSales} from "./GraphSales";
import ToggleSlider from "../../components/ToggleSlider";
import {observer} from "mobx-react";

type AdminDashboardProps = {
    customerStats: QueryResult<ICustomerStats>
}

const AdminDashboard = (props: AdminDashboardProps) => {
    const users = useQuery('getUsers', usersApi.get)
    const eventQuery = useQuery('getEvents', eventsApi.get)
    const trialMailEventQuery = useQuery('getTrialMailEvents', eventsApi.trialMails)
    const [statsView, setStatsView] = useState<"monthly" | "daily">('monthly')
    const userStats: QueryResult<IUserStats> = useQuery('userStats', stats.getUserStats)
    const {customerStats} = props
    const {t} = useTranslation()

    return (
        <>
            <Row className="block-row">
                <DashboardStats loading={userStats.isLoading} userStats={userStats} customerStats={customerStats}/>
            </Row>

            <Row>
                <ToggleSlider
                    id='stats-view'
                    type='round'
                    checked={statsView === 'monthly'}
                    onChange={(checked: boolean) => {
                        setStatsView(checked ? 'monthly' : 'daily')
                    }}
                />
            </Row>

            <Row className="block-row pt-3 pb-3">
                <Col sm={12}>
                    <GraphPageviewTargets
                        title={'Pageview targets ' + t('over time')}
                        time={statsView}
                    />
                </Col>
            </Row>

            <Row className="block-row pt-3 pb-3">
                <Col sm={12}>
                    <GraphTrials
                        time={statsView}
                        title={t('New trials')}
                    />
                </Col>
            </Row>

            <Row className="block-row pt-3 pb-3">
                <Col sm={12}>
                    <GraphSales
                        time={statsView}
                        title={t('Sales')}
                        monthTarget={5}
                        dayTarget={1}
                    />
                </Col>
            </Row>

            <Row className="block-row pt-3 pb-3">
                <Col sm={12}>
                    <GraphRevenueOverTime
                        title={'Omzet ' + t('over time')}
                    />
                </Col>
            </Row>

            <Row className="mt-4">
                <h2 className="m-0">Trial Mails Status</h2>
                <TrialMailsTable eventsQuery={trialMailEventQuery}/>
            </Row>

            <Row className="mt-4">
                <h2 className="m-0">Events</h2>
                <EventTable eventQuery={eventQuery}/>
            </Row>

            <Row className="mt-4">
                <h2 className="m-0">Users</h2>
                <UserTable users={users}/>
            </Row>

            <Row className="mt-4">
                <h2 id="trial" className="m-0">Trial Users</h2>
                <TrialUserTable loading={userStats.isLoading} userStats={userStats}/>
            </Row>
        </>
    )
}

export default observer(AdminDashboard)
