import React from 'react';
import {Button, Modal} from "react-bootstrap";
import persistentStore from "../stores/persistentStore";
import './TrialInfoModal.scss'
import {useRouter} from "../utils/router";

const TrialInfoModal = (props: any) => {
    const hasEmailAddress: boolean = (persistentStore.user?.email !== null && persistentStore.user?.email !== '' )
    const router = useRouter()

    const handleCloseTrialInfo = () => {
        props.onHide()
        persistentStore.setShowTrialInfo(false)
    };

    return (
        <Modal
            show={persistentStore.showTrialInfo}
            onHide={handleCloseTrialInfo}
            backdrop="static"
            keyboard={false}
            className='TrialInfoModal'
        >
            <Modal.Header closeButton>
                <Modal.Title> <span role='img' aria-label='wave'>👋</span> Dit is je persoonlijk probeer-account!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className='mb-4'>
                    Leuk dat je de Strippenkaart App wilt proberen!<br />
                    Ik heb hieronder nog wat tips en info voor je <span role='img' aria-label='kijk beneden'>👇</span>
                </p>
                <ul>
                    <li>Dit is <strong>jouw persoonlijke probeer-account</strong>.<br />Alleen jij kunt hierbij.</li>
                    <li>Je kunt <strong>een week lang</strong> van alle functionaliteiten gebruik maken</li>
                    {! hasEmailAddress && (
                        <li>{hasEmailAddress && ''} Wil je later weer in kunnen loggen? <br />Geef dan je e-mailadres op bij <strong>Configuratie &gt; Instellingen</strong></li>
                    )}
                    <li>Andere bezoekers of gebruikers kunnen jouw gegevens niet zien.</li>
                    <li>Alle informatie wordt binnen Europa opgeslagen</li>
                </ul>
                <p className='mt-4'>
                    Vragen? Je kunt me mailen op <a href='mailto:paul@strippenkaart.app'>paul@strippenkaart.app</a>
                </p>

                <p>
                    <strong>Klaar voor de start?</strong> Sluit dan deze melding en maak je eerste klant aan!
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={() => router.push(`/config/general?setLoginInfo`)} disabled={hasEmailAddress}>
                    {hasEmailAddress && 'E-mailadres is gezet ✅' }

                    {! hasEmailAddress && 'E-mailadres opgeven'}
                </Button>
                <Button variant="secondary" onClick={handleCloseTrialInfo}>
                    Starten
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default TrialInfoModal;
