import React, {ChangeEvent} from 'react';
import './ToggleSlider.scss'

type ToggleSliderProps = {
    id: string,
    type: "round" | "square",
    size?: "sm" | "md" | "lg",
    checked: boolean,
    onChange: (checked: boolean) => void,
    className?: string,
    style?: object,
    text?: string
}

const ToggleSlider = (props: ToggleSliderProps) => {
    const {type, checked, className, onChange} = props
    const size = props.size ? props.size : 'lg'
    const localStyle = {
        display: 'flex',
    }
    const style = Object.assign({}, localStyle, props.style)
    const randomNumber = Math.floor(Math.random() * 10000)

    return (
        <div style={style} className={`toggle-slider ${size} ${className}`}>
            <label className={`switch`}>
                <input
                    id={props.id ? props.id : `toggle-slider-${randomNumber}`}
                    type="checkbox"
                    checked={checked}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => onChange(event.target.checked)}
                />
                <span className={`slider ${type} ${size}`}/>
            </label>
            <span className='ml-2 text'>{props.text || ''}</span>
        </div>
    )
}

export default ToggleSlider;
