import React from 'react';
import {useHistory, useParams} from "../utils/router";
import featureToggleStore from "../stores/featureToggleStore";

const FeatureToggle = () => {
    const {featureName, featureStatus} = useParams<{featureName: string|undefined, featureStatus: string|undefined}>()
    const history = useHistory()

    if(featureName === undefined || featureStatus === undefined){
        history.goBack()
        return null
    }

    // get all features we can use
    if(! featureToggleStore.hasOwnProperty(featureName)){
        alert('Feature does not exist')
        history.goBack()
        return null
    }

    if(featureStatus !== "0" && featureStatus !== "1"){
        alert('Invalid value!')
        history.goBack()
        return null
    }

    // update the value
    featureToggleStore.set(featureName, featureStatus === "1")

    window.location.href = '/'

    return (
        <div>hoi</div>
    )
};

export default FeatureToggle;
