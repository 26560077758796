import React from 'react';
import {observer} from 'mobx-react'
import {NumberOfCustomers} from './NumberOfCustomers'
import {NumberOfStripsSold} from './NumberOfStripsSold'
import {NumberOfStripsBooked} from './NumberOfStripsBooked'
import {NumberOfStripsLeft} from './NumberOfStripsLeft'
import {CustomerOutOfStrips} from './CustomerOutOfStrips'
import {CustomerStripsBought} from './CustomerStripsBought'
import {CustomerStripsOverTime} from './CustomerStripsOverTime'
import {Row, Col} from "react-bootstrap";
import {stats} from '../../api/stats'
import persistentStore from "../../stores/persistentStore";
import {QueryResult, useQuery} from "react-query";
import {users as usersApi} from "../../api/users";
import {customers as customersApi} from '../../api/customers'
import AdminDashboard from "./AdminDashboard";
import {useTranslation} from "react-i18next";
import {stripnameTimePlural} from "../../utils/stripname";
import OrderTable from "../Users/OrderTable";
import {orders} from "../../api/orders";
import StatsForUsers from "../Users/StatsForUsers";

const Dashboard = () => {
    const customerStats: QueryResult<any> = useQuery('customerStats', stats.getCustomerStats)
    const customers = useQuery('getCustomers', customersApi.getAll)
    const stripsOverTimeQuery = useQuery('stripsOverTime', usersApi.stripsOverTime)
    const ordersQuery = useQuery(
        'getOrders',
        orders.getFromUser,
        {
            enabled: (persistentStore.user !== undefined)
        }
    )
    const {t} = useTranslation()

    return (
        <div id='dashboard' className='container m-0'>
            <h1>Dashboard</h1>

            {!persistentStore.isAdmin && (
                <>
                    <Row className="block-row pt-3 pb-3">
                        <Col sm={6} xl={3}>
                            <NumberOfCustomers
                                loading={customerStats.isLoading}
                                stats={customerStats}
                                title={t('Customers')}
                            />
                        </Col>

                        <Col sm={6} xl={3}>
                            <NumberOfStripsSold
                                loading={customers.isLoading}
                                customers={customers.data || []}
                                title={t('sold')}
                            />
                        </Col>

                        <Col sm={6} xl={3}>
                            <NumberOfStripsBooked
                                loading={customers.isLoading}
                                customers={customers.data || []}
                                title={t('booked')}
                            />
                        </Col>

                        <Col sm={6} xl={3}>
                            <NumberOfStripsLeft
                                loading={customers.isLoading}
                                customers={customers.data || []}
                                title={t('available')}
                            />
                        </Col>
                    </Row>

                    <Row className="block-row pt-3">
                        <Col sm={6} className="mb-3">
                            <CustomerOutOfStrips
                                loading={customers.isLoading}
                                customers={customers.data || []}
                                title={t('Customers running out of ') + stripnameTimePlural()}
                            />
                        </Col>

                        <Col sm={6} className="mb-3">
                            <CustomerStripsBought
                                loading={customers.isLoading}
                                customers={customers.data || []}
                                title={t('Customers buying the most')}
                            />
                        </Col>
                    </Row>

                    <Row className="block-row pt-3 pb-3">
                        <Col sm={12}>
                            <CustomerStripsOverTime
                                loading={stripsOverTimeQuery.isLoading}
                                stats={stripsOverTimeQuery.data?.user_statistics}
                                unit_stats={(stripsOverTimeQuery.data?.unit_stats || [])}
                                timespan='day'
                                title={'Strippen ' + t('over time')}
                            />
                        </Col>
                    </Row>

                    <Row className="block-row pt-3 pb-3">
                        <Col sm={12}>
                            <StatsForUsers />
                        </Col>
                    </Row>

                    {ordersQuery.data && ordersQuery.data.length > 0 && (
                        <Row className="block-row pt-3 pb-3">
                            <Col md={12}>
                                <h3>{t('Orders')}</h3>
                                <OrderTable orders={ordersQuery.data || []}/>
                            </Col>
                        </Row>
                    )}
                </>
            )}

            {persistentStore.isAdmin && (
                <AdminDashboard customerStats={customerStats}/>
            )}
        </div>
    );
}

export default observer(Dashboard);
