import React, {useEffect, useState} from 'react';
import {QueryResult, useQuery, useQueryCache} from "react-query";
import {IRecurrence} from "../interfaces/IRecurrence";
import {Loader, Trash2, Mail, Clock} from "react-feather";
import {recurrences as recurrencesApi} from "../api/recurrences";
import {Card} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {IRecurringDay} from "../interfaces/IRecurringDay";
import {stripSingularOrPlural} from "../utils/card";
import moment from "moment";
import {toast} from "react-toastify";
import './Recurrences.scss'
import {IUnit} from "../interfaces/IUnit";
import {units} from "../api/units";

type RecurrencesProps = {
    className: string,
    customerPublicId: string,
}

const Recurrences = (props: RecurrencesProps) => {
    const {className, customerPublicId} = props
    const [recurrences, setRecurrences] = useState<IRecurrence[]>([])
    const recurrenceQuery: QueryResult<{ recurrences: IRecurrence[] }> = useQuery(
        ['getRecurrences', customerPublicId],
        () => recurrencesApi.getFromCustomerPublicId(customerPublicId),
    )
    const queryCache = useQueryCache()
    const {t} = useTranslation()

    useEffect(() => {
        if (recurrenceQuery.data?.recurrences) {
            setRecurrences(recurrenceQuery.data.recurrences)
        }
    }, [recurrenceQuery.data])

    if (recurrenceQuery.isLoading) {
        return <Loader/>
    }

    const days: IRecurringDay[] = [
        {
            abbreviation: t('abbrevMonday'),
            name: t('monday'),
            value: 1
        },
        {
            abbreviation: t('abbrevTuesday'),
            name: t('tuesday'),
            value: 2
        },
        {
            abbreviation: t('abbrevWednesday'),
            name: t('wednesday'),
            value: 4
        },
        {
            abbreviation: t('abbrevThursday'),
            name: t('thursday'),
            value: 8
        },
        {
            abbreviation: t('abbrevFriday'),
            name: t('friday'),
            value: 16
        },
        {
            abbreviation: t('abbrevSaturday'),
            name: t('saturday'),
            value: 32
        },
        {
            abbreviation: t('abbrevSunday'),
            name: t('sunday'),
            value: 64
        },
    ]

    // make a list in the Oxford comma style (eg "a, b, c, and d")
    // Examples with conjunction "and":
    // ["a"] -> "a"
    // ["a", "b"] -> "a and b"
    // ["a", "b", "c"] -> "a, b, and c"
    const oxford = function (arr: string[], conjunction: string, ifempty: string) {
        return arr
            .join(', ')
            .replace(
                /, ([^,]*)$/,
                ` ${conjunction} $1`
            )

        //
        // let l = arr.length;
        // if (!l) return ifempty;
        // if (l < 2) return arr[0];
        // if (l < 3) return arr.join(` ${conjunction} `);
        // arr = arr.slice();
        // arr[l - 1] = `${conjunction} ${arr[l - 1]}`;
        // return arr.join(", ");
    }

    const activeDays = (recurrence: IRecurrence) => {
        const activeDaysString: string[] = []
        days.forEach((day: IRecurringDay) => {
            if (recurrence.repeating_days & day.value) {
                activeDaysString.push(day.name)
            }
        })

        if (activeDaysString.length === 1) {
            return activeDaysString[0]
        }

        return oxford(activeDaysString, t('and'), '')
    }

    const removeRecurrence = (recurrenceId: number) => {
        recurrencesApi.del(recurrenceId)
            .then((deletedRecurrence) => {
                if (deletedRecurrence.id === recurrenceId) {
                    toast.success('Herhaling succesvol verwijderd')
                }

                // refetch all recurrences
                queryCache.invalidateQueries(['getRecurrences', customerPublicId])
            })

    }

    const hover = (dataId: number) => {
        const elTrash = document.querySelectorAll(`[data-id="${dataId}"]`)[0]

        if (!elTrash) {
            console.error('Could not find trash element with id trash-' + dataId)
            return
        }

        elTrash.classList.toggle('hover')
    }

    const toggleRecurrenceActive = (recurrence: IRecurrence) => {
        // TODO check for sufficient time/strips when you want to enable
        console.log(`Toggle activity for recurrence ${recurrence.id}`)
    }

    const toggleUnitMailCustomer = (unit: IUnit | undefined) => {
        if (!unit) {
            return
        }

        units.toggleMailCustomer(unit.id)
            .then((responseUnit: IUnit) => {
                if (responseUnit.id === unit.id) {
                    toast.success('Wijziging doorgevoerd')
                }

                // refetch all recurrences
                queryCache.invalidateQueries(['getRecurrences', customerPublicId])
            })
    }

    // don't show anything when there are no recurrences
    if (!recurrences || recurrences.length < 1) {
        return <></>
    }

    return (
        <Card className={`recurrences ` + className}>
            <Card.Header style={{backgroundColor: "white"}}>
                <Card.Title className="mb-0">{t('Recurrences')}</Card.Title>
                <small>
                    {t('These are recurrent bookings')}.<br/>
                </small>
            </Card.Header>
            <Card.Body>
                <ul>
                    {recurrences.map((recurrence: IRecurrence, index: number) => (
                        <li key={index}>
                            <span>
                                {recurrence.unit?.amount} {stripSingularOrPlural(recurrence.unit?.amount)}&nbsp;
                                {t('every')} {recurrence.repeating_timeframe === 'day' ? t('day') : 'week'}&nbsp;
                                {t('on')} {activeDays(recurrence)}&nbsp;
                                {/*{t('timeAt')} {moment(recurrence.unit?.created).format('HH:mm')}*/}
                                <br/>
                                {recurrence.last_executed && `${t('Last time')}: ${moment(recurrence.last_executed).calendar()}`}
                                <br/>
                                {t('Next time')}: {moment(recurrence.next_date || recurrence.start_date).calendar()}
                            </span>

                            <br/>

                            <p>
                                {t('Description')}: {recurrence.unit?.description || t('none')}
                            </p>

                            <span
                                data-tip={recurrence.active ? t('Recurrence active. Click to disable recurrence') : t('Recurrence disabled. Click to enable recurrence')}
                                onClick={() => toggleRecurrenceActive(recurrence)}
                            >
                                <Clock data-id={recurrence.id} className={`active ${recurrence.active ? 'enabled' : 'disabled'}`}/>
                            </span>

                            <span
                                onClick={() => toggleUnitMailCustomer(recurrence.unit)}
                            >
                                <Mail
                                    data-id={recurrence.unit?.id}
                                    className={`${recurrence.unit?.notify_by_email && 'active'} mail`}
                                    data-tip={recurrence.unit?.notify_by_email ? t('Customer will be mailed when the time or strip is booked off') : t('Customer will NOT be' +
                                        ' mailed when the time or strip is booked off')}
                                />
                            </span>

                            <span
                                data-tip={t('Remove this recurrence')}
                                onClick={() => removeRecurrence(recurrence.id)}
                                onMouseEnter={() => hover(recurrence.id)}
                                onMouseLeave={() => hover(recurrence.id)}
                            >
                                <Trash2 data-id={recurrence.id} className='trash'/>
                            </span>
                        </li>
                    ))}
                </ul>
            </Card.Body>
        </Card>
    )
};

export default Recurrences;
