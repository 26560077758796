import React, {useState} from 'react';
import {Button, Card, Spinner } from "react-bootstrap";
import {useTranslation} from "react-i18next";
import MollieIntegration from "./MollieIntegration";
import {useLoading} from "../../../utils/helpers";
import {IUserSettings} from "../../../interfaces/IUserSettings";
import {users} from "../../../api/users";
import {toast} from "react-toastify";
import persistentStore from "../../../stores/persistentStore";

const IntegrationsTab = () => {
    const {t} = useTranslation()
    const [loading, startLoading, stopLoading] = useLoading()

    const [togglTestKey, setTogglTestKey] = useState<IUserSettings>(persistentStore.user?.settings?.togglTestKey)
    const [togglLiveKey, setTogglLiveKey] = useState<IUserSettings>(persistentStore.user?.settings?.togglLiveKey)

    const [mollieTestKey, setMollieTestKey] = useState<IUserSettings>(persistentStore.user?.settings?.mollieTestKey)
    const [mollieLiveKey, setMollieLiveKey] = useState<IUserSettings>(persistentStore.user?.settings?.mollieLiveKey)

    // when the form is submitted
    const handleSubmitSettings = (event: any) => {
        event.preventDefault()

        if (!persistentStore.user) {
            return
        }

        startLoading()

        const updatedSettings: IUserSettings = Object.assign(
            persistentStore.user.settings,
            {togglTestKey: togglTestKey, togglLiveKey: togglLiveKey,
             mollieTestKey: mollieTestKey, mollieLiveKey: mollieLiveKey}
        )

        users.updateSettings(updatedSettings, persistentStore.user.id)
            .then(response => {
                if (response.success) {
                    toast.success(t('Integrations updated'))
                } else {
                    toast.error(t('Integrations could not be updated'))
                }
            })
            .catch((error) => {
                if(error.response){
                    const errors = error.response.data.errors
                    toast.error(errors.detail)
                    return
                }

                console.error(error)
            })
            .finally(() => {
                stopLoading()
            })
    }

    const SetInputValue= (event:any) => {
        if(event.name === 'toggl-key-test'){
           return setTogglTestKey(event.value);
        }

        if(event.name === 'toggl-key-live'){
            return setTogglLiveKey(event.value);
        }

        if(event.name === 'mollie-key-test'){
            return setMollieTestKey(event.value);
        }

        if(event.name === 'mollie-key-live'){
            return setMollieLiveKey(event.value);
        }
    }

    return (
        <form onSubmit={handleSubmitSettings}>
            <Card className="mt-4 mb-5">
                <Card.Body>
                    {/*<TogglIntegration setInputValue={SetInputValue} testKey={togglTestKey} liveKey={togglLiveKey} />*/}

                    <MollieIntegration setInputValue={SetInputValue} testKey={mollieTestKey} liveKey={mollieLiveKey} />
                </Card.Body>
                <Card.Footer>
                    <Button
                        type="submit"
                        size='lg'
                        id='integrations-update'
                        className="btn btn-primary"
                        disabled={loading}
                    >
                        {loading &&
                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>}
                        {!loading && t('Submit')}
                    </Button>
                </Card.Footer>
            </Card>
        </form>
    )
}

export default IntegrationsTab;
