import Spinner from 'react-feather/dist/icons/settings';
import React from 'react'
import {Row, Col, Card} from "react-bootstrap";
import {DollarSign} from 'react-feather';
import {secondsToTime} from "../../utils/helpers";
import persistentStore from "../../stores/persistentStore";
import {ICustomer} from "../../interfaces/ICustomer";

type Props = {
    customers: ICustomer[],
    loading: boolean,
    title: string
}

export const NumberOfStripsLeft = (props: Props) => {
    const {customers, loading, title} = props
    const useTime = (persistentStore.user?.settings.useType === 'hours')

    const left: number = customers.reduce((carry: number, customer: ICustomer) => {
        const getBooked = useTime
            ? customer.time_remaining
            : customer.strips_remaining
        carry += getBooked
        return carry
    }, 0)

    return (
        <Card
            bg='light'
            text='dark'
            className="mb-2"
        >
            <Card.Body className="customers_text_center">
                <Row>
                    <Col md={12}>
                        <Card.Title className="mb-1">{title}</Card.Title>
                    </Col>
                    <Col md={6} className="pr-0 m-auto">
                        <h3>
                        {
                            loading
                                ? <Spinner className="spinner"/>
                                : useTime ? secondsToTime(left) : left
                        }
                        </h3>
                    </Col>
                    <Col md={6} className='m-auto text-center'>
                        <DollarSign className="customer-d-icon"/>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}
