import React from 'react';

class IntegrationsPage extends React.Component
{
    componentDidMount = () => {
        // ReactGA.pageview("/integrations");
    };

    render()
    {
        return (
            <React.Fragment>
                <h1>Integraties</h1>
                <div>
                    In de toekomst kun je hier de integraties verwachten
                </div>
            </React.Fragment>
        )
    }
}

export default IntegrationsPage
