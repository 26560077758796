import React from 'react';
import {Route, Switch} from "./utils/router";
import Customer from "./pages/Customers/Customer";
import PricingPage from "./pages/Pricing/pricing";
import TrialPage from "./pages/Trial/TrialPage";
import RegistrationSuccess from "./pages/Users/RegistrationSuccess";
import App from "./App";
import OrderPage from "./pages/Orders/OrderPage";
import OrderProcessing from "./pages/OrderProcessing/OrderProcessing";
import InvoicePage from "./pages/Invoice/InvoicePage";
import ClearCache from "./pages/ClearCache/ClearCache";
import FeatureToggle from "./components/FeatureToggle";

const Routes = () => {
    return (
        <Switch>
            <Route exact path='/clear-cache' component={ClearCache}/>
            <Route path='/status' component={Customer}/>
            {/*<Route exact path='/open' component={Open}/>*/}
            {/*<Route exact path="/register" component={Register}/>*/}
            <Route exact path="/pricing" component={PricingPage}/>
            <Route exact path="/trial" component={TrialPage}/>
            {/*<Route exact path="/purchase/:productId" component={PurchasePage}/>*/}
            <Route path="/order/:saleSlug/:cardtypeSlug" component={OrderPage}/>
            <Route path="/order/:saleSlug" component={OrderPage}/>
            <Route exact path="/orderprocessing/:order_public_id" component={OrderProcessing}/>
            <Route exact path="/registratie-success" component={RegistrationSuccess}/>
            <Route exact path='/invoice/:public_id' component={InvoicePage}/>
            <Route exact path='/feature/:featureName/:featureStatus' component={FeatureToggle}/>
            <Route component={App}/>
        </Switch>
    )
}

export default Routes;
