import {users} from "../api/users";

export const clearCache = () => {
    users.event('CLEAR_CACHE')
    if ('serviceWorker' in navigator) {
        caches.keys().then(function (cacheNames) {
            cacheNames.forEach(function (cacheName) {
                console.log(`Deleting cache: ${cacheName}`)
                caches.delete(cacheName);
            });
        });
    }
    window.location.reload()
}

export const clearCacheAsync = async () => {
    return new Promise((resolve, reject) => {
        users.event('CLEAR_CACHE')
        if ('serviceWorker' in navigator) {
            caches.keys().then(function (cacheNames) {
                cacheNames.forEach(function (cacheName) {
                    console.log(`Deleting cache: ${cacheName}`)
                    caches.delete(cacheName);
                });
            });
        }

        resolve('cache cleared')
    })
}
