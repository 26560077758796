import React from 'react';
import {Link} from "react-router-dom";
import persistentStore from "../stores/persistentStore";
import {useTranslation} from "react-i18next";
import featureToggleStore from "../stores/featureToggleStore";
import {MessageSquare} from "react-feather";

type HeaderMobileMenuProps = {
    userLink?: JSX.Element
}

const HeaderMobileMenu = (props: HeaderMobileMenuProps) => {
    const {t} = useTranslation()

    const startChat = () => {
        console.log('firing up the chat engine')
        // @ts-ignore
        if(window.Tawk_API){
            // @ts-ignore
            window.Tawk_API.popup()
        }
    }

    return (
        <div className="collapse navbar-collapse" id="mainMenu">
            <ul className="navbar-nav hide-mobile mr-auto mt-2 mobile">
                <li className="nav-item">
                    <Link to="/" className="nav-link">
                        {t('Dashboard')}
                    </Link>
                </li>

                {persistentStore.isAdmin && (
                    <li className="nav-item">
                        <Link to={'/users'} className="nav-link">
                            {t('Users')}
                        </Link>
                    </li>

                )}

                <li className="nav-item">
                    <Link to={'/customers'} className="nav-link">
                        {t('Customers')}
                    </Link>
                </li>

                <li className="nav-item">
                    <Link to={'/config'} className="nav-link ">
                        {t('Configuration')}
                    </Link>
                </li>

                {featureToggleStore.chat && (
                    <li className='nav-item'>
                        <Link
                            to={'#'}
                            className='nav-link'
                            id='help-link'
                            onClick={startChat}
                        >
                            <MessageSquare className="feather mr-2"/>
                            Chat
                        </Link>
                    </li>
                )}

                <li className="nav-item mt-4">
                    {props.userLink}
                </li>
            </ul>
        </div>
    )
}

export default HeaderMobileMenu;
