import React, {useEffect, useState} from 'react';
import {Tabs, Tab} from "react-bootstrap";
import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import SweetAlert from "react-bootstrap-sweetalert/dist";
import GeneralTab from "./General/GeneralTab";
import SettingsTab from "./Settings/SettingsTab";
import SecurityTab from "./Security/SecurityTab";
import {users} from "../../api/users";
import {toast} from "react-toastify";
import './Config.scss'
import {useRouter} from "../../utils/router";
import CommunicationTab from "./Communication/CommunicationTab";
import IntegrationsTab from "./Integrations/IntegrationsTab";
import InvoicesTab from "./Invoices/InvoicesTab";
import persistentStore from "../../stores/persistentStore";

const Config = () => {
    const {t} = useTranslation()
    const [key, setKey] = useState('general')
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const router = useRouter()

    useEffect(() => {
        if(router.query.tab){
            setKey(router.query.tab)
        }
    }, [router.query.tab])

    const deleteAccount = () => {
        if(! persistentStore.user){
            return
        }

        users.del(persistentStore.user.id)
            .then(response => {
                toast.success(t('Your account is successfully removed'))
                setShowDeleteModal(false)
                users.logout()
                window.location.href = window.location.origin + '/login'
            })
            .catch(error => {
                console.error('An error occurred while removing your account: ', error)
            })
    }

    return (
        <div className='Config'>
            <h1>{t('Configuration')}</h1>

            <div className='mb-5'>
                Hier staan de instellingen waarmee je de Strippenkaart App kunt aanpassen naar je wensen.
            </div>

            <Tabs
                  activeKey={key}
                  onSelect={(k) => {
                      setKey(k || 'general')
                      router.push(`/config/${k}`)
                  }}
            >
                <Tab key='general' eventKey="general" title={t('General')}>
                    <GeneralTab/>
                </Tab>

                <Tab key='communication' eventKey="communication" title={t('Communication')}>
                    <CommunicationTab />
                </Tab>

                <Tab key='settings' eventKey="settings" title={t('Settings')}>
                    <SettingsTab/>
                </Tab>

                <Tab key='integrations' eventKey="integrations" title={t('Integrations')}>
                    <IntegrationsTab />
                </Tab>

                <Tab key='invoices' eventKey="Invoices" title={t('Invoices')}>
                    <InvoicesTab />
                </Tab>

                <Tab key='security' eventKey="Security" title={t('Danger zone')}>
                    <SecurityTab setShowDeleteModal={setShowDeleteModal}/>
                </Tab>
            </Tabs>

            {showDeleteModal && (
                <SweetAlert
                    warning
                    showCancel
                    allowEscape={true}
                    closeOnClickOutside={true}
                    confirmBtnText={t("I'm sure, delete my account")}
                    cancelBtnText={t("Cancel")}
                    confirmBtnBsStyle="danger"
                    title={t('Are you sure you want to delete your account?')}
                    onConfirm={() => deleteAccount()}
                    onCancel={() => setShowDeleteModal(false)}
                    focusCancelBtn
                >
                    <p>{t('When you delete your account, ALL data will be removed')}</p>
                    <p>{t('This action cannot be undone')}</p>
                </SweetAlert>
            )}
        </div>
    );
}

export default observer(Config)
