import Moment from 'react-moment'
import React, {useContext, useState} from 'react'
import {getSlug, secondsToTime} from '../../utils/helpers'
import {units} from '../../api/units'
import {Col, Row} from 'react-bootstrap'
import {Trash2, Edit2} from 'react-feather'
import {toast} from 'react-toastify'
import './UnitLine.scss'
import store from "../../stores/store";
import {useQueryCache} from "react-query";
import {IUnit} from "../../interfaces/IUnit";
import {ICustomer} from "../../interfaces/ICustomer";
import UseTimeContext from "../../contexts/UseTimeContext";
import {useHistory} from "../../utils/router";

type UnitLineProps = {
    description?: string,
    unit: IUnit,
    unitId: number,
    customer?:ICustomer
}

/**
 * Contains one line in a strippenkaart. This line is work that is done / units that are booked off of the
 * strippenkaart
 *
 * @param props
 * @return {*}
 * @constructor
 */
const UnitLine = (props: UnitLineProps) => {
    const fullDescription = props.description == null ? '-' : props.description
    const [isHoveredOver, setHovered] = React.useState(false)
    const [hide, setHide] = useState(false)
    const {unitId, unit, customer} = props
    const queryCache = useQueryCache()
    const useTime = useContext(UseTimeContext)
    const history = useHistory()
    const slug = getSlug()
    const isCustomerView = slug.startsWith('/status/')

    const creationDate = (unit.created === '')
        ? ''
        : <Moment date={unit.created} format="D MMMM YYYY HH:mm"/>

    const setHoveredWithCheck = () => {
        // do nothing when we're in the customer view
        if (isCustomerView) {
            return
        }

        setHovered(!isHoveredOver)
    }

    const removeUnit = (unitIdToRemove: number) => {
        store.triggerEvent('unit_removed')

        // remove the UnitLine element from the DOM so the user sees immediate result
        setHide(true)

        units.del(unitIdToRemove)
            .then((deletedUnit) => {
                if (deletedUnit.id === unitIdToRemove) {
                    toast.success('Strip succesvol verwijderd')
                }

                // refetch all customers
                queryCache.invalidateQueries('getCustomer')
            })
    }

    const amount = useTime
        ? secondsToTime(unit.time_in_seconds)
        : unit.amount

    if(hide){
        return <></>
    }

    return (
        <div
            className={`UnitLine unitline ${isHoveredOver ? 'hovered' : ''}`}
            id={String(unitId || 0)}
            onMouseEnter={setHoveredWithCheck}
            onMouseLeave={setHoveredWithCheck}
        >
            <Row className="d-flex">
                <div className="customer col-10">
                    <h5 className="unit-title">{fullDescription}</h5>
                </div>
                <div className="col-2 text-right">
                    <div className='unit-amount'>{amount}</div>
                </div>
            </Row>

            <Row>
                <Col sm={10} className='unit-creation-date'>
                    {creationDate}
                </Col>
                {
                    ! isCustomerView && (
                    <>
                        <Col sm={1} className={`remove-unit`}>
                            <span onClick={() => removeUnit(unitId)}>
                                <Trash2 className={`delete ${isHoveredOver ? 'hover' : ''}`}/>
                                </span>
                        </Col>
                        {customer && (
                            <Col sm={1} className='edit-unit'>
                                <span
                                    onClick={() => history.push(`/units/edit/${customer.public_id}/${unitId}`)} data-unitid={unitId}>
                                    <Edit2 className={`edit ${isHoveredOver ? 'hover' : ''}`} />
                                </span>
                            </Col>
                        )}
                    </>)
                }
            </Row>
        </div>
    )
}

export default UnitLine
