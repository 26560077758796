import React, {useEffect, useState} from 'react';
import {Button, Modal, Spinner} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {ICardType} from "../../interfaces/ICardType";
import Col from "react-bootstrap/Col";
import {queryCache, useMutation} from "react-query";
import {cardtypes} from "../../api/cardtypes";
import {toast} from "react-toastify";
import store from "../../stores/store";

type EditCardTypeModelType = {
    card: ICardType,
    shouldShow: boolean,
    hide: () => void,
    loading: boolean,
}

const EditCardTypeModal = (props: EditCardTypeModelType) => {
    const {t} = useTranslation()
    const [name, setName] = useState(props.card.name)
    const [defaultQuantity, setDefaultQuantity] = useState<number>(props.card.default_quantity)
    const [typedPrice, setTypedPrice] = useState<string>((props.card.price_in_cents / 100).toLocaleString(store.locale))
    const [priceInCents, setPriceInCents] = useState<number>(props.card.price_in_cents)
    const [description, setDescription] = useState(props.card.description)
    const [expires, setExpires] = useState(props.card.validity_time)
    const [hasCustomValidity, setHasCustomValidity] = useState<boolean>(false)
    const [durationQuantity, setDurationQuantity] = useState<number>(1)
    const [durationUnit, setDurationUnit] = useState<string>('years')

    const [updateCardTypeMutation] = useMutation(
        (updatedCardType: ICardType) => cardtypes.edit(updatedCardType),
        {
            onSettled: () => queryCache.invalidateQueries('cardtypes'),
            onError: () => {
                toast.success(t('Error while updating card'))
            },
            onSuccess: () => {
                toast.success(t('Card updated'))
                props.hide()
            }
        }
    )

    useEffect(() => {
        const expirationPresets = [
            "0",
            "3 weeks",
            "12 weeks",
            "1 months",
            "3 months",
            "6 months",
            "1 years"
        ]

        if(! expirationPresets.includes(expires) && expires !== 'Other'){
            const choppedExpiration = expires.split(' ')
            setExpires('Other')
            setHasCustomValidity(true)
            setDurationQuantity(parseInt(choppedExpiration[0], 10))
            setDurationUnit(choppedExpiration[1])
        }
    }, [props.card, expires])

    const checkNumber = (event: any) => {
        if (event.name === 'defaultQuantity') {
            setDefaultQuantity((Number(event.value) ? event.value : ''))
        }
    }

    const changePrice = (value: string) => {
        // store whatever is typed
        setTypedPrice(value)

        // now, turn the madness that is typed into an understandable price

        // change comma to point
        const correctDecimals = value
            .replace(',', '.')
            .replace(' ', '')

        // filthy hack: add a 9 when there are two decimals. Otherwise: javascript will deduct 0.01 from it :-|
        const positionOfDecimal = correctDecimals.indexOf('.')
        const decimalAmount = correctDecimals.substr(positionOfDecimal).length
        const floatValue = decimalAmount <= 2
            ? parseFloat(correctDecimals)
            : parseFloat(correctDecimals + '9')
        const intValue = parseInt((floatValue * 100).toString(), 10)
        setPriceInCents(intValue)
    }

    const editCard = (event: any) => {
        event.preventDefault()

        const validity_time = hasCustomValidity ? `${durationQuantity} ${durationUnit}` : expires

        const updatedCard: ICardType = Object.assign(
            {},
            props.card,
            {
                name: name,
                default_quantity: defaultQuantity,
                price_in_cents: priceInCents,
                validity_time: validity_time,
                description: description,
                has_custom_validity: hasCustomValidity,
            }
        )

        updateCardTypeMutation(updatedCard)
    }

    const handleExpires = (event: any) => {
        setExpires(event.target.value)
        setHasCustomValidity((event.target.value === 'Other'))
    }

    return (
        <Modal id='edit-cardtype' show={props.shouldShow} onHide={props.hide}>
            <form onSubmit={editCard}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('Edit card')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group">
                        <label htmlFor="inputName">{t('Name of this type of card')}</label>
                        <input
                            type="text"
                            className="form-control"
                            id="inputName"
                            placeholder='Bijv: 10-rittenkaart'
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            name="name"
                            required
                        />
                        <small>Dit is de naam die de klant bij de kaart ziet op de verkooppagina.</small>
                    </div>

                    <div className="form-group">
                        <label htmlFor="inputDefaultQuantity">{t('Default quantity')}</label>
                        <input
                            type="text"
                            className="form-control"
                            id="inputDefaultQuantity"
                            placeholder={'10'}
                            value={defaultQuantity}
                            onChange={(e) => checkNumber(e.target)}
                            name="defaultQuantity"
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="inputPriceInCents">{t('Price in cents (inc VAT)')}</label>
                        <input
                            type="text"
                            className="form-control"
                            id="inputPriceInCents"
                            placeholder={t('Price in cents (inc VAT)')}
                            // value={(priceInCents / 100).toStringLocale(store.locale)}
                            value={typedPrice}
                            onChange={(e) => changePrice(e.target.value)}
                            name="priceInCents"
                            required
                        />
                        <small>Op de factuur wordt de BTW bijgevoegd</small>
                    </div>

                    <div className="form-group">
                        <label htmlFor="inputDuedate">Geldigheidsduur: hoe lang zijn de strippen geldig?</label>
                        <select
                            id="inputDueDate"
                            className="form-control"
                            name="geldigheidsduur"
                            value={expires}
                            onChange={(event) => handleExpires(event)}
                        >
                            <option value="0">{t('unlimited')}</option>
                            <option value="3 weeks">3 {t('weeks')}</option>
                            <option value="12 weeks">12 {t('weeks')}</option>
                            <option value="1 months">1 {t('month')}</option>
                            <option value="3 months">3 {t('months')}</option>
                            <option value="6 months">6 {t('months')}</option>
                            <option value="1 years">1 {t('year')}</option>
                            <option value="Other">{t('other')}...</option>
                        </select>
                    </div>

                    {hasCustomValidity && (
                        <div className="form-row">
                            <Col sm={12}>
                                <label htmlFor="inputCustomDuration">{t('Custom duration')}</label>
                            </Col>
                            <Col>
                                <input
                                    type="number"
                                    min={1}
                                    step='any'
                                    className="form-control"
                                    id="inputCustomDuration"
                                    placeholder="1"
                                    value={durationQuantity}
                                    onChange={(e) => setDurationQuantity(parseInt(e.target.value, 10))}
                                    name="customDuration"
                                    required
                                />
                            </Col>

                            <Col>
                                <select
                                    id="inputValidityType"
                                    className="form-control"
                                    name="geldigheidsduur"
                                    value={durationUnit}
                                    onChange={(event) => setDurationUnit(event.target.value)}
                                >
                                    <option value="days">{t('days')}</option>
                                    <option value="weeks">{t('weeks')}</option>
                                    <option value="months">{t('months')}</option>
                                    <option value="years">{t('year')}</option>
                                </select>
                            </Col>
                        </div>
                    )}

                    <div className="form-group mt-2">
                        <label htmlFor="inputDescription">{t('Description')}</label>
                        <textarea
                            className='form-control'
                            id="inputDescription"
                            name='description'
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                        <small>De beschrijving is te zien op de verkooppagina van de kaarten</small>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="link" id='cancel-edit' className="text-danger" onClick={() => props.hide()}>
                        {t('Cancel')}
                    </Button>

                    <Button
                        type="submit"
                        size='lg'
                        id='submit-cardtype-edits'
                        variant="primary"
                        className="mr-1"
                        disabled={props.loading}
                    >
                        {props.loading &&
                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>}
                        {!props.loading && t('Save')}
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>

    )
}

export default EditCardTypeModal;
