import {moment, secondsToTime, getSlug} from "../../utils/helpers";
import {Link} from "react-router-dom";
import React, {useContext} from "react";
import GearIcon from "react-feather/dist/icons/settings";
import {getDaysUntilExpiration, stripSingularOrPlural} from "../../utils/card";
import { observer } from 'mobx-react'
import { Col } from 'react-bootstrap'
import persistentStore from "../../stores/persistentStore";
import {ICard} from "../../interfaces/ICard";
import UseTimeContext from "../../contexts/UseTimeContext";

type CardHeaderProps = {
    card: ICard,
    className?: string
}

/**
 *
 * @param props
 * @return {*}
 * @constructor
 */
const CardHeader = (props: CardHeaderProps) => {
    const {card}              = props
    const daysUntilExpiration = getDaysUntilExpiration(card.duedate);
    const isActive            = card.duedate === null ? true : (daysUntilExpiration > 0);
    const expiredMessage      = isActive ? null : "Verlopen op";
    const willExpiredMessage  = card.duedate === null ? 'Onbeperkt' : 'nog ' + daysUntilExpiration;
    const slug = getSlug();
    const isCustomerView = slug.startsWith('/status/');
    const useTime = useContext(UseTimeContext)

    let grootheid = 'geldig';

    if(daysUntilExpiration === 1){
        grootheid = 'dag geldig';
    }
    else if(daysUntilExpiration >0){
        grootheid = 'dagen geldig';
    }
    else if(!isActive){
        grootheid = moment(card.duedate).format('dd D MMM YYYY');
    }

    const amountOnCard = useTime
        ? secondsToTime(card.time_in_seconds)
        : card.amount

    const amountOnCardLeft = useTime
        ? secondsToTime(parseInt((card.totalUnitsLeft || '0'),10))
        : card.totalUnitsLeft

    return (
        <div className={`card-header row ${props.className ? props.className : ''}`} id={String(card.id)} >

            <Col xs={4} sm={4}>
                <div className="total text-center card-amount">{amountOnCard}</div>
                <div className="left text-center">{stripSingularOrPlural(card.amount)} </div>
            </Col>

            <Col xs={4} sm={4}>
                { isActive && <div className="text-center">{willExpiredMessage}</div>}
                { !isActive && <div className="text-center">{expiredMessage }</div>}
                <div className="text-center">{grootheid}</div>
            </Col>

            <Col xs={4} sm={4} className={(isActive ? '' : 'expired')}>
                <div className="customer text-center units-left">{amountOnCardLeft}</div>
                <div className="label-left text-center">{stripSingularOrPlural(parseInt((card.totalUnitsLeft||'0'), 10))} over</div>
            </Col>

            {  persistentStore.loggedIn && ! isCustomerView &&
            <Link to={`/cards/edit/${card.id}`} className="card-settings-button">
                <GearIcon/>
            </Link>
            }
        </div>
    );
};
export default observer(CardHeader);
