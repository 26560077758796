import axios from 'axios'
import errorChecker from './errorchecker'
import persistentStore from "../stores/persistentStore";
import { ISubscription } from '../interfaces/ISubscription';

const subscriptionsRequests = () => {
    const get = async (subscriptionId:string) => {
        const path = `/subscription/`;
        const config = persistentStore.authHeader;
    
        return axios.get(persistentStore.apiUrl + path, config).then(
            response => errorChecker(response)).then(response => {
          const data = response.data.data;
          if (!data.subscription) {
            throw new Error('The response did not contain subscriptions');
          }
    
          
          return data.subscriptions;
        });
      };
      
    const add = async (subscription:ISubscription) => {
        const path = `/subscriptions/`;
        const data = subscription;
        const config = persistentStore.authHeader;
    
        return axios.post(persistentStore.apiUrl + path, data, config).then(
            (response) => {
              if (response.request.status !== 400) {
                return response;
              }
    
              return JSON.parse(response.request.response);
            }).then(response => errorChecker(response)).then(response => {
            const data = response.data.data;
            if (!data.subscription) {
                console.log('The response did not contain the created subscription');
                return response.data;
            }
            return data.subscription;
        });
      };
    
      const view = async (subscriptionId:string) => {
        const path = `/subscription/${subscriptionId}/`;
        const config = persistentStore.authHeader;
    
        return axios.get(persistentStore.apiUrl + path, config).then(
            response => errorChecker(response)).then(response => {
          const data = response.data.data;
          if (!data.subscription) {
            throw new Error('The response did not contain subscription');
          }
    
          
          return data.subscription;
        });
      };
    
      const update = async (subscription:ISubscription) => {
        const path = `/subscriptions/${subscription.id}/`;
        const config = persistentStore.authHeader;
    
        return axios.put(persistentStore.apiUrl + path, subscription, config).then(
            response => errorChecker(response)).then(response => {
            
            const data = response.data;
            if (!data.data.subscription) {
                console.log('The response did not contain the created subscription');
                return response.data;
            }
            return data;
        });
      };
    
      const del = async (subscriptionId:string) => {
        const path = `/subscriptions/${subscriptionId}`;
        const config = persistentStore.authHeader;
    
        return axios.delete(persistentStore.apiUrl + path, config).then(
            response => errorChecker(response)).then(response => {
          return response.data;
        });
      };
    
      
    return {
        get,
        add,
        view,
        update,
        del
    }
}


export const subscriptions = subscriptionsRequests()