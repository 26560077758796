import axios from 'axios'
import errorChecker from './errorchecker'
import persistentStore from "../stores/persistentStore";

const mailRequests = () => {

    const getByCustomerPublicId = async (customerPublicId: string) => {
        const path = `/mails/from-customer/${customerPublicId}`

        return axios.get(persistentStore.apiUrl + path, persistentStore.authHeader)
            .then(response => errorChecker(response))
            .then(response => {
                return response.data.data.mails
            })
    };

    return {
        getByCustomerPublicId
    }
}

export const mails = mailRequests()
