import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import "./Pricing.scss";
import {useTranslation} from "react-i18next";
import {Check as CheckIcon} from "react-feather";
import store from "../../stores/store";

function Pricing(props) {
    const {t} = useTranslation()

    return (
        <Row className="justify-content-center">
            {props.items.map((item, index) => (
                <Col
                    md={12}
                    lg={4}
                    className="py-3 d-flex align-items-stretch"
                    key={index}
                >
                    <Card className="w-100">
                        <Card.Body className="d-flex flex-column p-4">
                            <h5 className="font-weight-bold mb-4">{item.name}</h5>
                            <h1 className="font-weight-bold mb-3">
                                €{item.price}
                                <small
                                    className="Pricing__period">{item.period === 'month' ? t('/mo') : t('/yr')}</small>
                            </h1>

                            {item.description && (
                                <Card.Text className="mb-4">{item.description}</Card.Text>
                            )}

                            {item.perks && (
                                <Card.Text className="mt-2">
                                    <ul className="list-unstyled">
                                        {item.perks.map((perk, index) => (
                                            <li className="py-1" key={index}>
                                                <CheckIcon className='text-success mr-3' />
                                                {perk}
                                            </li>
                                        ))}
                                    </ul>
                                    {item.description}
                                </Card.Text>
                            )}

                            <a
                                href={'https://pauledenburg.plugandpay.nl/checkout/strippenkaart?suggested-plan=' + item.id}
                            >
                                <Button
                                    variant="primary"
                                    size="lg"
                                    block={true}
                                    className="mt-auto"
                                    onClick={() => {
                                        store.triggerEvent(`clicked_button_pricing_${item.id}`)
                                    }}
                                >
                                    {props.buttonText}
                                </Button>
                            </a>
                        </Card.Body>
                    </Card>
                </Col>
            ))}
        </Row>
    );
}

export default Pricing;
