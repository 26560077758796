import React from "react";
import PricingSection from "./PricingSection";
import {useTranslation} from "react-i18next";

function PricingPage(props) {
    const { t } = useTranslation()

    return (
        <PricingSection
            bg="white"
            textColor="dark"
            size="md"
            bgImage=""
            bgImageOpacity={1}
            title={t('Registering for the Strippenkaart App')}
            subtitle={t('Do you want to get going with the Strippenkaart App? Then choose your best fit below')}
        />
    );
}

export default PricingPage;

