import React, {FormEvent, useEffect, useState} from 'react';
import {Button, Card, Spinner, Row, Col} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {users} from '../../../api/users';
import {toast} from 'react-toastify';
import ColorPicker from 'react-pick-color';
import persistentStore from '../../../stores/persistentStore';
import {IUserSettings} from "../../../interfaces/IUserSettings";

const ChangeColor = () => {
    const {t} = useTranslation();
    const [primaryColor, setPrimaryColor] = useState(persistentStore.user?.settings?.primaryColor);
    const [secondaryColor, setSecondaryColor] = useState(persistentStore.user?.settings?.secondryColor);
    const [loading, setLoading] = useState(false);
    const [settings, setSettings] = useState<IUserSettings>()

    useEffect(() => {
        if(persistentStore.user){
            const updatedSettings = Object.assign(
                persistentStore.user.settings || {},
                {
                    primaryColor: primaryColor,
                    secondryColor: secondaryColor,
                },
            );

            setSettings(updatedSettings)
        }
    }, [primaryColor, secondaryColor])

    // when the form is submitted
    const handleSubmitSettings = (event: FormEvent) => {
        event.preventDefault();

        if(! settings){
            return
        }

        if (!persistentStore.user) {
            return;
        }

        setLoading(true)

        users.updateSettings(settings, persistentStore.user.id)
            .then(
                response => {
                    if (response.success) {
                        toast.success(t('Theme color updated'));
                    } else {
                        toast.error(t('Theme color could not be updated'));
                    }
                    setLoading(false)
                });
    };

    const resetColors = () => {
        setSecondaryColor('')
        setPrimaryColor('')
    }

    return (
        <form onSubmit={handleSubmitSettings}>
            <Card className="mt-4 mb-5">
                <Card.Body>
                    <h4>
                        {t('Change theme color')}
                        <Button
                            variant={'outline-info'}
                            className='ml-3'
                            onClick={resetColors}
                        >
                            {t('reset')}
                        </Button>
                    </h4>
                    <Row>
                        <Col>
                            <label htmlFor="primary_color">{t(
                                'Select Primary Color')}</label>
                            <p
                                id="primary_color"
                                className="form-control"
                                style={{backgroundColor: primaryColor}}
                            ></p>
                            <ColorPicker
                                color={primaryColor}
                                onChange={(color) => setPrimaryColor(color.hex)}
                            />
                        </Col>

                        <Col>
                            <label htmlFor="secondry_color">
                                {t('Select Secondary Color')}
                            </label>
                            <p
                                id="secondry_color"
                                className="form-control"
                                style={{backgroundColor: secondaryColor}}
                            ></p>
                            <ColorPicker
                                color={secondaryColor}
                                onChange={(color) => setSecondaryColor(color.hex)}
                            />
                        </Col>
                    </Row>

                </Card.Body>

                <Card.Footer>
                    <Button
                        type="submit"
                        size="lg"
                        id="color-update"
                        variant="primary"
                        disabled={loading}
                    >
                        {loading && (
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        )}
                        {!loading && t('Submit')}
                    </Button>
                </Card.Footer>
            </Card>
        </form>
    );
};

export default ChangeColor;
