import React from 'react';
import './ToolTip.scss'

type ToolTipProps = {
    children: any,
    text: string,
}

const ToolTip = (props: ToolTipProps) => {
    const {text, children} = props
    return (
        <div className="sk-tooltip">
            {children}
            <span className="sk-tooltiptext">{text}</span>
        </div>
    );
}

export default ToolTip;
