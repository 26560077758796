import axios from 'axios';
import errorChecker from './errorchecker';
import persistentStore from '../stores/persistentStore';
import {INewUnit, IUnit} from "../interfaces/IUnit";

const unitRequests = () => {

    const add = async (unit: INewUnit) => {
        const path = `/units`;
        const config = persistentStore.authHeader;
        const data = {
            amount: unit.amount,
            description: unit.description,
            customer_public_id: unit.customer_public_id,
            notify_by_email: unit.notify_by_email,
            creation_date: unit.creation_date,
            card_to_use: unit.card_to_use,
            recurrence: unit.recurrence,
        };

        return axios.post(persistentStore.apiUrl + path, data, config).then(
            response => errorChecker(response)).then(response => {
            const data = response.data.data;
            if (!data.units || data.units.length < 1) {
                throw Error('The response did not contain the created unit');
            }

            return data;
        });
    };

    const get = async (unitID: number) => {
        const path = `/units/${unitID}`;
        const config = persistentStore.authHeader;


        return axios.get(persistentStore.apiUrl + path, config).then(
            response => errorChecker(response)).then(response => {
            const data = response.data.data;
            if (!data.units || data.units.length < 1) {
                throw Error('The response did not contain the unit');
            }

            return data;
        });
    };

    const update = async (unitId:number, unit: INewUnit) => {
        const path = `/units/${unitId}`;
        const config = persistentStore.authHeader;
        const data = {
            amount: unit.amount,
            description: unit.description,
            customer_public_id: unit.customer_public_id,
            notify_by_email: unit.notify_by_email,
            creation_date: unit.creation_date,
            card_to_use: unit.card_to_use,
            recurrence: unit.recurrence,
        };

        return axios.put(persistentStore.apiUrl + path, data, config).then(
            response => errorChecker(response)).then(response => {
            const data = response.data.data;
            if (!data.units || data.units.length < 1) {
                throw Error('The response did not contain the created unit');
            }

            return data;
        });
    };

    const addMultiple = async (unit: IUnit) => {
        const path = `/units/addMultiple`;
        const config = persistentStore.authHeader;

        return axios.post(persistentStore.apiUrl + path, unit, config).then(
            response => errorChecker(response)).then(response => {

            return response;
        });
    };

    const del = async (unitId: number) => {
        const path = `/units/${unitId}`;
        const config = persistentStore.authHeader;

        return axios.delete(persistentStore.apiUrl + path, config).then(
            response => errorChecker(response)).then(response => {
            const unit = response.data.data.unit;

            if (!unit) {
                throw Error('The response did not contain the removed unit');
            }

            return unit;
        });
    };

    const getLastMonth = async () => {
        const path = `/units/get-last-month-units/`;
        const config = persistentStore.authHeader;

        return axios.get(persistentStore.apiUrl + path, config).then(
            response => errorChecker(response)).then(response => {
            const data = response.data.data;

            if (!data.units) {
                throw Error('The response did not contain units');
            }

            return data.units;
        });
    };

    const toggleMailCustomer = async (unitId: number) => {
        const path = `/units/toggle-mail-customer/${unitId}`;
        const config = persistentStore.authHeader;

        return axios.get(persistentStore.apiUrl + path, config).then(
            response => errorChecker(response)).then(response => {
            const data = response.data.data;

            if (!data.unit) {
                throw Error('The response did not contain the unit');
            }

            return data.unit;
        });
    };

    return {
        add,
        addMultiple,
        del,
        getLastMonth,
        toggleMailCustomer,
        update,
        get
    };
};

export const units = unitRequests();
