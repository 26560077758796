import axios from 'axios'
import store from '../stores/store'
import errorChecker from './errorchecker'
import persistentStore from "../stores/persistentStore";
import {ICardType} from "../interfaces/ICardType";


const cardTypeRequests = () => {

    const add = async (cardType: any) => {
        const path = `/card-types`
        const data = cardType

        return axios.post(persistentStore.apiUrl + path, data, persistentStore.authHeader)
            .then(response => errorChecker(response))
            .then(response => {
                if (!response.data.data.card_types) {
                    console.log('The response did not contain the card type');
                    throw Error('The response did not contain the card type');
                }
                store.setCardTypes(response.data.data.card_types)
                return response.data
            })
    }

    const del = async (cardId: string) => {
        const path = `/card-types/${cardId}`

        return axios.delete(persistentStore.apiUrl + path, persistentStore.authHeader)
            .then(response => errorChecker(response))
            .then(response => {
                store.setCardTypes(response.data.data.card_types)
                return response.data
            })
    }

    const edit = async (cardType: ICardType) => {

        const path = `/card-types/${cardType.id}`

        return axios.put(persistentStore.apiUrl + path, cardType, persistentStore.authHeader)
            .then(response => errorChecker(response))
            .then(response => {
                store.setCardTypes(response.data.data.card_types)
                return response.data
            })
    }

    const getFromSlug = async (cardtypeSlug: string) => {
        const path = `/card-types/get-from-slug/${cardtypeSlug}`

        return axios.get(persistentStore.apiUrl + path, persistentStore.authHeader)
            .then(response => errorChecker(response))
            .then(response => {
                return response.data.data
            })
    }

    const getFromSaleSlug = async (saleSlug: string) => {
        const path = `/card-types/get-from-sale-slug/${saleSlug}`

        return axios.get(persistentStore.apiUrl + path, persistentStore.authHeader)
            .then(response => errorChecker(response))
            .then(response => {
                return response.data.data
            })
    }

    const get = async (userIdOrSlug: string) => {
        const path = `/card-types/get-from/${userIdOrSlug}`

        return axios.get(persistentStore.apiUrl + path, persistentStore.authHeader)
            .then(response => errorChecker(response))
            .then(response => {
                return response.data.data
            })
    }

    return {
        add,
        del,
        edit,
        get,
        getFromSaleSlug,
        getFromSlug,
    }
}

export const cardtypes = cardTypeRequests()
