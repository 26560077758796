import React, {useEffect, useState} from 'react';
import DataTable from "react-data-table-component";
import Spinner from 'react-feather/dist/icons/settings';
import {Col} from "react-bootstrap";
import FilterComponent from "../../components/FilterComponent";
import persistentStore from "../../stores/persistentStore";
import {IUser} from "../../interfaces/IUser";
import store from "../../stores/store";
import {useRouter} from "../../utils/router";
import moment from "moment";
import {useTranslation} from "react-i18next";

const UserTable = (props: any) => {
    const [filterText, setFilterText] = React.useState('');
    const [filteredUsers, setFilteredUsers] = useState([])
    const users = props.users
    const router = useRouter()
    const {t} = useTranslation()

    // filter the users by the search query used
    useEffect(() => {
        if(users.data?.length > 0){
            const localFilteredUsers = users.data.filter((item: IUser) => {
                if(filterText === ''){
                    return true
                }
                const data = JSON.stringify(item).toLowerCase()
                const query = filterText.toLowerCase()

                return data && data.includes(query)
            });

            setFilteredUsers(localFilteredUsers)
        }
    }, [users.data, filterText])

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                // setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return <FilterComponent onFilter={(e: any) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
    }, [filterText]);

    const onRowClicked = (user: IUser) => {
        store.setUserView(user)
        router.push(`/users/${user.id}`)
    }

    const columns = [
        {
            name: 'Type',
            sortable: false,
            cell: (user: IUser) => user.type,
            width: '120px',
        },
        {
            name: 'Name',
            selector: 'name',
            sortable: true,
            width: '180px',
        },
        {
            name: 'Email',
            selector: 'email',
            sortable: true,
        },
        {
            name: t('Requests'),
            sortable: true,
            selector: 'requests_this_month',
        },
        {
            name: 'Last Seen',
            selector: 'last_seen',
            sortable: true,
            cell: (user: IUser) => {
                return user.last_seen
                    ? `${moment(user.last_seen).fromNow()}`
                    : ''
            },
        },
    ]

    if(users.isLoading){
        return (
            <Col md={12}>
                <Spinner className="spinner"/>
            </Col>
        )
    }

    return (
        <DataTable
            columns={columns}
            data={filteredUsers}
            pagination
            paginationPerPage={persistentStore.pageLength}
            paginationRowsPerPageOptions={persistentStore.pageLengthOptions}
            onChangeRowsPerPage={(rowCount) => persistentStore.setPageLength(rowCount)}
            noHeader
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            persistTableHead
            progressPending={users.isLoading}
            highlightOnHover={true}
            pointerOnHover={true}
            onRowClicked={(event) => onRowClicked(event)}
            defaultSortField='last_seen'
            defaultSortAsc={false}
        />
    );
}

export default UserTable;
