import React, {useEffect, useRef} from 'react';
import {Alert, Button, Modal, Spinner} from "react-bootstrap";
import {useTranslation} from "react-i18next";

type ModalAddGroupProps = {
    show: boolean,
    hide: () => void,
    handleSubmitGroup: (event: any) => void
    setgroupName: (changedgroupName: string) => void,
    loading: boolean,
    groupError: string
}

const ModalAddGroup = (props: ModalAddGroupProps) => {
    const {hide, handleSubmitGroup, show, setgroupName, loading, groupError} = props
    const {t} = useTranslation()
    const nameRef = useRef(null)

    useEffect(() => {
        if(nameRef.current === null){
            return
        }

        // @ts-ignore
        nameRef.current.focus()
    }, [])

    return (
        <Modal show={show} onHide={hide}>
            <form onSubmit={(e) => handleSubmitGroup(e)}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('Name the group')}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p>{t('With a group you can easily select multiple customers at once')}.</p>
                    <label>{t('Group name')}</label>
                    <input type="text"
                           className="form-control"
                           placeholder={t('Mondaymorning class')}
                           ref={nameRef}
                           onChange={(e) => {
                               setgroupName(e.target.value)
                           }}
                           disabled={loading}
                    />
                    <div className='errorPanel'>
                        {groupError && <Alert variant='danger'>{groupError}</Alert>}
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="link" onClick={hide}>
                        {t('Cancel')}
                    </Button>

                    <Button
                        type="submit"
                        className="btn btn-primary"
                    >
                        {loading && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>}
                        {!loading && t('Submit')}
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}

export default ModalAddGroup;
