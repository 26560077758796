import React from 'react';
import styled from "styled-components";
import {Button} from "react-bootstrap";

const TextField = styled.input`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;

  &:hover {
    cursor: pointer;
  }
`;

const ClearButton = styled(Button)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 32px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FilterComponent = (props: any) => {
    const {filterText, onFilter, onClear, placeholder, id}: {id: string, placeholder: string, filterText: string, onFilter: any, onClear: any} = props

    return (
        <>
            <TextField
                id={id}
                type="text"
                placeholder={placeholder}
                value={filterText}
                onChange={onFilter}
            />
            <ClearButton type="button" onClick={onClear}>X</ClearButton>
        </>
    )
};

export default FilterComponent;
