import persistentStore from "../stores/persistentStore";
const useTime = ['time', 'hours', 'uur'].includes(persistentStore.user?.settings.useType || 'strippen')

export const stripnameSingular = () => {
    return persistentStore.user?.settings?.stripName.singular || 'strip'
}

export const stripnamePlural = () => {
    return persistentStore.user?.settings?.stripName.plural || 'strippen'
}

export const stripnameTimeSingular = () => {
    return useTime ? 'tijd' : stripnameSingular()
}

export const stripnameTimePlural = () => {
    return useTime ? 'tijd' : stripnamePlural()
}
