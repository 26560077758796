import axios from 'axios'
import errorChecker from './errorchecker'
import persistentStore from "../stores/persistentStore";

const recurrenceRequests = () => {

    /**
     * Get recurrences of the current logged in user
     */
    const getFromCustomerPublicId = async (customerPublicId: string) => {
        const path = `/recurrences/get-from-customer/${customerPublicId}`
        const config = persistentStore.authHeader

        return axios.get(persistentStore.apiUrl + path, config)
            .then(response => errorChecker(response))
            .then(response => {
                const data = response.data.data;
                if (!data.recurrences) {
                    throw Error('The response did not contain recurrences');
                }

                return data
            })
    }

    const del = async (recurrenceId: number) => {
        const path   = `/recurrences/${recurrenceId}`

        return axios.delete(persistentStore.apiUrl+path, persistentStore.authHeader)
            .then(response => errorChecker(response))
            .then(response => {
                const recurrence = response.data.data.recurrence

                if(! recurrence){
                    throw Error('The response did not contain the removed recurrence');
                }

                return recurrence
            })
    }

    return {
        getFromCustomerPublicId,
        del,
    }
}

export const recurrences = recurrenceRequests()
