import React from 'react';
import {Row ,Col, Card, Button} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {Edit as EditIcon, Delete as DeleteIcon, Globe as WorldIcon} from "react-feather";
import {ICardType} from "../../interfaces/ICardType";
import store from "../../stores/store";
import persistentStore from "../../stores/persistentStore";

const CardType = (props: any) => {
    const card: ICardType = props.card
    const showDeleteCardTypes = props.showDeleteCardTypes
    const showEditCardTypes = props.showEditCardTypes
    const {t} = useTranslation()


    return (
        <Card className='mt-3 p-2 cardtype'>
            <Row>
                <Col md="12"><h1>{card.name}</h1></Col>
            </Row>
            <Row>
                <Col>
                    <div className='cardtype-description'>{card.description}</div>
                    <div className='cardtype-quantity'>{t('Quantity of strips')}: {card.default_quantity}</div>
                </Col>
                <Col>
                    <div className='d-flex flex-row'>
                        <div>€&nbsp;</div>
                        <div className='cardtype-price'>{(card.price_in_cents/100).toLocaleString(store.locale)}</div>
                    </div>

                    <Button
                        type="submit"
                        size='sm'
                        variant="primary"
                        className="float-right edit-cardtype"
                        onClick={showEditCardTypes}
                    >
                       <EditIcon />
                    </Button>

                    <Button
                        type="submit"
                        size='sm'
                        variant="danger"
                        className="float-right mr-1"
                        onClick={showDeleteCardTypes}
                    >
                       <DeleteIcon />
                    </Button>

                    <Button
                        type="submit"
                        size='sm'
                        variant="success"
                        className="float-right show-cardtype mr-1"
                        onClick={() => window.open(`/order/${persistentStore.user?.sale_slug}/${card.slug}`, '_blank')}
                    >
                        <WorldIcon />
                    </Button>
                </Col>
            </Row>
        </Card>
    )
}

export default CardType;
