import React from 'react';
import {IOrder} from "../../interfaces/IOrder";
import DataTable from "react-data-table-component";
import {useTranslation} from "react-i18next";
import moment from "moment/moment";
import uiStore from "../../stores/uiStore";
import persistentStore from "../../stores/persistentStore";
import store from "../../stores/store";
import {Link} from "../../utils/router";

const OrderTable = (props: any) => {
    const {orders}: { orders: IOrder[] } = props
    const {t} = useTranslation()

    const columns = [
        {
            name: t('Status'),
            selector: 'status',
            sortable: true,
            cell: (order: IOrder) => {
                let type = 'info'
                let text = order.status

                if (order.status === 'paid') {
                    type = 'success'
                    text = t('paid')
                }

                if (order.status === 'expired') {
                    type = 'warning'
                    text = t('expired')
                }

                return (
                    <span
                        className={`badge badge-pill badge-${type}`}
                        style={{padding: '7px', fontSize: '0.9rem'}}
                    >
                    {text}
                </span>
                )
            }
        },
        {
            name: t('Buyer'),
            sortable: true,
            cell: (order: IOrder) => {

                if (order.buyer.deleted === null) {
                    return <Link to={`/customers/${order.buyer.public_id}`}>{order.buyer.name}</Link>
                }

                return (
                    <label style={{display: 'flex', alignItems: 'center'}}>
                        {order.buyer.name}
                        <span
                            className='badge badge-pill badge-danger'
                            style={{marginLeft: '3px', padding: '7px', fontSize: '0.7rem'}}
                        >
                            {t('Deleted')}
                        </span>
                    </label>
                )
            }
        },
        {
            name: t('Price'),
            selector: 'order_price_in_cents',
            sortable: true,
            cell: (order: IOrder) => {
                return (order.order_price_in_cents / 100).toLocaleString(store.locale, uiStore.moneyOptions)
            },
        },
        {
            name: t('Invoice'),
            selector: 'invoice',
            sortable: true,
            cell: (order: IOrder) => {
                if (!order.invoice) {
                    return ''
                }

                return (
                    <a
                        rel="noopener noreferrer"
                        href={`/invoice/${order.invoice.public_id}`}
                        target='_blank'
                    >
                        {t('invoice')}
                    </a>
                )
            },
        },
        {
            name: t('Created'),
            selector: 'created',
            sortable: true,
            cell: (order: IOrder) => moment(order.created).format('YYYY-MM-DD'),
        },
    ]

    return (
        <div>
            <DataTable
                className='OrderListView order-list-view'
                columns={columns}
                data={orders}
                pagination={orders.length > 15}
                paginationPerPage={persistentStore.pageLength}
                paginationRowsPerPageOptions={persistentStore.pageLengthOptions}
                onChangeRowsPerPage={(rowCount) => persistentStore.setPageLength(rowCount)}
                noHeader={true}
                noDataComponent={<div>{t('No orders')}</div>}
            />
        </div>
    )
}

export default OrderTable;
